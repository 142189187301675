<script setup>
import {ref, defineProps, watch} from "vue"
import LoaderComponent from "./LoaderComponent"
import SvgIconComponent from "./SvgIconComponent"
import store from "../../store"

const props = defineProps({
  mainDispatch: Object,
  resetDispatch: Object,
  placeholder: {
    type: String,
    default: 'recherche par projet'
  }
})

const query = ref('')
const oldValue = ref('')
const loading = ref(false)
const time = ref(0)
const typing = ref(false)

function toggleTyping() {
  time.value = Date.now()
  typing.value = true
}

async function search() {
  if (Date.now() - time.value < 600) await new Promise(resolve => setTimeout(resolve, 600))

  if (query.value && query.value !== oldValue.value) {
    let data = {}
    loading.value = true

    if (props.mainDispatch.payload) data = Object.assign({query: query.value.replace(' ', '%')}, props.mainDispatch.payload)

    store.dispatch(props.mainDispatch.action, data)
        .then(() => {
          loading.value = false
          oldValue.value = query.value
        })
  }
}

function watchQuery() {
  if (!query.value) {
    loading.value = true
    store.dispatch(props.resetDispatch.action, props.resetDispatch.payload ?? {})
        .then(() => {
          loading.value = false
          oldValue.value = ""
        })
  }
}

watch(query, watchQuery)
</script>

<template>
  <div class="search">
    <LoaderComponent :loading="loading"/>
    <SvgIconComponent v-if="!loading" name="search"/>
    <input type="search" :placeholder="placeholder" @keydown="toggleTyping" @keyup="search" v-model="query" autocomplete="off">
  </div>
</template>