import Axios from "axios"
import Config from "../Utils/Config"
import store from "../store"

export default {
    getHeaders(contentType = "application/json") {
        return {'Authorization': `Bearer ${store.getters['securityModule/getToken']}`, 'content-type': contentType}
    },
    async getList(page = 'page=1') {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/logs?${page}`, {headers: this.getHeaders()})
                .then(response => {
                    const data = response.data
                    resolve(data)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
    async create(data) {
        return new Promise((resolve, reject) => {
            Axios({method: 'post', url: `${Config.getApiUrl()}/logs`, data, headers: this.getHeaders()})
                .then(response => resolve(response.data))
                .catch(error => {
                    store.commit('addMessage', {
                        state: 'error',
                        content: error.response.data?.message ?? error.response.data['hydra:description']
                    })
                    reject(error)
                })
        })
    }
}