<script setup>
import {computed, defineProps, onMounted, ref, watch} from "vue"
import store from "../../store"
import IconTitleComponent from "../items/IconTitleComponent"
import TimeViewResumeComponent from "../times/TimeViewResumeComponent"
import ProjectSettingsComponent from "./ProjectSettingsComponent"
import ProjectPieChartComponent from "../charts/ProjectPieChartComponent"
import SvgIconComponent from "../items/SvgIconComponent"
import timesApi from "../../api/times"
import DateUtils from "../../Utils/DateUtils"

const times = ref([])

const props = defineProps({
  project: Object
})

const isAdmin = computed(() => {
  return store.getters['userModule/isAdmin']
})

function getTimes(project) {
  if (project.yearly) {
    timesApi.getByProjectAndStartingDate(project.id, new Date(DateUtils.getYearlyDate(project)).getTime() / 1000)
        .then(data => data['hydra:totalItems'] ? times.value = data['hydra:member'] : [])
  } else {
    timesApi.getByProject(project.id)
        .then(data => data['hydra:totalItems'] ? times.value = data['hydra:member'] : [])
  }
}

function openWindow() {
  store.commit('timesModule/setAddTimes', {
    state: true,
    projectId: props.project['@id']
  })
}

onMounted(() => {
  getTimes(props.project)
})

function watchProject(newValue) {
  getTimes(newValue)
}
watch(() => props.project, watchProject)

</script>

<template>
  <div class="project-single-settings">
    <div v-if="isAdmin">
      <IconTitleComponent title="Paramètre de rentabilité" titleSize="h3" iconName="euro"/>
      <ProjectSettingsComponent :project="project"/>
      <hr>
    </div>
    <div style="position: relative">
      <IconTitleComponent title="Temps passé" titleSize="h3" iconName="times"/>
      <div v-if="parseInt(project.state) < 2" class="button-item set-times" title="Ajouter du temps à ce projet" @click.stop="openWindow()">
        <SvgIconComponent name="add-times" />
      </div>
      <TimeViewResumeComponent :project="project" :times="times"/>
    </div>
    <hr>
    <ProjectPieChartComponent v-if="times" :times="times" />
  </div>
</template>