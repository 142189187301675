<script setup>
import {computed, ref, defineProps} from "vue"
import SvgIconComponent from "../components/items/SvgIconComponent"
import Utils from "../Utils/Utils"

const props = defineProps({
  stateKey: String
})

const titleContainer = ref(null)
const state = ref(Utils.getDashboardStateByKey(props.stateKey) ?? false)

const getState = computed(() => {
  return state.value ? `${props.stateKey}` : `${props.stateKey} close`
})

function onClick() {
  state.value = !state.value
  Utils.setDashboardState(props.stateKey, state.value)
}
</script>

<template>
  <div class="card dashboard-item" :class="getState">
    <div class="title-container" ref="titleContainer" @click.prevent="onClick">
      <slot name="title"></slot>
      <SvgIconComponent name="arrow-down" />
    </div>

    <div class="content-container">
      <slot name="content"></slot>
    </div>
  </div>
</template>