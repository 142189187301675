<script setup>
import {ref, computed, defineProps, onMounted} from "vue"
import SvgIconComponent from "./SvgIconComponent"
import Config from "../../Utils/Config"

const props = defineProps({
  item: Object
})

const name = ref("")
const desc = ref(false)
const url = ref("")
const labels = ref([])
const labelsActive = ref(false)
const due = ref("")
const dueComplete = ref("")
const closed = ref(false)
const boardName = ref("")
const boardUrl = ref("")
const attachments = ref([])

const dueDate = computed(() => {
  return new Date(due.value).toLocaleDateString()
})
const dueState = computed(() => {
  if (Date.now() > dueTimestamp.value) return 'outdated'
  else if(dueComplete.value) return 'completed'
  else return ''
})
const dueTimestamp = computed(() => {
  return new Date(due.value).getTime()
})

function toggleLabelActive() {
  labelsActive.value = !labelsActive.value
}

onMounted(() => {
  const arrLink = props.item.link.split('/')
  const cardId = arrLink[4]

  fetch(`https://api.trello.com/1/card/${cardId}?fields=all&board=true&board_fields=name,url&attachments=true&key=${Config.getTrelloApiKey()}&token=${Config.getTrelloApiToken()}`)
      .then(resp => resp.json())
      .then(card => {
        name.value = card.name
        desc.value = !!card.desc
        url.value = card.url
        due.value = card.due
        closed.value = card.closed
        dueComplete.value = card.dueComplete
        boardName.value = card.board.name
        boardUrl.value = card.board.url
        labels.value = card.labels
        attachments.value = card.attachments
      });
})

</script>

<template>
  <div class="trello-card" :class="closed ? 'closed' : ''">
    <div v-if="boardName && boardUrl" class="header">
      <a :href="boardUrl" title="voir le board" target="_blank">
        <SvgIconComponent name="trello" />
        <span>{{ boardName }}</span>
      </a>
    </div>
    <div v-if="closed" class="closed">
      Cette carte est archivé
    </div>
    <div class="content">
      <span class="name">{{ name }}</span>
      <div class="items">
        <SvgIconComponent v-if="desc" name="text" class="description" />
        <div v-if="due" class="schedule" :class="dueState" >
          <SvgIconComponent name="schedule" />
          <span>{{ dueDate }}</span>
        </div>
      </div>
      <div v-if="labels" class="labels" :class="labelsActive ? 'active' : ''">
        <div @click="toggleLabelActive" v-for="label in labels" :key="label.id" :class="label.color">
          <span>{{ label.name }}</span>
        </div>
      </div>
      <ul v-if="attachments" class="attachments">
        <li v-for="item in attachments" :key="item.id">
          <SvgIconComponent name="open-new-window" />
          <a :href="item.url" target="_blank" rel="noreferrer, noopener">{{item.name}}</a>
        </li>
      </ul>
      <a :href="url" title="voir la carte" target="_blank">Voir la carte</a>
    </div>
  </div>
</template>