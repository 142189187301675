<script setup>
import {computed, defineProps, onMounted} from "vue"
import {useRoute} from "vue-router"
import store from "../../store"
import SvgIconComponent from "../items/SvgIconComponent"
import TrelloCardComponent from "../items/TrelloCardComponent"

const props = defineProps({
  parentType: String
})
const route = useRoute()

onMounted(() => {
  store.dispatch('linkModule/getLinkList', {type: props.parentType, id: route.params.id})
})

const isArchived = computed(() => {
  return store.getters['clientModule/getArchiveMode']
})
const links = computed(() => {
  return store.getters['linkModule/getItems']
})

function removeLink(id) {
  if (confirm(`Êtes vous sûr de vouloir supprimer le lien id : ${id} ?`)) store.dispatch('linkModule/removeLink', {id, type: props.parentType})
}

</script>

<template>
  <transition-group name="list-right" v-if="links" class="link-list" tag="ul">
    <li v-for="item in links" :key="item.id" :class="item.type === 'trello' ? 'trello' : ''">
      <template v-if="item.type === 'trello'">
        <TrelloCardComponent :item="item" />
      </template>

      <template v-else>
        <SvgIconComponent :name="item.type" />
        <a :href="item.link" target="_blank">{{ item.name }}</a>
      </template>
      <div v-if="!isArchived" @click="removeLink(item.id)" class="delete-button">supprimer</div>
    </li>
  </transition-group>
</template>