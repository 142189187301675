<script setup>
import {computed} from "vue"
import store from "../../store"

const getMessages = computed(() => {
  return store.getters['getMessages']
})

const vRead = {
  mounted: (el) => {
    const messageId = el.getAttribute('id')
    setTimeout(() => {
      el.style.transform = 'translate(0, -150%)'
      el.addEventListener('transitionend', () => {
        store.commit('removeMessage', messageId)
      })
    }, 3500)
  }
}
</script>

<template>
  <div class="messages-flash">
    <transition-group name="slide-fade" tag="p">
      <p
          v-read
          v-for="(message) in getMessages"
          :key="message.uuid"
          :id="message.uuid"
          class="message-item"
          :class="'alert alert-' + message.state"
      >
        {{ message.content }}
      </p>
    </transition-group>
  </div>
</template>