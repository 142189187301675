<script setup>
import {ref} from "vue"
import store from "../../store"
import SvgIconComponent from "../items/SvgIconComponent"
import Utils from "../../Utils/Utils"

const editing = ref(false)
const name = ref("")
const input = ref(null)

function toggleEditingState(state) {
  editing.value = state
  if (state) setTimeout(() => input.value.focus(), 0)
}

function createClient(ev) {
  if (((ev instanceof KeyboardEvent && ev.isTrusted && ev.key === "Enter") || ev instanceof PointerEvent) && name.value) {
    let ref = Utils.refify(name.value)
    store.dispatch('clientModule/checkReference', ref)
        .then(response => {
          if (response) {
            store.commit('addMessage', {state: "warning", content: `ATTENTION : une référence similaire à ${ref} existe déjà`})
            const date = new Date()
            ref += `_${date.getFullYear()}_${date.getMonth()}_${date.getDay()}`
          }

          store.dispatch('clientModule/createClient', {name: name.value, reference: ref, isArchived: false})
              .then(response => {
                if (response) {
                  name.value = ""
                  editing.value = false
                  ev.target.blur()
                  ev.target.value = ""
                }
              })
        })
  }
  editing.value = false
}

</script>

<template>
  <div @click="toggleEditingState(true)" class="btn-create create-client">
    <div class="button-icon-rounded">
      <SvgIconComponent name="person-add" />
    </div>
    <div class="btn-container">
      <span v-show="!editing">Créer un nouveau client</span>
      <input
          ref="input"
          v-show="editing"
          v-focus="editing"
          @keyup.enter="createClient"
          @blur="toggleEditingState(false)"
          type="text"
          name="client_name"
          placeholder="Veuillez entrer le nom du client &ldca;"
          v-model="name">
    </div>
  </div>
</template>