import Axios from "axios"
import Config from "../Utils/Config"
import store from "../store"

export default {
    getHeaders(contentType = "application/json") {
        return {'Authorization': `Bearer ${store.getters['securityModule/getToken']}`, 'content-type': contentType}
    },
    async referenceExist(ref) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/projects?reference=${ref}`, {headers: this.getHeaders()})
                .then(response => {
                    const hasProject = parseInt(response.data["hydra:totalItems"])
                    resolve(hasProject)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
                })
        })
    },
    async getListByClient(payload) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/projects?client=${payload?.clientId}${payload?.exists ? `&exists[${payload.exists}]=true` : ''}`, {headers: this.getHeaders()})
                .then(response => {
                    const projects = response.data['hydra:member'] ?? {}
                    resolve(projects)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
                })
        })
    },
    async getListByDate(payload) {
        let query = "exists[times]=true&pagination=false"
        if (payload.state) query += `&state=${payload.state}`
        else query += "&state[gt]=0"
        if (payload.type) query += `&type=${payload.type}`

        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/projects?createdAt[before]=${payload.end}&createdAt[after]=${payload.start}&${query}`, {headers: this.getHeaders()})
                .then(response => {
                    const projects = response.data['hydra:member'] ?? {}
                    resolve(projects)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
    async get(id) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/projects/${id}`, {headers: this.getHeaders()})
                .then(response => {
                    const project = response.data
                    resolve(project)
                }).catch(error => {
                    reject(error.response.data.message)
                })
        })
    },
    async getAll(payload) {
        let query = "exists[times]=true&pagination=false"
        if (payload.state) query += `&state=${payload.state}`
        if (payload.yearly) query += `&yearly=1`

        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/projects?${query}`, {headers: this.getHeaders()})
                .then(response => resolve(response.data['hydra:member']))
                .catch(error => reject(error.response.data.message))
        })
    },
    async update(id, data) {
        return new Promise((resolve, reject) => {
            Axios.patch(`${Config.getApiUrl()}/projects/${id}`, data, {headers: this.getHeaders('application/merge-patch+json')})
                .then(response => {
                    const project = response.data
                    resolve(project)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
    async remove(id) {
        return new Promise((resolve, reject) => {
            Axios.delete(`${Config.getApiUrl()}/projects/${id}`,{headers: this.getHeaders()})
                .then(response => {
                    store.commit('addMessage', {state: 'warning', content: `Le projet id:${id} à bien été supprimé`})
                    resolve(response)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
    async create(data) {
        return new Promise((resolve, reject) => {
            Axios({method: 'post', url: `${Config.getApiUrl()}/projects`, data, headers: this.getHeaders()})
                .then(response => {
                    const project = response.data
                    if (project) {
                        store.commit('addMessage', {
                            state: 'success',
                            content: `[${project.reference}] - ${project.name} à bien été créé`
                        })
                    }
                    resolve(project)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                reject(error)
            })
        })
    },
    async search(payload) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/projects?search_name=${payload.query}`, {headers: this.getHeaders()})
                .then(response => {
                    const projects = response.data['hydra:member']
                    resolve(projects)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
                })
        })
    },
}