import clientApi from "../../api/client"
import store from "../index"
import {DateTime} from "luxon";

const state = () => ({
    current: {},
    items: [],
    archivedItems: [],
    pagination: {},
    archiveMode: false
})

const getters = {
    getItems: state => state.items,
    getArchivedItems: state => state.archivedItems,
    getCurrent: state => state.current,
    getArchiveMode: state => state.archiveMode,
    getPagination: state => state.pagination,
    getClientByAnniversary: state => {
        const date = new DateTime("now")
        const birthdays = []
        state.items.forEach(client => {
            if (client.createdAt.includes(`-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}T`)) birthdays.push(client)
        })

        return birthdays
    }
}

const actions = {
    async searchClient({commit}, data) {
        const clients = await clientApi.search(data)
        commit('setClientList', clients)
    },
    async getClientList({commit}, page) {
        const clients = await clientApi.getList(page)
        commit('setClientList', clients['hydra:member'])
        commit('setPagination', clients['hydra:view'] ?? null)
    },
    async getArchivedClientList({commit}) {
        const clients = await clientApi.getArchivedList()
        commit('setArchivedClientList', clients)
    },
    async createClient({commit}, data) {
        const client = await clientApi.create(data)
        commit('addNewClient', client)

        await store.dispatch('logModule/createLog', {
            user: store.getters['userModule/getCurrentUser'].username,
            action: "CREATE",
            entity: "client",
            entityId: client.id,
            entityIri: client['@id'],
            description: `Nom du client : ${client.name}`
        })

        return client
    },
    async getClientById({commit}, id) {
        const client = await clientApi.get(id)
        commit('addCurrent', client)
        return client
    },
    async updateClient({commit}, payload) {
        const client = await clientApi.update(payload.id, payload.data)
        commit('addCurrent', client)

        await store.dispatch('logModule/createLog', {
            user: store.getters['userModule/getCurrentUser'].username,
            action: client.isArchived ? "ARCHIVED" : "UPDATE",
            entity: "client",
            entityId: client.id,
            entityIri: client['@id'],
            description: `Nom du client : ${client.name}`
        })

        if (client && payload.action === "archive") {
            commit('removeClient', client.id)
            store.commit('addMessage', {
                state: 'success',
                content: `${client.name} à bien été ${payload.data.isArchived ? 'archivé' : 'désarchivé'}`
            })
        }

        return client
    },
    async removeClient({commit}, payload) {
        await clientApi.remove(payload.id)
        commit('removeClient', payload.id)

        await store.dispatch('logModule/createLog', {
            user: store.getters['userModule/getCurrentUser'].username,
            action: "DELETE",
            entity: "client",
            entityId: payload.id,
            description: `Nom du client : ${payload.name}`
        })
    },
    async checkReference(context, ref) {
        return await clientApi.referenceExist(ref)
    }
}

const mutations = {
    setPagination(state, pagination) {
        state.pagination = pagination
    },
    setClientList(state, clients) {
        if (localStorage.getItem('recent-client') && clients.length) {
            const order = JSON.parse(localStorage.getItem('recent-client'))
            let newArray = []
            let arrClients = Array.from(clients)
            let current
            order.forEach((id, index) => {
                clients.forEach(el => {
                    if (el.id === id) current = el
                })
                if (current) {
                    arrClients = arrClients.filter(el => el.id !== id)
                    newArray.splice(index, 1, current)
                }
            })

            state.items = [...new Set(newArray), ...arrClients]
        } else {
            state.items = clients
        }

        state.items.forEach(item => {
            if (item.projects) {
                const finishedProjects = item.projects.filter(el => el.state === 2)
                const otherProjects = item.projects.filter(el => el.state < 2)
                item.projects = [...finishedProjects, ...otherProjects]
            }
        })
    },
    setArchivedClientList(state, clients) {
        state.archivedItems = clients
    },
    setArchiveMode(state, bool) {
        state.archiveMode = bool
    },
    addNewClient(state, client) {
        if (client) state.items.unshift(client)
    },
    removeClient(state, id) {
        state.archivedItems = state.archivedItems.filter(item => item.id !== id)
        state.items = state.items.filter(item => item.id !== id)
    },
    addCurrent(state, client) {
        if (client.projects) {
            const finishedProjects = client.projects.filter(el => el.state === 2)
            const otherProjects = client.projects.filter(el => el.state < 2)
            client.projects = [...finishedProjects, ...otherProjects]
        }
        state.current = client
    },
    addProject(state, project) {
        state.items.forEach(client => {
            if (client.id === project.client.id) client.projects.unshift(project)
        })
        if (state.current.id === project.client.id) state.current.projects.push(project)
    },
    removeProject(state, id) {
        state.items.forEach(client => client.projects = client.projects.filter(p => p.id !== id))
        if (state.current) state.current.projects = state.current.projects.filter(p => p.id !== id)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}