const state = () => ({
    mainTitle: {}
})

const getters = {
    getTitle: state => state.mainTitle.title,
    getIconName: state => state.mainTitle.icon,
}

const mutations = {
    setMainTitle(state, mainTitle) {
        state.mainTitle = mainTitle
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}