import Axios from "axios"
import Config from "../Utils/Config"
import store from "../store"

export default {
    getHeaders(contentType = "application/json") {
        return {'Authorization': `Bearer ${store.getters['securityModule/getToken']}`, 'content-type': contentType}
    },
    async getList(params) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/links?${params.type}=${params.id}`, {headers: this.getHeaders()})
                .then(response => {
                    const links = response.data['hydra:member']
                    resolve(links)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
    async get(id) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/links/${id}`, {headers: this.getHeaders()})
                .then(response => {
                    const link = response.data
                    resolve(link)
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },
    async update(id, data) {
        return new Promise((resolve, reject) => {
            Axios.patch(`${Config.getApiUrl()}/links/${id}`, data, {headers: this.getHeaders('application/merge-patch+json')})
                .then(response => {
                    const link = response.data
                    resolve(link)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
    async remove(id) {
        return new Promise((resolve, reject) => {
            Axios.delete(`${Config.getApiUrl()}/links/${id}`,{headers: this.getHeaders()})
                .then(response => {
                    store.commit('addMessage', {state: 'warning', content: `Le lien id:${id} à bien été supprimé`})
                    resolve(response)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
    async create(data) {
        return new Promise((resolve, reject) => {
            Axios({method: 'post', url: `${Config.getApiUrl()}/links`, data, headers: this.getHeaders()})
                .then(response => {
                    const link = response.data
                    resolve(link)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                reject(error)
            })
        })
    }
}