<script setup>
import {ref, onMounted, computed} from "vue"
import store from "../../store"
import SvgIcon from "../items/SvgIconComponent"

const dock = ref(null)

// COMPUTED
const isLoggedIn = computed(() => {
  return !!store.getters['securityModule/getToken']
})
const hasTodoEnable = computed(() => {
  return store.getters['userModule/hasTodoEnable']
})


onMounted(() => {
  dock.value.addEventListener('mouseover', () => dock.value.classList.add('active'))
  dock.value.addEventListener('mouseout', () => dock.value.classList.remove('active'))

  const items = dock.value.querySelectorAll('.dock-item')
  items.forEach(item => {
    item.addEventListener('mouseover', () => item.classList.add('hover'))
    item.addEventListener('mouseout', () => item.classList.remove('hover'))
  })
})
</script>

<template>
  <div v-if="isLoggedIn" class="dock" ref="dock">
    <router-link :to="{name: 'dashboard'}" title="Retour à la liste des clients" active-class="active" class="dock-item">
      <SvgIcon name="home" />
      <span>Dashboard</span>
    </router-link>
    <router-link v-show="hasTodoEnable" :to="{ name: 'personalTodo' }" title="Ma Todo" active-class="active" class="dock-item">
      <SvgIcon name="todo-list" />
      <span>Todo</span>
    </router-link>
    <router-link :to="{ name: 'timesUserDashboard' }" title="Gestion du temps" active-class="active" class="dock-item">
      <SvgIcon name="times" />
      <span>Temps</span>
    </router-link>
    <router-link :to="{ name: 'leave_ask' }" active-class="active" class="dock-item">
      <SvgIcon name="calendar-range" />
      <span>Congés</span>
    </router-link>
    <router-link :to="{ name: 'account' }" active-class="active" class="dock-item">
      <SvgIcon name="account-circle" />
      <span>Mon compte</span>
    </router-link>
    <router-link :to="{ name: 'archivedClients' }" title="Voir les clients archivés" active-class="active" class="dock-item">
      <SvgIcon name="archive" />
      <span>Archive</span>
    </router-link>
  </div>
</template>