import logApi from "../../api/log"

const state = () => ({
    items: [],
    pagination: {}
})

const getters = {
    getItems: state => state.items,
    getPagination: state => state.pagination
}

const actions = {
    async getLogList({ commit }, page) {
        const data = await logApi.getList(page)
        commit('setLogList', data['hydra:member'])
        commit('setPagination', data['hydra:view'])
    },
    async createLog({commit}, data) {
        const log = await logApi.create(data)
        commit('addNewLog', log)
        return log
    }
}

const mutations = {
    setLogList(state, logs) {
        state.items = logs
    },
    setPagination(state, pagination) {
        state.pagination = pagination
    },
    addNewLog(state, log) {
        if(log) state.items.unshift(log)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}