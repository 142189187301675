<template>
  <div class="pagination">
    <a v-if="first && first !== currentPage && currentQuery?.page" :href="`?${first}`">
      <SvgIconComponent name="arrow-left" />
      <SvgIconComponent name="arrow-left" />
      <span>première</span>
    </a>
    <a v-if="prev" :href="`?${prev}`">
      <SvgIconComponent name="arrow-left" />
      <span>précédente</span>
    </a>
    <a v-if="next" :href="`?${next}`">
      <span>suivante</span>
      <SvgIconComponent name="arrow-right" />
    </a>
    <a v-if="last && last !== currentPage" :href="`?${last}`">
      <span>dernière</span>
      <SvgIconComponent name="arrow-right" />
      <SvgIconComponent name="arrow-right" />
    </a>
  </div>
</template>

<script lang="js">
import SvgIconComponent from "./SvgIconComponent"
export default {
  components: {SvgIconComponent},
  props: {
    pagination: Object
  },
  data() {
    return {
      currentPage: this.$route.fullPath?.split('?')?.at(-1),
      currentQuery: this.$route.query
    }
  },
  computed: {
    first() {
      return this.pagination['hydra:first']?.split('?')?.at(-1)
    },
    last() {
      return this.pagination['hydra:last']?.split('?')?.at(-1)
    },
    next() {
      return this.pagination['hydra:next']?.split('?')?.at(-1)
    },
    prev() {
      return this.pagination['hydra:previous']?.split('?')?.at(-1)
    }
  }
}
</script>