import Axios from "axios"
import store from "../store"
import Config from "../Utils/Config"

export default {
    getHeaders(contentType = "application/json") {
        return {'Authorization': `Bearer ${store.getters['securityModule/getToken']}`, 'content-type': contentType}
    },
    async create(data) {
        return new Promise((resolve, reject) => {
            Axios({method: 'post', url: `${Config.getApiUrl()}/leaves`, data, headers: this.getHeaders()})
                .then(response => {
                    const leave = response.data
                    resolve(leave)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                    reject(error)
                })
        })
    },
    async getAll(page = 'page=1') {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/leaves?${page}`, {headers: this.getHeaders()})
                .then(response => {
                    const leaves = response?.data
                    resolve(leaves)
                })
                .catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                    reject(error)
                })
        })
    },
    async getByUser(userId) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/leaves?user=${userId}`, {headers: this.getHeaders()})
                .then(response => {
                    const leaves = response?.data
                    resolve(leaves)
                })
                .catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                    reject(error)
                })
        })
    },
    async getByStateOrType(payload) {
        let query = ""
        if (payload.state) query += `state=${payload.state}`
        if (payload.state && payload.type) query += '&'
        if (payload.type) query += `type=${payload.type}`
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/leaves?${query}`, {headers: this.getHeaders()})
                .then(response => {
                    const leaves = response?.data
                    resolve(leaves)
                })
                .catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                    reject(error)
                })
        })
    },
    async getIncoming(data) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/leaves?state=1&endAt[after]=${data.start}&endAt[before]=${data.end}`, {headers: this.getHeaders()})
                .then(response => {
                    const leaves = response?.data
                    resolve(leaves)
                })
                .catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                    reject(error)
                })
        })
    },
    async update(id, data) {
        return new Promise((resolve, reject) => {
            Axios.patch(`${Config.getApiUrl()}/leaves/${id}`, data, {headers: this.getHeaders('application/merge-patch+json')})
                .then(response => {
                    const leave = response.data
                    resolve(leave)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
}