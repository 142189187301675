<script setup>
import MultiColumnTemplate from "@/views/templates/MultiColumnTemplate.vue";
import SearchComponent from "@/components/items/SearchComponent.vue";
import TimesListComponent from "@/components/times/TimesListComponent.vue";
import PaginationComponent from "@/components/items/PaginationComponent.vue";
import {computed, onMounted, onUnmounted, ref} from "vue";
import {useRoute} from "vue-router";
import store from "@/store";
import BasicInputComponent from "@/components/fields/BasicInputComponent.vue";
import DateUtils from "@/Utils/DateUtils";

const route = useRoute()

const selectedUser = ref("")
const startDefault = ref("")
const endDefault = ref("")

const times = computed(() => store.getters['timesModule/getItems'])
const getPagination = computed(() => store.getters['timesModule/getPagination'])
const getUsers = computed(() => store.getters['userModule/getItems'])
const getTotalTimes = computed(() => store.getters['timesModule/getTotalTime'])

const searchMainDispatch = computed(() => {
  return {
    action: 'timesModule/searchByUserAndProject',
    payload: {
      user: selectedUser.value,
      page: route?.query?.page ?? '',
      pagination: false,
      start: DateUtils.stringToTimestamp(startDefault.value),
      end: DateUtils.stringToTimestamp(endDefault.value)
    }
  }
})

const searchResetDispatch = computed(() => {
  return {
    action: 'timesModule/searchByUserAndProject',
    payload: {
      user: selectedUser.value,
      pagination: false,
      start: DateUtils.stringToTimestamp(startDefault.value),
      end: DateUtils.stringToTimestamp(endDefault.value)
    }
  }
})

function searchByUser() {
  let searchQuery = ""
  const searchInput = document.querySelector('input[type="search"]')
  if (searchInput) searchQuery = searchInput.value
  const user = selectedUser.value
  store.dispatch('timesModule/searchByUserAndProject', {
    user,
    query: searchQuery,
    page: route?.query?.page ?? '',
    pagination: false,
    start: DateUtils.stringToTimestamp(startDefault.value),
    end: DateUtils.stringToTimestamp(endDefault.value)
  })
}

onMounted(() => {
  const today = new Date()
  endDefault.value = DateUtils.getInputFriendlyDate(today)
  today.setDate(today.getDate() + 1)
  const end = DateUtils.getInputFriendlyDate(today)
  const lastYear = new Date(today.setFullYear(today.getFullYear() - 1))
  startDefault.value = DateUtils.getInputFriendlyDate(lastYear)

  const page = route.query?.page ?? ''
  store.dispatch('timesModule/searchByUserAndProject', {
    page,
    pagination: true,
    start: DateUtils.stringToTimestamp(startDefault.value),
    end: DateUtils.stringToTimestamp(end)
  })
  store.dispatch('userModule/getList', {page, pagination: true})
  store.commit('topBarModule/setMainTitle', {title: 'Récap temps', icon: 'recap-times'})
})

onUnmounted(() => {
  store.commit('timesModule/resetTimes')
})
</script>

<template>
  <MultiColumnTemplate>
    <template #main>
      <div class="time-users">
        <div class="header">
          <SearchComponent :main-dispatch="searchMainDispatch" :reset-dispatch="searchResetDispatch" />
          <div class="filters">
            <div class="form-group">
              <label for="user">Collaborateur</label>
              <select name="user" id="user" v-model="selectedUser" @change="searchByUser">
                <option key="0" value="">-</option>
                <option v-for="user in getUsers" :key="user.id" :value="user.id">{{ `(${user.username}) ${user.firstname} - ${user.lastname}` }}</option>
              </select>
            </div>

            <div class="form-group">
              <label for="start">Date de début</label>
              <BasicInputComponent input-type="date" v-model:modelValue="startDefault" @change="searchByUser" />
            </div>

            <div class="form-group">
              <label for="end">Date de fin</label>
              <BasicInputComponent input-type="date" v-model:modelValue="endDefault" @change="searchByUser" />
            </div>
          </div>
        </div>

        <p class="total-times"><strong>Total : </strong>{{ getTotalTimes }}</p>

        <TimesListComponent :times="times" :readOnly="true" />

        <PaginationComponent v-if="getPagination" :pagination="getPagination" />
      </div>
    </template>
  </MultiColumnTemplate>
</template>