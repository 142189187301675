<script setup>
import Confetti from "./../../Utils/confetti.min"
import {computed, watch, ref} from "vue";
import store from "./../../store"

const confettiContainer = ref(null)
const messageHasRead = ref(sessionStorage.getItem("anniversary-message-read"))

const anniversaries = computed(() => {
  return store.getters['commonModule/getAnniversaries']
})

watch(() => confettiContainer.value, (newValue) => {
  if (newValue) {
    newValue.addEventListener('click', () => sessionStorage.setItem("anniversary-message-read", "1"))
    const confetti = new Confetti('confetti')
    confetti.setCount(150);
    confetti.setSize(1.5);
    confetti.setPower(40);
    confetti.setFade(false);
    confetti.destroyTarget(true);
  }
})
</script>

<template>
  <div id="confetti" v-if="anniversaries.length && !messageHasRead" class="card client-birthday" ref="confettiContainer">
    <strong>🎉 Aujourd'hui on fête : 🎉</strong>
    <div v-for="anniversary in anniversaries" :key="anniversary.id" class="birthday-item">
      l'anniversaire de <strong>{{ anniversary.name }}</strong> {{ anniversary.message }}
    </div>
  </div>
</template>