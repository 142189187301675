<script setup>
import {onMounted, ref} from "vue"
import ApiProject from "../../api/project"
import StatsUtils from "../../Utils/StatsUtils"

const total = ref(0)
const used = ref(0)
const gain = ref(0)

async function getTotal(projects) {
  let t = 0
  let c = 0
  await projects.map(async project => {
    t += project.sellingPrice ? parseInt(project.sellingPrice) : 0
    if (project.times.length) c += Math.round(StatsUtils.getTotalCost(project, project.times))
  })

  used.value = c
  total.value = t

  gain.value = total.value - used.value
}

onMounted(() => {
  ApiProject.getAll({state: 1, yearly: true})
      .then(projects => getTotal(projects))
})
</script>

<template>
  <div class="mini-card"><strong>Total :</strong> {{total}} €</div>
  <div class="mini-card"><strong>Total consommé :</strong> {{used}} €</div>
  <div class="mini-card"><strong>Marge :</strong> {{gain}} €</div>
</template>