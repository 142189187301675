<script setup>
import {defineProps, ref} from "vue";
import ContactItemComponent from "@/components/contacts/ContactItemComponent.vue";
import WindowComponent from "@/components/items/WindowComponent.vue";
import CreateContactComponent from "@/components/contacts/CreateContactComponent.vue";
import SvgIconComponent from "@/components/items/SvgIconComponent.vue";
import store from "@/store";

const createContactState = ref(false)
const currentContact = ref({})
const createContact = ref(null)
const parentIri = ref("")

function toggleCreateContact(ev, contact) {
  createContactState.value = true
  if (contact) {
    currentContact.value = contact
    parentIri.value = contact.client['@id']
  } else if (createContact.value.contains(ev.target)) {
    createContactState.value = true
    currentContact.value = {}
    parentIri.value = store.getters['clientModule/getCurrent']['@id']
  }
}

defineProps({
  contacts: Array,
  showClient: {
    type: Boolean,
    default: false
  }
})
</script>

<template>
  <div v-if="!showClient" ref="createContact" class="btn-create create-contact" @click.stop="toggleCreateContact">
    <div class="button-icon-rounded">
      <SvgIconComponent name="person-add"/>
    </div>
    <span>Créer un nouveau contact</span>
  </div>

  <ul class="contact-list-container">
    <li v-for="contact in contacts" :key="contact.id">
      <ContactItemComponent :contact="contact" :showClient="showClient" @editContact="toggleCreateContact($event, contact)"/>
    </li>
  </ul>

  <teleport v-if="!showClient" to="#endOfBody">
    <WindowComponent :state="createContactState" windowTitle="création de contact" @close="createContactState = false">
      <CreateContactComponent :parentIri="parentIri" :edit="currentContact" :saveAndClose="showClient" @close="createContactState = false"/>
    </WindowComponent>
  </teleport>
</template>