<script setup>
import {onMounted, computed} from "vue"
import store from "./../../store"
import TimeViewComponent from "../../components/times/TimeViewComponent"
import MultiColumnTemplate from "../templates/MultiColumnTemplate"
import SearchComponent from "../../components/items/SearchComponent"
import ProjectSearchComponent from "../../components/projects/ProjectSearchComponent.vue"

const searchMainDispatch = {
  action: 'projectModule/searchProject',
  payload: {}
}

const searchResetDispatch = {
  action: 'projectModule/getProjectList',
  payload: {exists: 'times'}
}

// COMPUTED
const getItems = computed(() => {
  return store.getters['projectModule/getItems']
})

// LIFECYCLE
onMounted(() => {
  store.commit('topBarModule/setMainTitle', {title: "Rentabilité des projets", icon: "manage-times"})
  store.dispatch('settingsModule/getSettings')
})
</script>

<template>
  <MultiColumnTemplate>
    <template #main>
      <div class="time-view-list">
        <ProjectSearchComponent />
        <p style="text-align: center">ou</p>
        <SearchComponent :main-dispatch="searchMainDispatch" :reset-dispatch="searchResetDispatch" />

        <ul v-if="getItems.length">
          <li v-for="project in getItems" :key="project.id" class="time-view-item mini-card">
            <TimeViewComponent v-if="project.times.length" :project="project" />
          </li>
        </ul>
        <p v-else>Aucun projet n'a été trouvé</p>
      </div>
    </template>
  </MultiColumnTemplate>
</template>