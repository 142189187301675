<script setup>
import {ref, watch, defineProps, onMounted, computed} from "vue"
import store from "../../store"
import SimpleTinyMceComponent from "../fields/SimpleTinyMceComponent"
import SvgIconComponent from "../items/SvgIconComponent"
import Config from "../../Utils/Config"
import {socketInstance} from "../../main"

const props = defineProps({
  data: Object,
  parent: Object
})

const message = ref("")
const createMessage = ref("")
const date = ref("")
const userIsTyping = ref(false)

const isArchived = computed(() => {
  return store.getters['clientModule/getArchiveMode']
})
const isAuthor = computed(() => {
  return props.data.user === getUsername.value
})
const getUsername = computed(() => {
  return localStorage.getItem('username')
})

function createNote() {
  if (createMessage.value && createMessage.value.trim()) {
    const data = {
      message: createMessage.value.trim(),
      user: localStorage.getItem('username'),
    }
    data[props.parent.type] = props.parent.iri
    store.dispatch('noteModule/createNote', data)
  }

  createMessage.value = ""
}
function updateNote(ev, value) {
  if (value === "") message.value = props.data.message
  if (ev instanceof FocusEvent && value.trim()) {
    const data = {
      message: value.trim(),
      updatedAt: "now"
    }
    store.dispatch('noteModule/updateNote', {id: props.data.id, data})
        .then(note => {
          if (note) date.value = new Date(note.updatedAt).toLocaleString()
        })
  }
}
function removeNote(id) {
  if (confirm(`Voulez-vous supprimer la note id:${id}`)) {
    store.dispatch('noteModule/removeNote', {id, type: props.parent.type})
  }
}
function isTyping(bool) {
  const currentUser = store.getters['userModule/getCurrentUser']
  if (!userIsTyping.value) {
    if (bool) {
      userIsTyping.value = true
      const payload = {id: currentUser.id, state: bool, message: `${currentUser.username} est en train d'écrire...`, parent: props.parent}
      if (Config.isProd()) socketInstance.emit('note:startTyping', payload)
    }
  } else if(!bool) {
    userIsTyping.value = false
    if (Config.isProd()) socketInstance.emit('note:stopTyping', {id: currentUser.id, state: bool, parent: props.parent})
  }
}

onMounted(() => {
  if (props.data?.message) message.value = props.data.message
  if (props.data?.updatedAt) date.value = new Date(props.data.updatedAt).toLocaleString()
  else if (props.data?.createdAt) date.value = new Date(props.data.createdAt).toLocaleString()
  else date.value = ""
})


function watchData(newValue) {
  message.value = newValue.message
  date.value = new Date(props.data.updatedAt).toLocaleString()
}

watch(() => props.data, watchData)
</script>

<template>
  <div v-if="data?.message.length" class="message">
    <div class="header">
      <SvgIconComponent name="account-circle" />
      <span class="user">{{ data.user }}</span>
    </div>
    <div v-if="isAuthor && !isArchived" class="content">
      <SimpleTinyMceComponent
          class="note"
          name="message_note"
          :inlineOption="true"
          @blur="updateNote"
          v-model="message"/>
    </div>
    <div v-else v-html="message" class="content no-edit"></div>

    <div class="footer">
      <span class="date">{{ date }}</span>
      <a @click="removeNote(data.id)" v-if="isAuthor && !isArchived" class="remove-note">supprimer</a>
    </div>
  </div>

  <div v-else-if="!isArchived" class="create-message">
    <div class="user">{{ getUsername }} :</div>
    <SimpleTinyMceComponent
        name="message_note"
        inputPlaceholder="Écrire une note..."
        :inlineOption="true"
        @keyup="isTyping(true)"
        @blur="isTyping(false)"
        v-model="createMessage"/>
    <button @click="createNote" class="save-button">
      <SvgIconComponent name="save" />
      enregistrer
    </button>
  </div>
</template>