<script setup>
import {ref, defineProps, computed} from "vue"
import store from "../../store"
import {DateTime, Interval, Duration} from "luxon"

const props = defineProps({
  start: String,
  end: String,
  isJSDate: Boolean
})

const noWorkingDays = ref(['samedi', 'dimanche'])

const getHolidaysList = computed(() => {
  return store.getters['publicHolidaysModule/getItems']
})
const getStrHolidays = computed(() => {
  let str = ''
  const arrHolidays = Object.values(getHolidayDaysInInterval())
  if (arrHolidays.length) str = arrHolidays.join(', ')
  return str
})
const getStrNbrOfDay = computed(() => {
  const nbr = getInterval().count('days')
  const weekDays = getNoWorkingDaysInInterval()
  const holidays = countHolidaysWithoutWeekend()
  const total = nbr - weekDays - holidays
  const weekendPlural = weekDays > 1 ? 's' : ''
  const plural = total > 1 ? 's' : ''
  const strWeekend = weekDays ? `(${weekDays / 2} weekend${weekendPlural})` : ''

  return `${total} jour${plural} de congé${plural} ${strWeekend}`
})

function getNoWorkingDaysInInterval() {
  let arrWeekend = {}
  const interval = getInterval()
  const arrInt = interval.splitBy(Duration.fromMillis(86400 * 1000))

  if (arrInt.length) {
    arrInt.forEach(int => {
      if (noWorkingDays.value.includes(int.s.weekdayLong)) arrWeekend[int.s.toFormat('yyyy-MM-dd')] = int.s.weekdayLong
      if (noWorkingDays.value.includes(int.e.weekdayLong)) arrWeekend[int.e.toFormat('yyyy-MM-dd')] = int.e.weekdayLong
    })
  }

  return Object.values(arrWeekend).length
}
function getHolidayDaysInInterval() {
  let arrHolidays = {}
  const interval = getInterval()
  const arrInt = interval.splitBy(Duration.fromMillis(86400 * 1000))

  if (arrInt.length) {
    arrInt.forEach(int => {
      if (int.s.toFormat('yyyy-MM-dd') in getHolidaysList.value) arrHolidays[int.s.toFormat('yyyy-MM-dd')] = getHolidaysList.value[int.s.toFormat('yyyy-MM-dd')]
      if (int.e.toFormat('yyyy-MM-dd') in getHolidaysList.value) arrHolidays[int.e.toFormat('yyyy-MM-dd')] = getHolidaysList.value[int.e.toFormat('yyyy-MM-dd')]
    })
  }

  return arrHolidays
}

function countHolidaysWithoutWeekend() {
  let count = 0
  const holidays = getHolidayDaysInInterval()
  const arrDates = Object.keys(holidays)
  if (arrDates.length) arrDates.forEach(date => {
    if (!noWorkingDays.value.includes(DateTime.fromFormat(date, 'yyyy-MM-dd').weekdayLong)) count++
  })

  return count
}

function getStartDateTime() {
  if (props.isJSDate) return DateTime.fromFormat(props.start, 'yyyy-MM-dd')
  else return DateTime.fromISO(props.start, { locale: "fr" })
}

function getEndDateTime() {
  if (props.isJSDate) return DateTime.fromFormat(props.end, 'yyyy-MM-dd')
  else return DateTime.fromISO(props.end, { locale: "fr" })
}

function getInterval() {
  return Interval.fromDateTimes(getStartDateTime(), getEndDateTime().endOf('day'))
}
</script>

<template>
  <div>
    <p>{{ getStrNbrOfDay }}</p>
    <p>{{ getStrHolidays }}</p>
  </div>
</template>
