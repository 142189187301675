import Axios from "axios"
import Config from "../Utils/Config"
import store from "../store"

export default {
    getHeaders(contentType = "application/json") {
        return {'Authorization': `Bearer ${store.getters['securityModule/getToken']}`, 'content-type': contentType}
    },
    async getAll() {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/public_holidays`, {headers: this.getHeaders()})
                .then(response => resolve(response?.data['hydra:member']))
                .catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                    reject(error)
                })
        })
    },
    async check(year) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/public_holidays?year=${year}`, {headers: this.getHeaders()})
                .then(response => resolve(response.data['hydra:totalItems']))
                .catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                    reject(error)
                })
        })
    },
    async create(year) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getHolidaysApiUrl()}/metropole/${year}.json`, {headers: {'content-type': 'application/json'}})
                .then(response => {
                    const dates = Object.keys(response.data)
                    dates.forEach((date) => {
                        const data = {year: year.toString(),date, name: response.data[date]}
                        Axios({method: 'post', url: `${Config.getApiUrl()}/public_holidays`, data, headers: this.getHeaders()})
                            .then(response => resolve(response?.data['hydra:member']))
                            .catch(error => {
                                store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                                reject(error)
                            })
                    })
                })
        })
    },
}