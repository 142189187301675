<script setup>
import {ref, computed, useSlots} from "vue"
import SvgIconComponent from "../../components/items/SvgIconComponent"
import store from "../../store"

const slots = useSlots()
const noteBtnState = ref(false)

const countNotes = computed(() => {
  return store.getters['noteModule/countCurrent']
})
const getNoteContent = computed(() => {
  return slots.note
})
const getRightContent = computed(() => {
  return slots.right
})

function toggleNote() {
  noteBtnState.value = !noteBtnState.value
}
</script>

<template>
  <div class="multi-column">
    <div v-if="getNoteContent" class="note-container" :class="noteBtnState ? 'open' : 'close'">
        <div class="slot-container card">
          <slot name="note"></slot>
        </div>
        <div class="toggle-button" :title="noteBtnState ? 'fermer' : 'ouvrir'" @click="toggleNote()">
          <SvgIconComponent name="speaker-note" />
          <SvgIconComponent name="add" class="close" />
          <div class="count">
            {{countNotes}}
          </div>
        </div>
    </div>

    <div class="main-container" :class="getRightContent ? 'has-right-content' : ''">
      <div class="card main">
        <slot name="main">
          <p>Aucun contenu pour l'instant :/</p>
        </slot>
      </div>

      <div v-if="getRightContent" class="card right">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>