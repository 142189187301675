<script setup>
import {computed, onMounted} from "vue"
import store from "./store"
import TopBar from "./components/parts/TopBar"
import DockComponent from "./components/parts/DockComponent"
import MessageFlash from "./components/items/MessageFlash"
import Footer from "./components/parts/Footer"
import AnniversaryComponent from "@/components/items/AnniversaryComponent.vue";

const isLoggedIn = computed(() => {
  return store.getters['securityModule/isLoggedIn']
})

onMounted(() => {
  if (isLoggedIn.value) store.dispatch('userModule/getUser')
})
</script>

<template>
  <TopBar v-if="isLoggedIn" />
  <MessageFlash />
  <AnniversaryComponent />
  <router-view/>

  <div id="gifContainer"></div> <!-- teleport -->
  <div id="endOfBody"></div> <!-- teleport -->
  <DockComponent v-if="isLoggedIn" />
  <Footer />
</template>