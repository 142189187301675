<script setup>
import {ref, defineProps, computed} from "vue"
import StatsUtils from "../../Utils/StatsUtils"
import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, SubTitle, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'

ChartJS.register(Title, SubTitle, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const props = defineProps({
  projects: Array
})

// DATA
const chartOptions = ref({
  plugins: {
    legend: {display: false},
    title: {display: true, text: "Temps de travail", padding: {bottom: 0}, font: {size: 22}},
    subtitle: {display: true, text: "Jours de production", padding: {bottom: 20}, font: {size: 14}},
  },
  scales: {
    y: {
      ticks: {
        callback: function (value) {
          const plural = value > 1 ? "s" : ""
          return `${value} jour${plural}`
        }
      }
    }
  },
  responsive: true
})

// COMPUTED
const chartData = computed(() => {
  const chartData = {
    labels: [],
    datasets: []
  }

  if (props.projects) {
    chartData.labels = getProjectLabels.value
    chartData.datasets = [
      {label: 'Jours de prod', data: getTotalTimes.value, backgroundColor: '#ffd100'},
    ]
  }

  return chartData
})
const getProjectLabels = computed(() => {
  const labels = []
  props.projects.forEach(project => {
    labels.push([project.client.name, project.name])
  })

  return labels
})
const getTotalTimes = computed(() => {
  const totalTimes = []
  props.projects.forEach(project => {
    totalTimes.push(Math.round(StatsUtils.getTotalTimes(project.times) / 8))
  })

  return totalTimes
})
</script>

<template>
  <Bar :chart-options="chartOptions" :chart-data="chartData"/>
</template>