<template>
  <div v-if="active" ref="imageContainer" :class="active ? 'active' : ''" class="image-preview">
    <img :src="src">
  </div>
</template>

<script lang="js">
export default {
  name: "ImagePreviewComponent",
  props: {
    src: String,
    active: Boolean
  },
  mounted() {
    window.addEventListener('mousemove', this.followMouse)
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.followMouse)
  },
  methods: {
    followMouse(ev) {
      if (this.active) {
        this.$refs.imageContainer.style.top = `${ev.y + 40}px`
        this.$refs.imageContainer.style.left = `${ev.x - 149}px`
      }
    }
  }
}
</script>