<script setup>
import {watch, ref, defineProps, computed, defineEmits} from "vue"
import store from "../../store"
import IconTitleComponent from "../items/IconTitleComponent"
import SvgIconComponent from "../items/SvgIconComponent"

const emit = defineEmits(['close'])

const props = defineProps({
  parentIri: String,
  saveAndClose: {
    type: Boolean,
    default: false
  },
  edit: {
    type: Object,
    default() {
      return {}
    }
  }
})

// DATA
const editing = ref(false)
const firstname = ref("")
const lastname = ref("")
const job = ref("")
const phoneLabel = ref("")
const phoneValue = ref("")
const phones = ref([])
const emails = ref([])
const emailLabel = ref("")
const emailValue = ref("")
const firstnameInput = ref("")
const emailLabelInput = ref("")
const phoneLabelInput = ref("")

// COMPUTED
const activeSaveButton = computed(() => {
  return !firstname.value || !lastname.value
})

const buttonLabel = computed(() => {
  let label = `${editing.value ? 'mettre à jour' : 'enregistrer'} le contact`
  if (props.saveAndClose) label = `${label} et fermer`

  return label
})

// METHODS
function reset() {
  editing.value = false
  firstname.value = ""
  lastname.value = ""
  job.value = ""
  phoneLabel.value = ""
  phoneValue.value = ""
  phones.value = []
  emails.value = []
  emailLabel.value = ""
  emailValue.value = ""
  firstnameInput.value.focus()
}
function addPhone() {
  if (phoneValue.value) {
    if (!phoneLabel.value) phoneLabel.value = "Bureau"
    phones.value = [...phones.value, {label: phoneLabel.value, number: phoneValue.value}]
    phoneValue.value = ""
    phoneLabel.value = ""
    phoneLabelInput.value.focus()
  }
}
function addEmail() {
  if (emailValue.value) {
    if (!emailLabel.value) emailLabel.value = "Bureau"
    emails.value = [...emails.value, {label: emailLabel.value, value: emailValue.value}]
    emailValue.value = ""
    emailLabel.value = ""
    emailLabelInput.value.focus()
  }
}
function getData() {
  if (phoneValue.value) addPhone()
  if (emailValue.value) addEmail()

  return {
    firstname: firstname.value,
    lastname: lastname.value,
    job: job.value,
    emails: emails.value,
    phones: phones.value,
    client: props.parentIri,
  }
}
function editContact() {
  if (firstname.value && lastname.value) {
    const data = getData()
    if (editing.value) store.dispatch('contactModule/updateContact', {id: props.edit.id, data})
    else store.dispatch('contactModule/createContact', data)

    if (props.saveAndClose) {
      emit('close')
    } else {
      setTimeout(() => reset(), 500)
    }
  }
}
function removePhone(item) {
  phones.value = phones.value.filter(phone => phone.number !== item.number)
}
function removeEmail(item) {
  emails.value = emails.value.filter(email => email.value !== item.value)
}

watch(() => props.edit, (newValue) => {
  reset()
  if (Object.entries(newValue).length) {
    firstname.value = newValue.firstname ?? ""
    lastname.value = newValue.lastname ?? ""
    job.value = newValue.job ?? ""
    phones.value = newValue.phones ?? []
    emails.value = newValue.emails ?? []
    editing.value = true
  }
})

</script>

<template>
  <IconTitleComponent title="Créer un contact" iconName="contacts" titleSize="h3"/>

  <form class="create-contact-container">
    <div class="identity bloc">
      <IconTitleComponent title="Identité" iconName="user" titleSize="h4"/>
      <input ref="firstnameInput" type="text" name="firstname" placeholder="prénom" v-model="firstname" autocomplete="false">
      <input type="text" name="lastname" placeholder="nom" v-model="lastname" autocomplete="false">
    </div>

    <div class="job bloc">
      <IconTitleComponent title="Poste occupé" iconName="work" titleSize="h4"/>
      <input type="text" name="job" placeholder="poste" v-model="job">
    </div>

    <div class="phones bloc">
      <IconTitleComponent title="Téléphones" iconName="contact-phone" titleSize="h4"/>

      <ul v-if="phones">
        <li v-for="(phone, index) in phones" :key="index">
          <SvgIconComponent name="delete-forever" class="icon-delete" @click="removePhone(phone)" />
          <span><strong>{{ phone.label }} : </strong></span>
          <span>{{ phone.number }}</span>
        </li>
      </ul>

      <div class="create">
        <input ref="phoneLabelInput" type="text" v-model="phoneLabel" placeholder="label du numéro" autocomplete="false">
        <input type="tel" v-model="phoneValue" @keyup.enter="addPhone" placeholder="numéro &ldca;" autocomplete="false">
        <button type="button" @click.prevent="addPhone" class="btn-primary"><SvgIconComponent name="add"/></button>
      </div>
    </div>

    <div class="emails bloc">
      <IconTitleComponent title="Emails" iconName="contact-mail" titleSize="h4"/>

      <ul v-if="emails">
        <li v-for="(email, index) in emails" :key="index">
          <SvgIconComponent name="delete-forever" class="icon-delete" @click="removeEmail(email)" />
          <span><strong>{{ email.label }} : </strong></span><span>{{ email.value }}</span>
        </li>
      </ul>

      <div class="create">
        <input ref="emailLabelInput" type="text" v-model="emailLabel" placeholder="label de l'email" autocomplete="false">
        <input type="email" v-model="emailValue" @keyup.enter="addEmail" placeholder="email &ldca;" autocomplete="false">
        <button type="button" @click.prevent="addEmail" class="btn-primary"><SvgIconComponent name="add"/></button>
      </div>
    </div>

    <button type="button" @click.prevent="editContact" class="save-button" :disabled="activeSaveButton">
      <SvgIconComponent name="save"/> {{ buttonLabel }}
    </button>
  </form>
</template>