<script setup>
import {onMounted, ref, computed, onUnmounted} from "vue"
import store from "../../store"
import {useRoute} from "vue-router"
import Utils from "../../Utils/Utils"
import Config from "../../Utils/Config"
import TinyMceComponent from "../fields/TinyMceComponent"
import SvgIconComponent from "../items/SvgIconComponent"
import IconTitleComponent from "../items/IconTitleComponent"
import CreateLinkComponent from "../links/createLinkComponent"
import LinkListComponent from "../links/LinkListComponent"
import ContactListComponent from "@/components/contacts/ContactListComponent.vue";

const route = useRoute()

// DATA
const editing = ref(false)
const iri = ref("")
const logoUrl = ref("")
const clientId = ref("")
const clientName = ref("")
const reference = ref("")
const description = ref("")
const address = ref("")
const createdAt = ref("")
const updatedAt = ref("")
const links = ref([])
const isArchived = ref(false)
const media = ref(null)

// COMPUTED
const contacts = computed(() => {
  return store.getters['contactModule/getItems']
})

// METHODS
function updateReference(ev) {
  const value = Utils.refify(ev.target.value)
  const oldValue = store.state.clientModule.current.reference
  if (oldValue !== value) {
    store.dispatch('clientModule/checkReference', value)
        .then(response => {
          if (response) {
            store.commit('addMessage', {state: 'error', content: `La référence :  "${value}" existe déjà`})
            reference.value = oldValue
          } else {
            store.dispatch('clientModule/updateClient', {
              id: clientId.value, data: {reference: value, updatedAt: 'now'}
            }).then(response => {
              if (response) {
                updatedAt.value = response.updatedAt
                reference.value = value
              }
            })
          }
        })
  }
  editing.value = false
}
function handleEvent(ev, value) {
  if (ev instanceof FocusEvent) {
    const inputName = ev.target.getAttribute('name')?.split('_')[1]
    const oldValue = store.state.clientModule.current[inputName]

    if (oldValue !== value) {
      const data = {updatedAt: 'now'}
      data[inputName] = value ?? ev.target?.value

      store.dispatch('clientModule/updateClient', {
        id: clientId.value,
        data: data
      }).then(response => {
        if (response) updatedAt.value = response.updatedAt
      })
    }
  }
  editing.value = false
}
function sendFile() {
  const file = media.value.files[0]
  if (file) {
    if (logoUrl.value) {
      store.dispatch('mediaModule/removeFile', store.getters['clientModule/getCurrent'].logo)
    }
    const form = new FormData()
    form.append('file', file)
    form.append('filename', file.name)

    store.dispatch('mediaModule/sendFile', form)
        .then(url => {
          if (url.contentUrl) {
            store.dispatch('clientModule/updateClient', {
              id: clientId.value,
              data: {logo: url['@id'], updatedAt: "now"}
            }).then(client => {
              if (client.logo) {
                store.dispatch('mediaModule/getFile', client.logo)
                    .then(file => {
                      logoUrl.value = `${Config.getMediaUrl()}/${file.data.contentUrl}`
                    })
              }
            })
          }
        })
  }
}

// LIFECYCLE
onMounted(() => {
  clientId.value = route.params.id
  store.dispatch('clientModule/getClientById', clientId.value)
      .then(response => {
        if (response) {
          clientName.value = response.name
          reference.value = response.reference
          description.value = response.description
          address.value = response.address
          createdAt.value = response.createdAt
          updatedAt.value = response.updatedAt
          isArchived.value = response.isArchived
          links.value = response.links
          iri.value = response['@id']

          if (response.logo) {
            store.dispatch('mediaModule/getFile', response.logo)
                .then(file => {
                  logoUrl.value = `${Config.getMediaUrl()}/${file.data.contentUrl}`
                })
          }

          store.commit('noteModule/setParent', {type: "client", iri: response['@id']})
          store.dispatch('noteModule/getListByType', {id: response.id, type: "client"})

          const client = store.getters['clientModule/getCurrent'].id
          if (client) Utils.setNewViewedClient(client)
        }
      })

  store.dispatch('contactModule/getContactList', clientId.value)
})

onUnmounted(() => {
  store.commit('contactModule/resetContact')
  store.commit('noteModule/resetNotes')
})
</script>

<template>
  <div class="client-single">
    <div v-if="isArchived" class="is-archived">
      Ce client est archivé
    </div>

    <div class="single-content">
      <p v-if="updatedAt" class="updated-date">mis à jour : {{ new Date(updatedAt).toLocaleString() }}</p>

      <div class="header">
        <div class="logo">
          <div class="input-file" v-if="!isArchived" :class="!logoUrl ? 'placeholder' : ''">
            <p v-if="logoUrl">Remplacer le logo</p>
            <p v-else>Ajouter un logo</p>
            <input type="file" name="media" ref="media" @change="sendFile" title="Cliquez et choisissez un logo"/>
          </div>
          <img v-if="logoUrl" :src="logoUrl" alt="Logo du client">
        </div>

        <div>
          <div v-if="!isArchived" class="input-container name">
            <div v-show="editing !== 'name'" @click="editing = 'name'" class="input">{{ clientName }}</div>
            <input type="text"
                   name="client_name"
                   v-show="editing === 'name'"
                   v-focus="editing"
                   @blur="handleEvent"
                   @keyup.enter="(ev) => ev.target.blur()"
                   v-model="clientName"/>
          </div>
          <div v-else class="name">{{ clientName }}</div>

          <div v-if="!isArchived" class="input-container bloc reference">
            <label>Réf. : </label>
            <div v-show="editing !== 'reference'" @click="editing = 'reference'" class="input" v-html="reference"></div>
            <input type="text"
                   name="client_reference"
                   v-show="editing === 'reference'"
                   v-focus="editing"
                   @blur="updateReference"
                   @keyup.enter="(ev) => ev.target.blur()"
                   v-model="reference"/>
          </div>
          <div v-else class="reference bloc">
            <span class="label">Réf. : </span><span>{{ reference }}</span>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="main-content">
          <div v-if="!isArchived" class="textarea-container bloc">
            <IconTitleComponent class="label-icon" title="Description" iconName="wysiwyg" titleSize="h3"/>
            <TinyMceComponent
                id="description"
                name="client_description"
                inputPlaceholder="Écrivez une description"
                :inlineOption="true"
                @blur="handleEvent"
                v-model="description"/>
          </div>
          <div v-else class="bloc">
            <label for="description" class="label-icon">
              <SvgIconComponent name="wysiwyg"/>
              Description</label>
            <div v-html="description"></div>
          </div>

          <div v-if="!isArchived" class="textarea-container bloc">
            <IconTitleComponent class="label-icon" title="Adresse" iconName="home" titleSize="h3"/>
            <TinyMceComponent
                id="contact"
                name="client_address"
                inputPlaceholder="Renseignez une adresse"
                :inlineOption="true"
                @blur="handleEvent"
                v-model="address"/>
          </div>
          <div v-else class="bloc">
            <label for="contact" class="label-icon">
              <SvgIconComponent name="contacts"/>
              Adresse</label>
            <div v-html="address"></div>
          </div>

          <div v-if="!isArchived" class="bloc">
            <IconTitleComponent class="label-icon" title="Contacts" iconName="contacts" titleSize="h3"/>

            <ContactListComponent :contacts="contacts" />
          </div>
        </div>

        <div class="side-content">
          <IconTitleComponent title="Liens" iconName="link" titleSize="small"/>
          <CreateLinkComponent v-if="!isArchived" :parent="{type: 'client', iri}"/>
          <LinkListComponent parentType="client"/>
        </div>
      </div>
    </div>
  </div>
</template>