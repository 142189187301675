import {Buffer} from "buffer";

const fileType = {
    svg: 'image',
    svgz: 'image',
    png: 'image',
    jpeg: 'image',
    jpg: 'image',
    gif: 'image',
    webp: 'image',
    pdf: 'pdf',
    md: 'document',
    csv: 'document',
    odt: 'document',
    ods: 'document',
    odp: 'document',
    odg: 'document',
    ott: 'document',
    ots: 'document',
    otp: 'document',
    otg: 'document',
    doc: 'document',
    docx: 'document',
    dot: 'document',
    dotx: 'document',
    xls: 'document',
    xlsx: 'document',
    xlt: 'document',
    xltx: 'document',
    ppt: 'document',
    pptx: 'document',
    pot: 'document',
    potx: 'document',
    mp3: 'audio',
    mp4: 'video',
    m4a: 'video',
    m4v: 'video',
    webm: 'video',
    ogg: 'video',
    ogv: 'video',
    wma: 'video',
    wmv: 'video',
    ram: 'video',
    rm: 'video',
    mov: 'video',
}

export default class Utils {
    static parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString().split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    static jwtIsExpired() {
        if (!localStorage.getItem('token')) return true
        const objToken = this.parseJwt(localStorage.getItem('token'))
        return objToken.exp - (Date.now() / 1000) <= 0
    }

    static refify(string) {
        if (!string) return ''
        string = string.replace(/^\s+|\s+$/g, '').toLowerCase();

        const from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/-,:;_";
        const to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa_______";
        for (let i = 0, l = from.length; i < l; i++) {
            string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        return string.replace(/[^a-z0-9 -]/g, '_')
            .replace(/\s+/g, '_')
            .replace(/-+/g, '_');
    }

    /*eslint-disable */
    static generateUUID() {
        let d = new Date().getTime()
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = (d + (Math.random() * 16)) % 16 | 0
            d = Math.floor(d / 16)
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
        })
    }

    static getFileType(extension) {
        return fileType[extension]
    }

    static setNewViewedClient(id) {
        const recentClients = localStorage.getItem('recent-client') ? JSON.parse(localStorage.getItem('recent-client')) : []
        recentClients.unshift(id)
        localStorage.setItem('recent-client', JSON.stringify([...new Set(recentClients)]))
    }

    static setDashboardState(key, value) {
        const state = this.getDashboardState() ?? {}
        state[key] = value
        localStorage.setItem('dashboard_state', JSON.stringify(state))
    }

    static getDashboardState() {
        return JSON.parse(localStorage.getItem('dashboard_state'))
    }

    static getDashboardStateByKey(key) {
        const dashboardState = this.getDashboardState()
        if (dashboardState) return dashboardState[key]
        else return false
    }

    static randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    static getGifByWeatherCode(weatherCode) {
        if (weatherCode === "Clear") {
            return require(`@/assets/videos/sunny/0${Utils.randomIntFromInterval(1, 5)}.webp`)
        } else if (weatherCode === "Clouds") {
            return require(`@/assets/videos/cloudy/0${Utils.randomIntFromInterval(1, 4)}.webp`)
        } else if (weatherCode === "Fog") {
            return require(`@/assets/videos/foggy/0${Utils.randomIntFromInterval(1, 4)}.webp`)
        } else if (weatherCode === "Rain") {
            return require(`@/assets/videos/rain/0${Utils.randomIntFromInterval(1, 5)}.webp`)
        } else if (weatherCode === "Rain") {
            return require(`@/assets/videos/rain/0${Utils.randomIntFromInterval(1, 4)}.webp`)
        } else return require('@/assets/images/weather-default.jpg')
    }

    static getRandomGifByFolderName(folder, nbrOfItems) {
        return require(`@/assets/videos/${folder}/0${Utils.randomIntFromInterval(1, nbrOfItems)}.webp`)
    }
}