<script setup>
import SvgIconComponent from "../items/SvgIconComponent"
import {computed, defineProps, onMounted, ref} from "vue"
import store from "../../store"
import StatsUtils from "../../Utils/StatsUtils"
import {DateTime} from "luxon"
import DateUtils from "../../Utils/DateUtils"

const props = defineProps({
  project: Object,
  client: Object,
  template: {
    type: String,
    default: 'project'
  }
})

const progress = ref(0)

const isArchived = computed(() => {
  return store.getters['clientModule/getArchiveMode']
})
const isAdmin = computed(() => {
  return store.getters['userModule/isAdmin']
})
const user = computed(() => {
  return store.getters['userModule/getCurrentUser']
})

function getProgressState() {
  if (progress.value > 65 && progress.value <= 93) return "warning"
  else if (progress.value > 93) return "danger"
  else return "success"
}

function getProgressWidth() {
  if (progress.value >= 100) return "width: 100%;"
  else return `width: ${progress.value}%;`
}

function deleteProject(id) {
  if (confirm(`Voulez-vous supprimer le projets id: ${id} ?`)) {
    store.dispatch('projectModule/removeProject', id)
  }
}

function openWindow(project) {
  const data = {
    state: true,
    projectId: props.project['@id'],
    clientId: props.client['@id'],
    projectName: project.name.toString()
  }

  if (props.template === "dashboard") {
    data['clientName'] = props.client.name
  }
  store.commit('timesModule/setAddTimes', data)
}

onMounted(() => {
  let times = props.project.times

  if (times.length && props.project.yearly) {
    if (times.length) times = times.filter(time => {
      return time.date > DateTime.fromISO(DateUtils.getYearlyDate(props.project)).toMillis() / 1000
    })
  }

  if (props.project.state === 1) progress.value = 100 - parseInt(StatsUtils.getMargin(props.project, times))
  else progress.value = 0
})
</script>

<template>
  <div>
    <div class="state"></div>
    <p>{{ project.name }}</p>
    <i v-if="project.updatedAt" class="date">Mis à jour le {{ new Date(project.updatedAt).toLocaleString() }}</i>
    <i v-else class="date">Créé le {{ new Date(project.createdAt).toLocaleString() }}</i>
  </div>

  <div class="actions">
    <div v-if="!isArchived" class="button-item set-times" title="Ajouter du temps à ce projet" @click.stop="openWindow(project)">
      <SvgIconComponent name="add-times"/>
    </div>
    <router-link :to="{'name': 'projectSingle', 'params' : {'id': project.id}}">voir le projet</router-link>
    <div v-if="(user.id === project.user.id || isAdmin) && !isArchived" class="button-item delete-project"
         :title="`supprimer ${project.name} ?`" @click.stop="deleteProject(project.id)">
      <SvgIconComponent name="delete-forever"/>
    </div>
  </div>
  <div :class="getProgressState(project)" class="margin-progress" :style="getProgressWidth(project)"></div>
</template>