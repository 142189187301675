<script setup>
import {computed, onBeforeMount, onUnmounted} from "vue"
import store from "./../store"
import ClientListComponent from "../components/client/ClientListComponent"
import ClientCreateComponent from "../components/client/ClientCreateComponent"
import LeaveResumeComponent from "../components/leave/LeaveResumeComponent"
import ProjectStatsBulkComponent from "../components/projects/ProjectStatsBulkComponent"
import IconTitleComponent from "../components/items/IconTitleComponent"
import ProjectWallOfShame from "../components/projects/ProjectWallOfShame"
import SearchComponent from "../components/items/SearchComponent"
import PaginationComponent from "../components/items/PaginationComponent"
import {useRoute} from "vue-router"
import ClientStatsResumeComponent from "../components/client/ClientStatsResumeComponent"
import DashboardItem from "./DashboardItem"
import MotivationsComponent from "../components/items/MotivationsComponent"
import ProjectYearlyStatsComponent from "../components/projects/ProjectYearlyStatsComponent.vue"
import TodoComponent from "../components/todo/TodoComponent.vue";
import WeatherComponent from "../components/weather/WeatherComponent.vue";

const route = useRoute()

const searchMainDispatch = {
  action: 'clientModule/searchClient',
  payload: {}
}

const getUser = computed(() => {
  return store.getters['userModule/getCurrentUser']
})

const searchResetDispatch = {
  action: 'clientModule/getClientList',
  payload: {}
}

const isAdmin = computed(() => {
  return store.getters['userModule/isAdmin']
})

const clients = computed(() => {
  return store.getters['clientModule/getItems']
})

const pagination = computed(() => {
  return store.getters['clientModule/getPagination']
})

function update(todo, user) {
  store.dispatch('userModule/updateUser', {username: user.username, data: {todo}})
}

onBeforeMount(() => {
  const page = route.query.page
  store.dispatch('clientModule/getClientList', page ? 'page='+page : '')
  store.commit('clientModule/setArchiveMode', false)
  store.dispatch('userModule/getActiveMemberList')
  store.commit('topBarModule/setMainTitle',  {title: "Tableau de bord", icon: "home"})
  store.dispatch('settingsModule/getSettings')

})
onUnmounted(() => {
  store.commit('leaveModule/resetList')
})
</script>

<template>
  <div class="dashboard">
    <div class="dashboard-main card">
      <div class="dashboard-item-container">
        <SearchComponent :main-dispatch="searchMainDispatch" :reset-dispatch="searchResetDispatch" />
        <ClientCreateComponent />

        <ClientListComponent :clients="clients" />
        <PaginationComponent :pagination="pagination" />
      </div>
    </div>
    <div class="dashboard-side">
      <div class="weather-component">
        <WeatherComponent />
      </div>
      <DashboardItem stateKey="motivation">
        <template #title>
          <IconTitleComponent title="Besoin de motivation ? 💪"  title-size="h3"/>
        </template>
        <template #content>
          <MotivationsComponent />
        </template>
      </DashboardItem>

      <DashboardItem stateKey="personal-todo">
        <template #title>
          <IconTitleComponent title="Todo personnel" icon-name="todo-list" title-size="h3" />
        </template>
        <template #content>
          <TodoComponent :todo="getUser.todo" @onUpdate="update($event, getUser)" :gift-mode="false" />
        </template>
      </DashboardItem>

      <DashboardItem v-if="isAdmin" stateKey="stats">
        <template #title>
          <IconTitleComponent title="Stats" icon-name="stats" title-size="h3" />
        </template>
        <template #content>
          <ProjectStatsBulkComponent />
        </template>
      </DashboardItem>

      <DashboardItem v-if="isAdmin" stateKey="stats">
        <template #title>
          <IconTitleComponent title="Services annuels" icon-name="stats" title-size="h3" />
        </template>
        <template #content>
          <ProjectYearlyStatsComponent />
        </template>
      </DashboardItem>

      <DashboardItem v-if="isAdmin" stateKey="honorRoll">
        <template #title>
          <IconTitleComponent title="Tableau d'honneur* 🍾" icon-name="leader-board" title-size="h3" />
        </template>
        <template #content>
          <ClientStatsResumeComponent />
          <i class="more-information">*total de la marge des projets du client</i>
        </template>
      </DashboardItem>

      <DashboardItem v-if="isAdmin" stateKey="wallShame">
        <template #title>
          <IconTitleComponent title="Wall of shame* 👎" icon-name="leader-board" title-size="h3" />
        </template>
        <template #content>
          <ProjectWallOfShame />
          <i class="more-information">*projets les moins rentables</i>
        </template>
      </DashboardItem>

      <DashboardItem stateKey="nextLeave">
        <template #title>
          <IconTitleComponent title="Prochains congés" icon-name="calendar-range" title-size="h3" />
        </template>
        <template #content>
          <LeaveResumeComponent />
        </template>
      </DashboardItem>
    </div>
  </div>
</template>