import {createRouter, createWebHistory} from 'vue-router'
import store from '../store/index'
import Dashboard from '../views/Dashboard.vue'
import Login from "../views/security/Login"
import UserAccount from "../views/user/UserAccount"
import ClientSingle from "../views/clients/ClientSingle"
import ClientArchived from "../views/clients/ClientArchived"
import ProjectSingle from "../views/projects/ProjectSingle"
import LogList from "../views/logs/LogList"
import UserList from "../views/user/UserList"
import TimesDashboard from "../views/times/TimesDashboard"
import TimesUserList from "../views/times/TimesUserList"
import ProjectStats from "../views/projects/ProjectStats"
import Settings from "../views/settings/Settings"
import AskLeave from "../views/leave/LeaveAsk"
import ManageLeave from "../views/leave/LeaveManage"
import TodoPersonal from "../views/todos/TodoPersonal"
import TodoList from "../views/todos/TodoList"
import Utils from "../Utils/Utils"
import Contacts from "@/views/contacts/contacts.vue";
import TimesByUsers from "@/views/times/TimesByUsers.vue";

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {requireAuth: true}
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/account',
        name: 'account',
        component: UserAccount,
        meta: {requireAuth: true}
    },
    {
        path: '/client/:id',
        name: 'clientSingle',
        component: ClientSingle,
        meta: {requireAuth: true}
    },
    {
        path: '/client/archived',
        name: 'archivedClients',
        component: ClientArchived,
        meta: {requireAuth: true}
    },
    {
        path: '/project/:id',
        name: 'projectSingle',
        component: ProjectSingle,
        meta: {requireAuth: true}
    },
    {
        path: '/project/stats',
        name: 'projectStats',
        component: ProjectStats,
        meta: {
            requireAuth: true,
            requireAdmin: true
        }
    },
    {
        path: '/logs',
        name: 'logs',
        component: LogList,
        meta: {
            requireAuth: true,
            requireAdmin: true
        }
    },
    {
        path: '/times/dashboard',
        name: 'timesDashboard',
        component: TimesDashboard,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/times/user-dashboard',
        name: 'timesUserDashboard',
        component: TimesUserList,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/times/users',
        name: 'timesByUsers',
        component: TimesByUsers,
        meta: {
            requireAdmin: true,
            requireAuth: true
        }
    },
    {
        path: '/users',
        name: 'users',
        component: UserList,
        meta: {
            requireAuth: true,
            requireAdmin: true
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            requireAuth: true,
            requireAdmin: true
        }
    },
    {
        path: '/leave/manage',
        name: 'leave_manage',
        component: ManageLeave,
        meta: {
            requireAuth: true,
            requireAdmin: true
        }
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: Contacts,
        meta: {
            requireAuth: true,
            requireAdmin: true
        }
    },
    {
        path: '/leave/ask',
        name: 'leave_ask',
        component: AskLeave,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/todo/personal',
        name: 'personalTodo',
        component: TodoPersonal,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/todo/list',
        name: 'todoList',
        component: TodoList,
        meta: {
            requireAuth: true,
            requireManager: true
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (store.getters['securityModule/isLoggedIn']) {
            if (Utils.jwtIsExpired()) {
                store.dispatch('securityModule/logout')
                next({name: 'login'})
            }

            if (to.matched.some(record => record.meta.requireAdmin)) {
                store.dispatch('userModule/getUser')
                    .then(user => {
                        if (user?.roles.includes('ROLE_ADMIN')) next()
                        else {
                            store.commit('addMessage', {
                                state: 'error',
                                content: `vous n'avez les droits pour accéder à cette ressource`
                            })
                            next({name: 'dashboard'})
                        }
                    })
            } else if (to.matched.some(record => record.meta.requireManager)) {
                store.dispatch('userModule/getUser')
                    .then(user => {
                        if (user?.roles.includes('ROLE_MANAGER')) next()
                        else {
                            store.commit('addMessage', {
                                state: 'error',
                                content: `vous n'avez les droits pour accéder à cette ressource`
                            })
                            next({name: 'dashboard'})
                        }
                    })
            } else next()
        } else next('/login')
    } else next()
})

export default router
