import projectApi from "../../api/project"
import Config from "../../Utils/Config"
import store from "../index"
import Utils from "../../Utils/Utils"

const state = () => ({
    current: {},
    attachments: [],
    items: [],
    parent: {}
})

const getters = {
    getItems: state => state.items,
    getCurrent: state => state.current,
    getState: () => (project, isRefify) => isRefify ? Utils.refify(Config.getProjectStateByKey(project.state)) : Config.getProjectStateByKey(project.state),
    getAttachments: state => state.attachments ?? []
}

const actions = {
    async searchProject({commit}, payload) {
        const projects = await projectApi.search(payload)
        commit('setProjectList', projects)
    },
    async getProjectList({commit}, payload) {
        const projects = await projectApi.getListByClient(payload)
        commit('setProjectList', projects)
    },
    async getProjectListByDate({commit}, payload) {
        const projects = await projectApi.getListByDate(payload)
        commit('setProjectList', projects)
    },
    async createProject({commit}, data) {
        const project = await projectApi.create(data)
        commit('addNewProject', project)
        return project
    },
    async getProjectById({commit}, id) {
        const project = await projectApi.get(id)
        commit('addCurrent', project)
        if (project.attachments) {
            project.attachments.forEach(item => {
                store.dispatch('mediaModule/getFile', item)
                    .then(file => {
                        if (file) commit('addAttachments', {
                            iri: file.data['@id'],
                            url: `${Config.getMediaUrl()}/${file.data.contentUrl}`,
                            name: file.data.filename,
                            extension: file.data.extension
                        })
                    })
            })
        }

        return project
    },
    async updateProject({commit}, payload) {
        const project = await projectApi.update(payload.id, payload.data)
        commit('addCurrent', project)
        commit('updateList', project)
        return project
    },
    async removeProject({commit}, id) {
        await projectApi.remove(id)
        commit('removeProject', id)
    },
    async checkReference(context, ref) {
        return await projectApi.referenceExist(ref)
    }
}

const mutations = {
    setProjectList(state, projects) {
        state.items = projects
    },
    addNewProject(state, project) {
        if (project) {
            state.items.unshift(project)
            store.commit('clientModule/addProject', project)
        }
    },
    removeProject(state, id) {
        state.items = state.items.filter(item => item.id !== id)
        store.commit('clientModule/removeProject', id)
    },
    addCurrent(state, project) {
        state.current = project
    },
    updateList(state, project) {
        state.items.map((item, index) => {
            if (item.id === project.id) state.items[index] = project
        })
    },
    resetAttachments(state) {
        state.attachments = []
    },
    addAttachments(state, file) {
        state.attachments.push(file)
    },
    removeAttachments(state, iri) {
        state.attachments = state.attachments.filter(file => file.iri !== iri)
    },
    resetProjects(state) {
        state.items = []
    },
    resetCurrent(state) {
        state.current = {}
    },
    setParent(state, parent) {
        state.parent = parent
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}