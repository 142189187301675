<script setup>
import {computed, onBeforeMount} from "vue"
import store from "./../../store"
import ClientListComponent from "../../components/client/ClientListComponent"
import MultiColumnTemplate from "../templates/MultiColumnTemplate"

const clients = computed(() => {
  return store.getters['clientModule/getArchivedItems']
})

onBeforeMount(() => {
  store.dispatch('clientModule/getArchivedClientList')
  store.commit('clientModule/setArchiveMode', true)
  store.commit('topBarModule/setMainTitle', {title: "Clients archivés", icon: 'people'})

})
</script>

<template>
  <MultiColumnTemplate>
    <template #main>
      <ClientListComponent :clients="clients" :emptyPlaceholder="'Il n\'y a aucun client d\'archivé pour le moment'" />
    </template>
  </MultiColumnTemplate>
</template>