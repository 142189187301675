<script setup>
import {ref} from "vue"
import store from "../../store"
import SvgIconComponent from "./SvgIconComponent"
import IconTitleComponent from "./IconTitleComponent"

// DATA
const isLoading = ref(false)
const generatedPassword = ref("MonSuperMotDePasse")
const passwordLength = ref(12)
const includeUpperCase = ref(false)
const includeNumber = ref(false)
const includeSymbol = ref(false)

// METHODS
function generatePassword() {
  if (!passwordLength.value) return
  triggerLoading(true)
  let password = ""
  let characters = "abcdefghijklmnopqrstuvwxyz"
  if (includeUpperCase.value) characters += "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  if (includeNumber.value) characters += "0123456789"
  if (includeSymbol.value) characters += "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~"
  for (let i = 0; i < passwordLength.value; i++) {
    password += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  generatedPassword.value = password
  triggerLoading(false)
}
function triggerLoading(state) {
  isLoading.value = state
}
function copyPassword() {
  if(!navigator.clipboard) store.commit('addMessage', {state: "warning", content: "Désolé, mais le navigateur n'est pas compatible avec cette fonctionnalité"})
  navigator.clipboard.writeText(generatedPassword.value)
      .then(() => store.commit('addMessage', {state: "success", content: "Mot passe copié !"}))
      .catch(() => store.commit('addMessage', {state: "danger", content: "Une erreur s'est produite."}))
}
</script>

<template>
  <div class="password-generator">
    <IconTitleComponent title="Générer un mot de passe" iconName="password" titleSize="h3" />
    <div class="generated-password-container" @click="copyPassword">
      <div class="generated-password">{{ generatedPassword }}</div>
      <div class="button-icon-rounded">
        <SvgIconComponent name="clipboard" />
      </div>
    </div>
    <div class="options">
      <div class="input-container">
        <label>Longueur du mot de passe : <span class="range-value">{{passwordLength}}</span></label>
        <input type="range" v-model="passwordLength" min="8" max="20" step="1" />
      </div>
      <div class="input-container checkbox">
        <input id="upperCase" type="checkbox" v-model="includeUpperCase">
        <label for="upperCase">Inclure des capitales</label>
      </div>
      <div class="input-container checkbox">
        <input id="number" type="checkbox" v-model="includeNumber">
        <label for="number">Inclure des chiffres</label>
      </div>
      <div class="input-container checkbox">
        <input id="symbol" type="checkbox" v-model="includeSymbol">
        <label for="symbol">Inclure des caractères spéciaux</label>
      </div>
      <button class="save-button" @click="generatePassword"><SvgIconComponent name="sync"/>Générer</button>
    </div>
  </div>
</template>