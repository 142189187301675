<script setup>
import {computed} from "vue"
import store from "../../store"
import StatsUtils from "../../Utils/StatsUtils"
import ProjectSearchComponent from "./ProjectSearchComponent"

// COMPUTED
const getProjectList = computed(() => {
  return store.getters['projectModule/getItems']
})

const getAverageStats = computed(() => {
  const average = []
  if (!getProjectList.value.length) return average
  let marginTotal = 0
  let gainTotal = 0
  let costTotal = 0
  let sellingTotal = 0
  const totalProjects = getProjectList.value.length
  getProjectList.value.forEach(p => {
    if (p.times.length) {
      const cost = StatsUtils.getTotalCost(p, p.times)
      if (cost > 0 && !isNaN(cost)) costTotal += cost
      if (p.sellingPrice > 0 && !isNaN(p.sellingPrice)) sellingTotal += p.sellingPrice
    }
  })

  gainTotal = sellingTotal - costTotal
  marginTotal = 100 - ((costTotal * 100) / sellingTotal)

  average.push({label: 'Vente total', unit: '€', value: Math.round(sellingTotal)})
  average.push({label: 'Coût total', unit: '€', value: Math.round(costTotal)})
  average.push({label: 'Gain total', unit: '€', value: Math.round(gainTotal)})
  average.push({label: 'Marge moyenne', unit: '%', value: Math.round(marginTotal)})
  average.push({label: 'Moyenne des gains', unit: '€', value: Math.round(gainTotal / totalProjects)})

  return average
})
</script>

<template>
  <ProjectSearchComponent />

  <div class="project-stats-bulk">
    <p>{{ getProjectList.length}} projet{{ getProjectList.length > 1 ? 's' : ''}}</p>
    <div v-for="(stats, index) in getAverageStats" :key="index" class="mini-card">
      <strong>{{stats.label}} :</strong> {{stats.value}}{{stats.unit}}
    </div>
  </div>
</template>