<script setup>
import {ref, onMounted, defineProps, watch} from "vue"
import ProjectSettingsComponent from "../projects/ProjectSettingsComponent.vue"
import TimeViewResumeComponent from "./TimeViewResumeComponent.vue"
import IconTitleComponent from "../items/IconTitleComponent.vue"
import SvgIconComponent from "../items/SvgIconComponent.vue"
import ProjectPieChartComponent from "../charts/ProjectPieChartComponent.vue"
import DateUtils from "../../Utils/DateUtils"
import {DateTime} from "luxon"

const props = defineProps({
  project: Object
})

const times = ref([])
const isOpened = ref(false)

function getTimes(project) {
  if (project.yearly) {
    times.value = []
    if (project.times.length) project.times.map(time => {
      if (time.date > DateTime.fromISO(DateUtils.getYearlyDate(project)).toMillis() / 1000) times.value.push(time)
    })
  } else times.value = project.times
}

onMounted(() => {
  getTimes(props.project)
})

function watchProject(newValue) {
  getTimes(newValue)
}
watch(() => props.project, watchProject)
</script>

<template>
  <div class="title">
    <router-link :to="{'name': 'projectSingle', 'params' : {'id': project.id}}" title="voir le projet">
      <h3>{{ project.client.name }} - {{ project.name }}</h3>
    </router-link>
    <div class="icons" @click="isOpened = !isOpened" :title="isOpened ? 'fermer' : 'voir la répartition'">
      <SvgIconComponent v-if="!isOpened" name="pie-chart"/>
      <SvgIconComponent v-if="isOpened" name="add" class="close-button"/>
    </div>
  </div>
  <div class="time-view">
    <div class="stats">
      <div class="left">
        <div class="project-settings-container">
          <IconTitleComponent title="Paramètre projet" iconName="settings" titleSize="h3"/>
          <ProjectSettingsComponent :project="project" :lightView="true"/>
        </div>
        <div>
          <IconTitleComponent title="Temps" iconName="times" titleSize="h3"/>
          <div class="times">
            <table class="times-table">
              <thead>
              <tr>
                <td>Date</td>
                <td>Utilisateur</td>
                <td>Temps</td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="time in times" :key="time.id">
                <td>{{ new Date(time.date * 1000).toLocaleDateString() }}</td>
                <td>{{ time.user.username }}</td>
                <td>{{ time.times }}h</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="project.dailyCost && project.sellingPrice" class="right">
        <IconTitleComponent title="Rentabilité" iconName="euro" titleSize="h3"/>
        <TimeViewResumeComponent :project="project" :times="times"/>
      </div>
    </div>
    <div v-if="isOpened" class="pie-chart">
      <ProjectPieChartComponent v-if="times" :times="times"/>
    </div>
  </div>
</template>