<script setup>
import {computed, onMounted, onUnmounted} from "vue"
import store from "../../store"
import MultiColumnTemplate from "../templates/MultiColumnTemplate"
import BackButton from "../../components/buttons/BackButton"
import NoteListComponent from "../../components/note/NoteListComponent"
import ProjectSingleComponent from "../../components/projects/ProjectSingleComponent"
import IconTitleComponent from "../../components/items/IconTitleComponent"
import ProjectSingleSettingsComponent from "../../components/projects/ProjectSingleSettingsComponent"
import {useRoute} from "vue-router"
import WindowComponent from "../../components/items/WindowComponent"
import AddTimeComponent from "../../components/times/AddTimeComponent"

const route = useRoute()

const getProject = computed(() => {
  return store.getters['projectModule/getCurrent']
})
const getAddTimes = computed(() => {
  return store.getters['timesModule/getAddTimes']
})

function getProjectState(isRefify = false) {
  return store.getters['projectModule/getState'](getProject.value, isRefify)
}

function closePopup() {
  store.commit('timesModule/setAddTimes', {state: false, projectId: '', projectName: ''})
}

onMounted(() => {
  store.dispatch('projectModule/getProjectById', route.params.id)
  store.dispatch('settingsModule/getSettings')
  store.dispatch('userModule/getActiveMemberList')
  store.commit('topBarModule/setMainTitle', {title: "Projet", icon: "devices"})
})

onUnmounted(() => {
  store.commit('projectModule/resetAttachments')
  store.commit('projectModule/resetCurrent')
  store.commit('noteModule/resetNotes')
  store.commit('linkModule/resetLinks')
})
</script>

<template>
  <MultiColumnTemplate>
    <template #note>
      <IconTitleComponent title="Notes" iconName="speaker-note" titleSize="h3" />
      <NoteListComponent />
    </template>

    <template #main>
      <div class="project-single">
        <div class="project-state" :class="getProjectState(true)">projet {{ getProjectState() }}</div>
        <BackButton />
        <router-link v-if="getProject.id"
                     :to="{'name': 'clientSingle', 'params' : {'id': getProject.client.id}}"
                     class="back-to-client"
                     :title="`retour vers le client ${getProject.client.name}`"
        >
          {{ getProject.client.name }}
        </router-link>
        <ProjectSingleComponent v-if="getProject.id" :project="getProject" />
      </div>
    </template>

    <template #right>
      <ProjectSingleSettingsComponent v-if="getProject.id" :project="getProject" />
    </template>
  </MultiColumnTemplate>

  <teleport to="#endOfBody">
    <WindowComponent :state="getAddTimes.state" windowTitle="gestion du temps" @close="closePopup">
      <AddTimeComponent @closePopup="closePopup" />
    </WindowComponent>
  </teleport>
</template>
