import Axios from "axios"
import Config from "../Utils/Config"
import store from "../store"

export default {
    getHeaders(contentType = "application/json") {
        return {'Authorization': `Bearer ${store.getters['securityModule/getToken']}`, 'content-type': contentType}
    },
    async referenceExist(ref) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/clients?reference=${ref}`, {headers: this.getHeaders()})
                .then(response => {
                    const hasClient = parseInt(response.data["hydra:totalItems"])
                    resolve(hasClient)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
                })
        })
    },
    async search(data) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/clients?isArchived=0&search_name=${encodeURI(data.query)}`, {headers: this.getHeaders()})
                .then(response => {
                    const clients = response.data['hydra:member']
                    resolve(clients)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
                })
        })
    },
    async getList() {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/clients?isArchived=0&pagination=false`, {headers: this.getHeaders()})
                .then(response => {
                    const clients = response.data
                    resolve(clients)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
                })
        })
    },
    async getArchivedList() {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/clients?isArchived=1`, {headers: this.getHeaders()})
                .then(response => {
                    const clients = response.data['hydra:member']
                    resolve(clients)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
    async get(id) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/clients/${id}`, {headers: this.getHeaders()})
                .then(response => {
                    const client = response.data
                    resolve(client)
                }).catch(error => {
                    reject(error.response.data.message)
            })
        })
    },
    async getAll(payload) {
        let query = "pagination=false"
        if (payload) {
            if (payload.state) query += `&search_state=${payload.state}`
        }
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/clients?${query}`, {headers: this.getHeaders()})
                .then(response => {
                    const client = response.data['hydra:member']
                    resolve(client)
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },
    async update(id, data) {
        return new Promise((resolve, reject) => {
            Axios.patch(`${Config.getApiUrl()}/clients/${id}`, data, {headers: this.getHeaders('application/merge-patch+json')})
                .then(response => {
                    const client = response.data
                    resolve(client)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
                })
        })
    },
    async remove(id) {
        return new Promise((resolve, reject) => {
            Axios.delete(`${Config.getApiUrl()}/clients/${id}`,{headers: this.getHeaders()})
                .then(response => {
                    store.commit('addMessage', {state: 'warning', content: `Le client id:${id} à bien été supprimé`})
                    resolve(response)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
                })
        })
    },
    async create(data) {
        return new Promise((resolve, reject) => {
            Axios({method: 'post', url: `${Config.getApiUrl()}/clients`, data, headers: this.getHeaders()})
                .then(response => {
                    const client = response.data
                    if (client) {
                        store.commit('addMessage', {
                            state: 'success',
                            content: `[${client.reference}] - ${client.name} à bien été créé`
                        })
                    }
                    resolve(client)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                    reject(error)
                })
        })
    }
}