import Axios from "axios"
import Config from "../Utils/Config"
import store from "../store"

export default {
    getHeaders(contentType = "application/json") {
        return {'Authorization': `Bearer ${store.getters['securityModule/getToken']}`, 'content-type': contentType}
    },
    async getCurrentUser() {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/users/${localStorage.getItem('username')}`, {headers: this.getHeaders()})
                .then(response => {
                    resolve(response.data)
                }).catch(error => reject(error))
        })
    },
    async getUserByUsername(username) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/users/${username}`, {headers: this.getHeaders()})
                .then(response => {
                    resolve(response.data)
                }).catch(error => reject(error))
        })
    },
    async getUserByBirthdays(date) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/users?birthday=${date}`, {headers: this.getHeaders()})
                .then(response => {
                    resolve(response.data)
                }).catch(error => reject(error))
        })
    },
    async create(user) {
        return new Promise((resolve, reject) => {
            Axios.post(Config.getApiUrl() + '/register', user, {headers: {'Content-Type': 'application/json'}})
                .then(response => {
                    if (response.data.error) {
                        store.commit('addMessage', {state: "error", content: response.data.error})
                        reject(response)
                    } else {
                        store.commit('addMessage', {state: 'success', content: `L'utilisateur "${user.username}" à bien été ajouté`})
                        resolve(response.data)
                    }
                }).catch(error => {
                    store.commit('addMessage', {state: "error", content: error})
                    reject(error)
                })
        })
    },
    async getList() {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/users`, {headers: this.getHeaders()})
                .then(response => {
                    const users = response.data['hydra:member']
                    resolve(users)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
                })
        })
    },
    async remove(username) {
        return new Promise((resolve, reject) => {
            Axios.delete(`${Config.getApiUrl()}/users/${username}`, {headers: this.getHeaders()})
                .then(response => {
                    store.commit('addMessage', {state: 'warning', content: `L'utilisateur "${username}" à bien été supprimé`})
                    resolve(response)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
            })
        })
    },
    async update(username,data) {
        return new Promise((resolve, reject) => {
            Axios.patch(`${Config.getApiUrl()}/users/update/${username}`, data, {headers: this.getHeaders('application/merge-patch+json')})
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }
}