import Utils from "../../Utils/Utils"

const state = () => ({
    messages: []
})

const getters = {
    getMessages: state => state.messages ? state.messages : [],
    hasMessages: state => state.messages.length > 0
}

const mutations = {
    addMessage(state, payload) {
        state.messages.push({
            uuid: Utils.generateUUID(),
            state: payload.state,
            content: payload.content
        })
    },
    removeMessage(state, payload) {
        state.messages = state.messages.filter(message => message.uuid !== payload)
    }
}

export default {
    namespace: true,
    state,
    getters,
    mutations
}