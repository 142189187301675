<script setup>
import {ref, onMounted, computed, onUnmounted} from "vue"
import store from "../../store"
import {useRoute} from "vue-router"
import LeaveItemComponent from "../../components/leave/LeaveItemComponent"
import PaginationComponent from "../../components/items/PaginationComponent"
import MultiColumnTemplate from "../templates/MultiColumnTemplate"
import Config from "../../Utils/Config"

const route = useRoute()

const leaveTypes = ref(Config.getLeaveTypeLabels())
const leaveType = ref('-')
const leaveState = ref('-')

const getLeaves = computed(() => {
  return store.getters['leaveModule/getItems']
})
const getPagination = computed(() => {
  return store.getters['leaveModule/getPagination']
})

function updateList() {
  const state = leaveState.value && leaveState.value !== '-' ? leaveState.value : ''
  const type = leaveType.value && leaveType.value !== '-' ? leaveType.value : ''
  store.dispatch('leaveModule/getListByStateOrType', {state, type})
}

onMounted(() => {
  const page = route.query.page
  store.dispatch('leaveModule/getList', page ? 'page='+page : '')
  store.commit('topBarModule/setMainTitle', {title: "Gestion des demandes de congés", icon: "calendar-range"})
  store.dispatch('publicHolidaysModule/getList')
})

onUnmounted(() => {
  store.commit('leaveModule/resetList')
})
</script>

<template>
  <MultiColumnTemplate>
    <template #main>
      <div class="leave-filters">
        <div class="form-group">
          <label for="leaveType">Type de demande</label>
          <select name="leaveType" id="leaveType" v-model="leaveType" @change="updateList">
            <option>-</option>
            <option v-for="type in leaveTypes" :value="type" :key="type">{{ type }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="leaveState">Statut</label>
          <select name="leaveState" id="leaveState" v-model="leaveState" @change="updateList">
            <option>-</option>
            <option value="0">En attente</option>
            <option value="1">Validé</option>
            <option value="2">Refusé</option>
          </select>
        </div>
      </div>
      <LeaveItemComponent v-for="leave in getLeaves" :leave="leave" :key="leave.id" />
      <p v-if="!getLeaves.length">Aucune demande n'est disponibe</p>

      <PaginationComponent v-if="getPagination" :pagination="getPagination" />
    </template>
  </MultiColumnTemplate>
</template>
