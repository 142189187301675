<script setup>
import {useRouter} from "vue-router"
const router = useRouter()

function goBack() {
  router.back()
}
</script>

<template>
  <div class="back-button" @click="goBack">
    <svg class="sprite-icon" aria-hidden="true">
      <use xlink:href="@/assets/icons/sprite.svg#icon-arrow-left"></use>
    </svg>
    <span>retour</span>
  </div>
</template>