<script setup>
import {onMounted, computed, ref, watch} from "vue"
import store from "../../store"
import TodoComponent from "../../components/todo/TodoComponent"
import LoaderComponent from "../../components/items/LoaderComponent"
import SvgIconComponent from "../../components/items/SvgIconComponent"
import MultiColumnTemplate from "../templates/MultiColumnTemplate"

const searchResult = ref([])
const query = ref('')
const oldValue = ref('')
const loading = ref(false)
const time = ref(0)
const typing = ref(false)

const getUserList = computed(() => {
  return store.getters['userModule/getItems']
})
const getTodoList = computed(() => {
  return store.getters['todoModule/getItems']
})
const getAgencyTodo = computed(() => {
  return store.getters['settingsModule/getAgencyTodo']
})

function toggleTyping() {
  time.value = Date.now()
  typing.value = true
}
async function search() {
  loading.value = true
  if (Date.now() - time.value < 600) await new Promise(resolve => setTimeout(resolve, 600))

  if (query.value && query.value.length > 2 && query.value !== oldValue.value) {
    const q = query.value.replaceAll(' ', '[a-z]?\\s[a-z]?')
    const regex = new RegExp(q, 'gmi')

    const result = []
    getTodoList.value.forEach(item => {
      if (item.todo) item.todo.forEach(todo => {
        if (todo.description.search(regex) !== -1) result.push({user: item.user.username, description: todo.description})
      })
    })

    searchResult.value = [...new Set(result)]
    loading.value = false
  } else {
    loading.value = false
    typing.value = true
  }
}

function update(todo, user) {
  store.dispatch('userModule/updateUser', {username: user.username, data: {todo}})
}
function updateAgencyTodo(todo) {
  store.dispatch('settingsModule/updateSetting', {settingsKey: "agencyTodo", settingsValue: JSON.stringify(todo)})
}

onMounted(() => {
  store.dispatch('userModule/getActiveMemberList')
  store.dispatch('todoModule/getItems')
  store.dispatch('settingsModule/getSettings')
  store.commit('topBarModule/setMainTitle', {title: "Todo lists", icon: "todo-manage"})
})

function watchQuery() {
  if (!query.value) {
    loading.value = true
    searchResult.value = []
    loading.value = false
    oldValue.value = ""
  }
}
watch(query, watchQuery)
</script>

<template>
  <MultiColumnTemplate>
    <template #main>
      <div class="todo-list-container">
        <div class="search-container">
          <p class="search-title">filtrer</p>
          <div class="search">
            <LoaderComponent :loading="loading"/>
            <SvgIconComponent v-if="!loading" name="search"/>
            <input type="search" placeholder="rechercher par mot(s) clé(s) - au moins 3 lettres" @keydown="toggleTyping" @keyup="search" v-model="query" autocomplete="off">
          </div>
          <div v-for="(todo, index) in searchResult" :key="index" class="search-result">
            <div class="mini-card">
              <div class="username">{{todo.user}} : </div>
              <div v-html="todo.description"></div>
            </div>
          </div>
        </div>

        <ul v-if="getUserList">
          <li class="todo-list-item">
            <div>
              <h2>Todo de l'agence</h2>
              <TodoComponent :todo="getAgencyTodo" :enable-actions="false" @onUpdate="updateAgencyTodo($event)" />
            </div>
          </li>
          <li v-for="user in getUserList" :key="user.id" class="todo-list-item">
            <div v-if="user.roles.includes('TODO_ENABLE')">
              <h2>Todo de {{ user.firstname }} {{ user.lastname }} ({{user.username}})</h2>
              <TodoComponent :todo="user.todo" :enable-actions="false" @onUpdate="update($event, user)" />
            </div>
          </li>
        </ul>
      </div>
    </template>
  </MultiColumnTemplate>
</template>