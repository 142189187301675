import Axios from "axios"
import Config from "../Utils/Config"
import store from "../store"

export default {
    getHeaders(contentType = "application/json") {
        return {'Authorization': `Bearer ${store.getters['securityModule/getToken']}`, 'content-type': contentType}
    },
    async getListByType(id, type) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/notes?${type}=${id}`, {headers: this.getHeaders()})
                .then(response => {
                    const notes = response?.data['hydra:member']
                    resolve(notes)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                    reject(error)
                })
        })
    },
    async create(data) {
        return new Promise((resolve, reject) => {
            Axios({method: 'post', url: `${Config.getApiUrl()}/notes`, data, headers: this.getHeaders()})
                .then(response => {
                    const note = response.data
                    resolve(note)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                    reject(error)
                })
        })
    },
    async update(id, data) {
        return new Promise((resolve, reject) => {
            Axios.patch(`${Config.getApiUrl()}/notes/${id}`, data, {headers: this.getHeaders('application/merge-patch+json')})
                .then(response => {
                    const note = response.data
                    resolve(note)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
                })
        })
    },
    async remove(id) {
        return new Promise((resolve, reject) => {
            Axios.delete(`${Config.getApiUrl()}/notes/${id}`, {headers: this.getHeaders()})
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
            })
        })
    }
}