<script setup>
import {ref, defineProps, defineEmits} from "vue"
import SvgIconComponent from "./SvgIconComponent"

const emit = defineEmits(['close', 'closePopup'])
defineProps({
  windowTitle: {
    type: String,
    default() {
      return "Popup"
    }
  },
  windowIcon: {
    type: String,
    default() {
      return "add"
    }
  },
  state: Boolean
})

const dragActive = ref(false)
const initialX = ref("")
const initialY = ref("")
const currentX = ref("")
const currentY = ref("")
const xOffset = ref("")
const yOffset = ref("")
const isReduce = ref(false)
const window = ref(null)

function dragStart(ev) {
  if (document.activeElement instanceof HTMLInputElement) {
    ev.preventDefault()
  } else {
    if (ev.type === "touchstart") {
      initialX.value = ev.touches[0].clientX - xOffset.value
      initialY.value = ev.touches[0].clientY - yOffset.value
    } else {
      initialX.value = ev.clientX - xOffset.value
      initialY.value = ev.clientY - yOffset.value
    }

    if (ev.target === window.value) dragActive.value = true
  }
}
function drag(ev) {
  if (dragActive.value) {
    if (ev.type === "touchmove") {
      currentX.value = ev.touches[0].clientX - initialX.value
      currentY.value = ev.touches[0].clientY - initialY.value
    } else {
      currentX.value = ev.clientX - initialX.value
      currentY.value = ev.clientY - initialY.value
    }

    xOffset.value = currentX.value
    yOffset.value = currentY.value

    setTranslate(currentX.value, currentY.value, window.value)
  }
}
function dragStop() {
  initialX.value = currentX.value
  initialY.value = currentY.value

  dragActive.value = false
}
function setTranslate(xPos, yPos, el) {
  requestAnimationFrame(() => {
    el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)"
  })
}
function close() {
  emit('close')
  emit('closePopup')
}
function toggleReduce() {
  isReduce.value = !isReduce.value
}
</script>

<template>
  <div
      ref="window"
      class="window" :class="`${state ? 'active' : ''} ${isReduce ? 'reduce' : ''}`"
      @dragstart="dragStart" @drag="drag" @dragend="dragStop" @dragover.prevent draggable="true"
  >
    <div class="drag-header" @dblclick="toggleReduce">
      <div class="title">
        <SvgIconComponent :name="windowIcon" />
        <span v-if="windowTitle" class="window-title">{{ windowTitle }}</span>
      </div>
      <div class="buttons">
        <div class="reduce-button" @click="toggleReduce">
          <SvgIconComponent :name="isReduce ? 'add' : 'minus'" />
        </div>
        <div class="close-button" @click="close" title="fermer la fenêtre"><SvgIconComponent name="add" /></div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>