<script setup>
import {ref, onMounted, computed} from "vue"
import store from "../../store"
import MultiColumnTemplate from "../templates/MultiColumnTemplate"
import RegisterComponent from "../../components/user/RegisterComponent"
import SvgIconComponent from "../../components/items/SvgIconComponent"
import PasswordGeneratorComponent from "../../components/items/PasswordGeneratorComponent"
import Config from "../../Utils/Config"

// DATA
const editingUser = ref({})
const showUserTimes = ref(false)
const keyword = ref('')

const users = computed(() => {
  return store.getters['userModule/getItems']
})
const userAvatar = computed(() => (username) => {
  return store.getters['userModule/getUserAvatar'](username)
})

// METHODS
function getTimes(times) {
  let count = 0
  if (times) times.map(item => count += parseFloat(item.times))

  return Math.floor(count / 8)
}
function getJob(jobKey) {
  return Config.getJobs()[jobKey]?.label
}
function cancel(userId) {
  if (editingUser.value.id === userId) editingUser.value = {}
}
function removeUser(username) {
  if (confirm(`Êtes-vous sûr de vouloir supprimer ${username}`)) {
    store.dispatch('userModule/removeUser', username)
  }
}

// LIFECYCLE
onMounted(() => {
  document.addEventListener('keyup', (ev) => {
    if (ev.key && ev.key.match(/[a-z]/)) keyword.value += ev.key
    if (keyword.value === "kamoulox") showUserTimes.value = true
    else {
      if (keyword.value.length > 8) keyword.value = ''
    }
  })
  store.dispatch('userModule/getList')
  store.commit('topBarModule/setMainTitle', {title: "Liste des utilisateurs", icon: "account-circle"})
})
</script>

<template>
  <MultiColumnTemplate>
    <template #main>
      <ul v-if="users" class="user-list">
        <li v-for="user in users" :key="user.id">
          <div>
            <div class="header">
              <div class="user-avatar">
                <img :src="userAvatar(user.username)" alt="Avatar de l'utilisateur">
              </div>
              <div>
                <div class="username">{{ user.username }}</div>
                <div>{{user.firstname}} {{user.lastname}}</div>
                <div class="email">({{user.email}})</div>
              </div>
            </div>
            <div class="job">{{getJob(user.job)}}</div>
            <div class="roles">{{ user.roles.join(', ') }}</div>
          </div>

          <div v-if="showUserTimes && getTimes(user.times)">
            {{`${getTimes(user.times)} jours travaillés`}}
          </div>

          <div class="action-buttons">
            <div class="icon-button edit-button" :title="`Éditer ${user.username}`" @click="editingUser = user">
              <SvgIconComponent name="edit" />
            </div>
            <div class="icon-button delete-button" :title="`Supprimer ${user.username}`" @click="removeUser(user.username)">
              <SvgIconComponent name="delete-forever" />
            </div>
          </div>
        </li>
      </ul>
    </template>

    <template #right>
      <div class="bloc">
        <RegisterComponent :user="editingUser"  @cancel="cancel" />
      </div>
      <div class="bloc">
        <PasswordGeneratorComponent />
      </div>
    </template>
  </MultiColumnTemplate>
</template>