import Axios from "axios"
import store from "../store"
import Config from "../Utils/Config"

export default {
    getHeaders(contentType = "application/json") {
        return {'Authorization': `Bearer ${store.getters['securityModule/getToken']}`, 'content-type': contentType}
    },
    async create(data) {
        return new Promise((resolve, reject) => {
            Axios({method: 'post', url: `${Config.getApiUrl()}/leave_updates`, data, headers: this.getHeaders()})
                .then(response => {
                    const leaveUpdate = response.data
                    resolve(leaveUpdate)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                    reject(error)
                })
        })
    },
    async getAll(page = 'page=1') {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/leave_updates?${page}`, {headers: this.getHeaders()})
                .then(response => {
                    const leaves = response?.data
                    resolve(leaves)
                })
                .catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                    reject(error)
                })
        })
    },
    async getByLeave(leaveId) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/leave_updates?leave=${leaveId}`, {headers: this.getHeaders()})
                .then(response => {
                    const leaves = response?.data
                    resolve(leaves)
                })
                .catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                    reject(error)
                })
        })
    },
    async update(id, data) {
        return new Promise((resolve, reject) => {
            Axios.patch(`${Config.getApiUrl()}/leave_updates/${id}`, data, {headers: this.getHeaders('application/merge-patch+json')})
                .then(response => {
                    const leave = response.data
                    resolve(leave)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
}