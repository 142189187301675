<script setup>
import {ref} from "vue"
import store from "../../store"
import SvgIconComponent from "../../components/items/SvgIconComponent"
import {useRouter} from "vue-router"

const router = useRouter()

const username = ref("")
const password = ref("")
const errors = ref({})

function resetErrors() {
  errors.value = {}
}

function login() {
  if(username.value && password.value) {
    store.dispatch('securityModule/login', {username: username.value, password: password.value})
        .then(() => router.push('/'))
  } else {
    errors.value = {
      state: "error",
      message: "Veuillez remplir les champs"
    }
  }
}
</script>

<template>
  <div id="login">
    <transition name="slide-fade">
      <div v-if="errors?.state" :class="{ 'alert-error': errors.state = 'error' }">
        {{ errors.message }}
      </div>
    </transition>
    <form class="login-form card" @keyup.enter="login">
      <h2>Connexion</h2>
      <div>
        <input type="text" placeholder="username" v-model="username" @focus="resetErrors">
      </div>
      <div>
        <input type="password" placeholder="password" v-model="password" @focus="resetErrors">
      </div>
      <button @click.prevent="login" class="save-button">
        <SvgIconComponent name="login" /> Se connecter
      </button>
    </form>
  </div>
</template>