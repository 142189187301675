<script setup>
import {ref, computed, onMounted, onUnmounted} from "vue"
import store from "./../../store"
import {useRouter} from "vue-router"
import SvgIconComponent from "../items/SvgIconComponent"

const router = useRouter()

// DATA
let isOpened = ref(false)
const topBar = ref(null)

// COMPUTED
const getTitle = computed(() => {
  return store.getters['topBarModule/getTitle']
})
const getIcon = computed(() => {
  return store.getters['topBarModule/getIconName']
})
const username = computed(() => {
  return store.getters['userModule/getCurrentUser'].username
})
const userAvatar = computed(() => {
  return store.getters['userModule/getUserAvatar'](username.value)
})
const isLoggedIn = computed(() => {
  return !!store.getters['securityModule/getToken']
})
const isAdmin = computed(() => {
  return store.getters['userModule/isAdmin']
})
const isManager = computed(() => {
  return store.getters['userModule/isManager']
})

// METHODS
function closeMenu(ev) {
  if (topBar.value && !topBar.value.contains(ev.target)) isOpened.value = false
}

function logout() {
  store.dispatch('securityModule/logout')
      .then(() => {
        store.commit('addMessage', {state: 'warning', content: 'À bientôt'})
        router.push('/login')
      })
}

// LIFECYCLE
onMounted(() => {
  document.addEventListener('click', closeMenu.bind(this))
})
onUnmounted(() => {
  isOpened = false
  document.removeEventListener('click', closeMenu.bind(this))
})
</script>

<template>
  <div v-if="isLoggedIn" class="top-bar" ref="topBar">
      <div class="main-title">
        <SvgIconComponent :name="getIcon" />
        <span>{{ getTitle }}</span>
      </div>
    <div class="more-button" @click="isOpened = !isOpened">
      <SvgIconComponent name="more" />
    </div>
    <transition v-show="isOpened" name="to-right" v-if="isLoggedIn" class="profile-menu" tag="div">
      <div>
        <div class="username">
          <div class="user-avatar"><img :src="userAvatar" alt="Avatar de l'utilisateur"></div>
          {{ username }}
        </div>
        <hr>
        <ul>
          <li v-if="isAdmin">
            <router-link :to="{ name: 'projectStats' }" title="Voir les stats des projets" class="stats nav-items">
              <SvgIconComponent name="stats" />
              <span>Statistiques</span>
            </router-link>
          </li>
          <li v-if="isAdmin">
            <router-link :to="{ name: 'timesDashboard' }">
              <SvgIconComponent name="manage-times" />
              <span>Rentabilité</span>
            </router-link>
          </li>
          <li v-if="isAdmin">
            <router-link :to="{ name: 'timesByUsers' }">
              <SvgIconComponent name="recap-times" />
              <span>Récap temps</span>
            </router-link>
          </li>
          <li v-if="isManager">
            <router-link :to="{ name: 'todoList' }" title="Gérer les todo-listes">
              <SvgIconComponent name="todo-manage" />
              <span>Gérer les todos</span>
            </router-link>
          </li>
          <li v-if="isAdmin">
            <router-link :to="{ name: 'leave_manage' }" title="Gérer les demandes de congés">
              <SvgIconComponent name="calendar-range" />
              <span>Gérer les demandes</span>
            </router-link>
          </li>
          <li v-if="isAdmin">
            <router-link :to="{ name: 'contacts' }" title="Gérer les contacts">
              <SvgIconComponent name="contacts" />
              <span>Gérer les contacts</span>
            </router-link>
          </li>
          <li>
            <router-link v-if="isAdmin" :to="{ 'name': 'users' }" title="Liste des comptes">
              <SvgIconComponent name="person-add" />
              <span>Les utilisateurs</span>
            </router-link>
          </li>
          <li v-if="isAdmin">
            <router-link :to="{ name: 'logs' }">
              <SvgIconComponent name="log" />
              <span>Voir les logs</span>
            </router-link>
          </li>
          <li v-if="isAdmin">
            <router-link :to="{ name: 'settings' }" active-class="active" class="dock-item">
              <SvgIconComponent name="settings" />
              <span>Paramètres</span>
            </router-link>
          </li>
          <li class="logout-btn">
            <a @click="logout">
              <SvgIconComponent name="logout" />
              <span>se déconnecter</span>
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>