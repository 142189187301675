import ConfigFile from "../../adct-client-config.json"

export default class Config {
    static isProd() {
        return process.env.NODE_ENV === "production"
    }

    static get() {
        return ConfigFile
    }

    static getApiUrl() {
        return Config.isProd() ? Config.get().api_url_prod : Config.get().api_url_dev
    }

    static getMediaUrl() {
        return Config.isProd() ? Config.get().media_url_prod : Config.get().media_url_dev
    }

    static getHolidaysApiUrl() {
        return Config.get().api_public_holidays_url
    }

    static getWebsocketUrl() {
        return Config.isProd() ? Config.get().web_socket_prod : Config.get().web_socket_dev
    }

    static getTinyMceApiKey() {
        return Config.get().tinymce_api_key
    }

    static getTrelloApiKey() {
        return Config.get().trello_api_key
    }

    static getWeatherMapApiKey() {
        return Config.get().open_weather_map_id
    }

    static getTrelloApiToken() {
        return Config.get().trello_api_token
    }

    static getAcceptedFile() {
        return Config.get().accepted_files_type
    }

    static getJobs() {
        return Config.get().jobs
    }

    static getJobByKey(key) {
        return Config.get().jobs[key].label
    }

    static getProjectStates() {
        return Config.get().project_state
    }

    static getProjectStateByKey(key) {
        return Config.get().project_state[key]
    }

    static getLeaveTypes() {
        return Config.get().leave_type
    }

    static getLeaveTypeLabels() {
        const labels = []
        const types = this.getLeaveTypes()
        types.forEach(type => labels.push(type.label))
        return labels
    }

    static getLeaveSmileyByLabel(typeLabel) {
        let smiley = ""
        const types = this.getLeaveTypes()
        types.forEach(type => {
            if (type.label === typeLabel) smiley = type.smiley
        })
        return smiley
    }
}