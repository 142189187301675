export default class StatsUtils {
    static roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2")
    }

    static getTotalTimes(times) {
        let total = 0
        times.map(time => total += parseFloat(time.times))

        return total
    }

    static getTotalCost(project, times) {
        return this.roundToTwo(((project.dailyCost ?? 0) * (this.getTotalTimes(times ?? project.times) / 8)) + (project.purchases ?? 0))
    }

    static getGain(project, times) {
        return this.roundToTwo(project.sellingPrice - this.getTotalCost(project, times))
    }

    static getMargin(project, times) {
        return Math.round(100 - ((this.getTotalCost(project, times) * 100) / (project.sellingPrice ?? 0)))
    }
}