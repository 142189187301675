import store from "../index"

const state = () => ({
    items: []
})

const getters = {
    getItems: state => state.items
}

const actions = {
    getItems({commit}) {
        const users = store.getters['userModule/getItems']
        users.forEach(user => {
            commit('addItems', {user: {username: user.username, roles: user.roles}, todo: user.todo})
        })
    }
}

const mutations = {
    setItems(state, items) {
        state.items = items
    },
    addItems(state, todo) {
        state.items.push(todo)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}