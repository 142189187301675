import securityApi from "../../api/security"
import store from "../index"

const state = () => ({
    token: localStorage.getItem('token')
})

const getters = {
    isLoggedIn: state => !!state.token,
    getToken: state => state.token
}

const actions = {
    async login({commit}, user) {
        const data = await securityApi.login(user)

        localStorage.setItem('token', data.token)
        localStorage.setItem('username', data.username)

        this.token = data.token

        commit('auth_success', data.token)
        await store.dispatch('userModule/getUser')

        return data.username
    },
    async logout({commit}) {
        await securityApi.logout()
        commit('logout')

        store.commit('userModule/logoutUser')
    }
}

const mutations = {
    auth_success(state, token) {
        state.token = token
    },
    logout(state) {
        state.token = ''
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}