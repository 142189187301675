<script setup>
import {ref, computed, defineProps} from "vue"
import store from "./../../store"
import SvgIconComponent from "../items/SvgIconComponent"
import Utils from "../../Utils/Utils"

const props = defineProps({
  client: Object
})
const editing = ref(false)
const name = ref("")
const projectTypeInput = ref("")
const input = ref(null)

const user = computed(() => {
  return store.getters['userModule/getCurrentUser']
})
const projectCost = computed(() => {
  return store.getters['settingsModule/getProjectCost']
})
const projectTypes = computed(() => {
  return store.getters['settingsModule/getProjectTypesList']
})

function isYearly(slug) {
  let isYearly = false
  projectTypes.value.forEach(item => {
    if (item.slug === slug) isYearly = item.yearly
  })

  return isYearly
}
function toggleEditingState(state) {
  editing.value = state
  if (state) setTimeout(() => input.value.focus(), 0)
}
function createProject(ev) {
  if (((ev instanceof KeyboardEvent && ev.isTrusted && ev.key === "Enter") || ev instanceof PointerEvent) && name.value) {
    let ref = Utils.refify(name.value)
    store.dispatch('projectModule/checkReference', ref)
        .then(response => {
          if (response) {
            store.commit('addMessage', {state: "warning", content: `ATTENTION : une référence similaire à ${ref} existe déjà`})
            const date = new Date()
            ref += `_${date.getFullYear()}_${date.getMonth()}_${date.getDay()}`
          }

          store.dispatch('projectModule/createProject', {
            name: name.value,
            reference: ref,
            client: props.client['@id'],
            user: user.value['@id'],
            type: projectTypeInput.value.value,
            yearly: isYearly(projectTypeInput.value.value),
            state: 0,
            isFinished: false,
            dailyCost: parseInt(projectCost.value) ?? 320
          }).then(response => {
            if (response) {
              name.value = ""
              toggleEditingState(false)
              ev.target.blur()
              ev.target.value = ""
            }
          })
        })
  }
  toggleEditingState(false)
}
</script>

<template>
  <div @click="toggleEditingState(true)" class="btn-create create-client">
    <div class="button-icon-rounded">
      <SvgIconComponent name="add-project" />
    </div>
    <div class="btn-container">
      <span v-show="!editing">Créer un nouveau projet</span>

      <select v-show="editing && projectTypes.length" name="project_type" ref="projectTypeInput">
        <option v-for="type in projectTypes" :value="type.slug" :key="type.slug">{{type.name}}</option>
      </select>

      <input
          ref="input"
          v-show="editing"
          v-focus="editing"
          @keyup.enter="createProject"
          type="text"
          name="client_name"
          placeholder="Nom du projet &ldca;"
          v-model="name">
    </div>
  </div>
</template>