import HolidayApi from "../../api/public-holidays"

const state = () => ({
    items: {}
})

const getters = {
    getItems: state => state.items
}
const actions = {
    async checkData(context, year) {
        return await HolidayApi.check(year)
    },
    async getList({dispatch, commit}) {
        const currentYear = new Date().getFullYear()

        if (!await dispatch('checkData', currentYear)) await dispatch('updateDb', currentYear)
        if (!await dispatch('checkData', currentYear + 1)) await dispatch('updateDb', currentYear + 1)

        const holidays = await HolidayApi.getAll()
        const items = {}
        holidays.forEach(item => items[item.date] = item.name)
        commit('setItems', items)
    },
    async updateDb(context, year) {
        return await HolidayApi.create(year)
    }
}
const mutations = {
    setItems(state, items) {
        state.items = items
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}