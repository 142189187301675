import {DateTime} from "luxon";
import Config from "../Utils/Config";

const params = {
    "latitude": 47.425970,
    "longitude": 0.717390,
    "units": "metric",
    "appid": Config.getWeatherMapApiKey()
}
const url = "https://api.openweathermap.org/data/2.5/forecast"
const response = await fetch(`${url}?lat=${params.latitude}&lon=${params.longitude}&units=${params.units}&appid=${params.appid}`)
    .then(response => response.json())


export function useWeather() {
    const arrData = {}
    response.list?.forEach(weatherData => {
        const today = DateTime.now()
        const date = DateTime.fromFormat(weatherData.dt_txt, "yyyy-MM-dd HH:mm:ss")
        const item = {
            "time": weatherData.dt_txt,
            "temperature": weatherData.main.temp,
            "humidity": weatherData.main.humidity,
            "apparent": weatherData.main.feels_like,
            "weatherCode": weatherData.weather[0].main
        }

        if (date.hour - today.hour >= 0 && date.hour - today.hour < 3) {
            if (!Array.isArray(arrData[date.toISODate()])) {
                arrData[date.toISODate()] = []
                arrData[date.toISODate()].push(item)
            } else arrData[date.toISODate()].push(item)
        }
    })

    return arrData
}