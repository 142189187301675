<script setup>
import {useWeather} from "../../composables/weather";
import WeatherItemComponent from "./WeatherItemComponent.vue";
import {onMounted} from "vue";
import Swiper from "swiper";
import {Navigation} from "swiper/modules";
import SvgIconComponent from "../items/SvgIconComponent.vue";

const weather = useWeather()

onMounted(() => {
  new Swiper('.swiper', {
    modules: [Navigation],
    direction: "vertical",
    grabCursor: true,
    navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next'
    }
  })
})
</script>

<template>
  <div class="swiper">
    <div class="swiper-button-prev">
      <SvgIconComponent name="arrow-expand" />
    </div>
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="item in weather">
        <WeatherItemComponent :weather-data="item" />
      </div>
    </div>
    <div class="swiper-button-next">
      <SvgIconComponent name="arrow-expand" />
    </div>
  </div>
</template>