<script setup>
import {onMounted, computed} from "vue"
import store from "../../store"
import PaginationComponent from "../../components/items/PaginationComponent"
import {useRoute} from "vue-router"
import MultiColumnTemplate from "../templates/MultiColumnTemplate"

const route = useRoute()

const items = computed(() => {
  return store.getters['logModule/getItems']
})
const getPagination = computed(() => {
  return store.getters['logModule/getPagination']
})

onMounted(() => {
  const page = route.query.page
  store.dispatch('logModule/getLogList', page ? 'page='+page : '')
  store.commit('topBarModule/setMainTitle', {title: "logs", icon: "log"})
})
</script>

<template>
  <MultiColumnTemplate>
    <template #main>
      <table v-if="items">
        <thead>
        <tr>
          <td>Date</td>
          <td>Utilisateur</td>
          <td>Entité</td>
          <td>Action</td>
          <td>Description</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id">
          <td class="date">
            <strong>{{ new Date(item.createdAt).toLocaleDateString() }}</strong>
            <br>
            <strong>{{ new Date(item.createdAt).toLocaleTimeString() }}</strong>
          </td>
          <td>{{ item.user }}</td>
          <td>{{ item.entity }}</td>
          <td>{{ item.action }}</td>
          <td>{{ item.description }}</td>
        </tr>
        </tbody>
      </table>

      <PaginationComponent v-if="getPagination" :pagination="getPagination" />
    </template>
  </MultiColumnTemplate>
</template>