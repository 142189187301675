import MotivationsFile from "../../motivations.json"

export default class Motivations {
    static get() {
        return MotivationsFile
    }

    static getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    static getRandom() {
        return this.get()[this.getRandomInt(0, this.get().length)]
    }
}