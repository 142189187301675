import timesApi from "../../api/times"
import store from "../index"
import projectApi from "../../api/project"

const getDescription = async (time) => {
    const username = store.getters['userModule/getCurrentUser'].username
    const project = await projectApi.get(time.project.id)
    return `${username} à enregistré ${time.times}h de temps de travail sur le projet ${project.client.name} - ${project.name}.`
}

const state = () => ({
    items: [],
    pagination: {},
    addTimes: {
        state: false,
        clientId: '',
        clientName: '',
        projectId: '',
        projectName: ''
    }
})

const getters = {
    getItems: state => state.items,
    getAddTimes: state => state.addTimes,
    getPagination: state => state.pagination,
    getTotalTime: state => {
        let total = 0
        state.items.forEach(item => {
            total += parseFloat(item.times)
        })

        const days = (total / 8).toString().split('.')[0]
        const remaining = (total%8).toFixed(2)

        return `${days} jr${days > 1 ? 's' : ''}${remaining > 0 ? ' et ' + remaining + ' heure' + (remaining > 1 ? 's' : '') : ''}`
    }
}

const actions = {
    async searchTimes({commit}, payload) {
        const times = await timesApi.search(payload)
        commit('setTimeList', times)
        commit('setPagination', times['hydra:view'] ?? null)
    },
    async searchByUserAndProject({commit}, payload) {
        const times = await timesApi.searchByUserAndProject(payload)
        commit('setTimeList', times['hydra:member'])
        commit('setPagination', times['hydra:view'] ?? null)
    },
    async getTimeByProject(context, projectId) {
        return await timesApi.getByProject(projectId)
    },
    async getTimeList({commit}, page) {
        const data = await timesApi.getList(page)
        commit('setTimeList', data['hydra:member'] ?? null)
        commit('setPagination', data['hydra:view'] ?? null)
    },
    async getTimeListByUser({commit}, payload) {
        const data = await timesApi.getByUser(payload)
        commit('setTimeList', data['hydra:member'] ?? null)
        commit('setPagination', data['hydra:view'] ?? null)
        return data
    },
    async addTimes(context, payload) {
        let time
        let action = 'CREATE'

        const existingTimes = await timesApi.getByProjectAndDate(payload.userId, payload.data)

        if (existingTimes['hydra:member'].length) {
            const item = existingTimes['hydra:member'][0]
            action = 'UPDATE'
            payload.data.times = (parseFloat(item.times) + parseFloat(payload.data.times)).toString()
            payload.data.updatedAt = "now"
            time = await timesApi.update(item.id, payload.data)
        }
        else  time = await timesApi.create(payload.data)

        await store.dispatch('logModule/createLog', {
            user: store.getters['userModule/getCurrentUser'].username,
            action: action,
            entity: "times",
            entityId: time.id,
            entityIri: time['@id'],
            description: await getDescription(time)
        })

        return time
    },
    async updateTimes({commit}, payload) {
        const time = await timesApi.update(payload.id, payload.data)
        commit('updateTime', time)

        await store.dispatch('logModule/createLog', {
            user: store.getters['userModule/getCurrentUser'].username,
            action: "UPDATE",
            entity: "times",
            entityId: time.id,
            entityIri: time['@id'],
            description: await getDescription(time)
        })

        return time

    },
    async getTimeById(context, id) {
        return await timesApi.get(id)
    },
    async removeTime({commit}, id) {
        await timesApi.remove(id)
        commit('removeTime', id)
    }
}

const mutations = {
    setTimeList(state, times) {
        state.items = times
    },
    setPagination(state, pagination) {
        state.pagination = pagination
    },
    addNewTime(state, time) {
        if (time) state.items.unshift(time)
    },
    updateTime(state, time) {
        state.items.map((item, index) => {
            if (item.id === time.id) state.items[index] = time
        })
    },
    removeTime(state, id) {
        state.items = state.items.filter(item => item.id !== id)
    },
    addCurrent(state, time) {
        state.current = time
    },
    resetTimes(state) {
        state.items = []
    },
    setAddTimes(state, addTimes) {
        state.addTimes = addTimes
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}