<script setup>
import {computed, onMounted, onUnmounted} from "vue"
import store from "./../../store"
import {useRoute} from "vue-router"
import MultiColumnTemplate from "../templates/MultiColumnTemplate"
import ProjectListComponent from "../../components/projects/ProjectListComponent"
import BackButton from "../../components/buttons/BackButton"
import NoteListComponent from "../../components/note/NoteListComponent"
import ClientSingleComponent from "../../components/client/ClientSingleComponent"
import ProjectCreateComponent from "../../components/projects/ProjectCreateComponent"
import IconTitleComponent from "../../components/items/IconTitleComponent"
import AddTimeComponent from "../../components/times/AddTimeComponent"
import WindowComponent from "../../components/items/WindowComponent"


const route = useRoute()

const getAddTimes = computed(() => {
  return store.getters['timesModule/getAddTimes']
})
const getClient = computed(() => {
  return store.getters['clientModule/getCurrent']
})

function closePopup() {
  store.commit('timesModule/setAddTimes', {state: false, projectId: '', projectName: ''})
}

onMounted(() => {
  store.dispatch('clientModule/getClientById', route.params.id)
  store.dispatch('userModule/getActiveMemberList')
  store.commit('topBarModule/setMainTitle', {title: "Client", icon: "people"})
  store.dispatch('settingsModule/getSettings')
})
onUnmounted(() => {
  store.commit('noteModule/resetNotes')
  store.commit('linkModule/resetLinks')
})
</script>

<template>
  <MultiColumnTemplate>
    <template #note>
        <IconTitleComponent title="Notes" iconName="speaker-note" titleSize="h3" />
        <NoteListComponent />
    </template>

    <template #main>
      <BackButton />
      <ClientSingleComponent />
    </template>

    <template #right>
      <IconTitleComponent title="Projets" iconName="devices" titleSize="h3" />
      <ProjectCreateComponent :client="getClient" />
      <ProjectListComponent v-if="getClient.projects" :projects="getClient.projects" :client="getClient" />
    </template>
  </MultiColumnTemplate>


  <teleport to="#endOfBody">
    <WindowComponent :state="getAddTimes.state" windowTitle="Ajouter du temps" window-icon="add-times" @close="closePopup">
      <AddTimeComponent @closePopup="closePopup"/>
    </WindowComponent>
  </teleport>
</template>