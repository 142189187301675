<template>
  <div class="dropzone">
    <div ref="zone" class="zone">
      <div class="placeholder">
        <div>Déposer ou cliquer ici</div>
        <div v-if="files.length">{{ `vous avez déposé ${files.length} fichier${files.length > 1 ? 's' : ''}` }}</div>
      </div>
      <input ref="inputFiles" type="file" @dragenter="inputDragEnter" @drop="inputDragLeave" @dragleave="inputDragLeave"
             @change="addFile" placeholder="drop here" :accept="acceptedFileType" multiple />
    </div>
    <div v-if="files" class="list">
      <div v-for="(file, index) in files" :key="index" class="list-item">
        <SvgIcon @click="removeItem(file.name)" name="delete-forever"/>
        <div>
          <div class="filename" @click.stop="editFilename($event, index)">
            <input v-if="editing === index" type="text" placeholder="nom du fichier" v-model="filesName[index]" @blur="editing = ''">
            <div v-else>
              <span>{{ filesName[index] ?? file.name }}</span>
              <SvgIcon name="edit" />
            </div>
          </div>
          <span class="file-size">({{ fileConvertSize(file.size) }})</span>
        </div>
      </div>
    </div>
    <button v-if="files.length" @click="sendFiles" class="save-button">{{ sendButtonLabel }}</button>
  </div>
</template>

<script lang="js">
import Config from "../../Utils/Config"
import SvgIcon from "../items/SvgIconComponent"

export default {
  name: "DropZoneComponent",
  components: {SvgIcon},
  data() {
    return {
      editing: "",
      projectId: "",
      files: [],
      filesName: [],
      senButtonLabel: "envoyer",
      acceptedFileType: Config.getAcceptedFile()
    }
  },
  created() {
    this.projectId = this.$route.params.id
  },
  methods: {
    inputDragEnter() {
      this.$refs.zone.classList.add("hover")
    },
    inputDragLeave() {
      this.$refs.zone.classList.remove("hover")
    },
    fileConvertSize(aSize) {
      aSize = Math.abs(parseInt(aSize, 10));
      let def = [[1, 'octets'], [1024, 'ko'], [1024 * 1024, 'Mo'], [1024 * 1024 * 1024, 'Go'], [1024 * 1024 * 1024 * 1024, 'To']];
      for (let i = 0; i < def.length; i++) {
        if (aSize < def[i][0]) return (aSize / def[i - 1][0]).toFixed(2) + ' ' + def[i - 1][1];
      }
    },
    editFilename(ev, index) {
      this.editing = index
      ev.target.parentElement.querySelector('input')?.focus()
    },
    addFile() {
      const files = Array.from(this.$refs.inputFiles.files)
      files.forEach(file => this.files.push(file))
      const plural = this.files.length > 1 ? 's' : ''
      this.sendButtonLabel = `Envoyer le${plural} fichier${plural}`
    },
    removeItem(name) {
      this.files = this.files.filter(file => file.name !== name)
      if (!this.files.length) this.$refs.inputFiles.value = ""
    },
    sendFiles() {
      if (this.files.length) {
        this.files.forEach((file, index) => {
          const form = new FormData()
          form.append('file', file)
          form.append('project', this.projectId)
          form.append('filename', this.filesName[index] ?? file.name)
          form.append('extension', file.name.split('.').pop())

          this.$store.dispatch('mediaModule/sendFile', form)
              .then(file => {
                this.$store.dispatch('mediaModule/getFile', file['@id'])
                  .then(file => {
                    this.$store.commit('projectModule/addAttachments', {
                      iri: file.data['@id'],
                      url: `${Config.getMediaUrl()}/${file.data.contentUrl}`,
                      name: file.data.filename,
                      extension: file.data.extension
                    })
                  })
              })
        })

        this.filesName = []
        this.files = []
        this.sendButtonLabel = "Envoyer"
        this.$refs.inputFiles.value = ""
      }
    }
  }
}
</script>