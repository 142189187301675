<script setup>
import {ref, computed, defineEmits, defineProps} from "vue"
import store from "../../store"
import SvgIconComponent from "../items/SvgIconComponent"

const emit = defineEmits(['editContact'])

const props = defineProps({
  contact: Object,
  showClient: {
    type: Boolean,
    default: false
  }
})

const submenuOpened = ref(false)

const isArchived = computed(() => {
  return store.getters['clientModule/getArchiveMode']
})

function toggleSubmenu() {
  submenuOpened.value = !submenuOpened.value
}
function editContact(ev) {
  submenuOpened.value = false
  emit('editContact', ev, props.contact)
}
function deleteContact() {
  submenuOpened.value = false
  if (confirm("Êtes vous sûr de vouloir supprimer ce contact ?")) {
    store.dispatch('contactModule/removeContact', props.contact.id)
  }
}

</script>

<template>
  <div class="contact-card">
    <div v-if="showClient" class="client"><strong>client :</strong> {{contact.client.name}}</div>
    <div v-if="!isArchived" class="submenu-button" :class="submenuOpened ? 'active' : ''" @click="toggleSubmenu">
      <SvgIconComponent name="more" />
    </div>

    <transition name="to-top" v-show="submenuOpened">
      <div  class="submenu">
        <span @click="editContact" title="éditer le contact">éditer</span>
        <span @click="deleteContact">supprimer</span>
      </div>
    </transition>

    <div class="identity">{{ contact.firstname }} {{ contact.lastname }}</div>
    <div class="job" v-if="contact.job">{{ contact.job }}</div>

    <ul v-if="contact.phones.length">
      <li v-for="(phone, index) in contact.phones" :key="index">
        <strong>{{ phone.label }} : </strong><a :href="`tel:${phone.number}`">{{phone.number}}</a>
      </li>
    </ul>

    <ul v-if="contact.emails.length">
      <li v-for="(email, index) in contact.emails" :key="index">
        <strong>{{ email.label }} : </strong><a :href="`mailto:${email.value}`">{{email.value}}</a>
      </li>
    </ul>
  </div>
</template>