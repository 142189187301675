<script setup>
import {ref, onMounted, onUpdated, computed, defineEmits} from "vue"
import store from "../../store"
import Utils from "@/Utils/Utils";
import SvgIconComponent from "../items/SvgIconComponent"

const emit = defineEmits('closePopup')

// DATA
const chosenDate = ref('')
const time = ref('0.5')
const userId = ref(store.getters['userModule/getCurrentUser'].id)
const userIri = ref(store.getters['userModule/getCurrentUser']['@id'])

// COMPUTED
const isAdmin = computed(() => {
  return store.getters['userModule/isAdmin']
})
const getUserList = computed(() => {
  return store.getters['userModule/getItems']
})

const getAddTimes = computed(() => {
  return store.getters['timesModule/getAddTimes']
})

// METHODS
function addTimes(andClose = false) {
  const newDate = new Date(chosenDate.value)

  const data = {
    date: `${newDate.getTime()/1000}`,
    times: time.value.toString(),
    user: userIri.value ?? store.getters['userModule/getCurrentUser']['@id'],
    project: getAddTimes.value.projectId,
    clientId: getAddTimes.value.clientId
  }

  store.dispatch('timesModule/addTimes', {userId: userId.value, data}).then(() => {
    if (andClose) emit('closePopup')
    const currentProject = store.getters['projectModule/getCurrent']
    if (currentProject?.id) {
      store.commit('projectModule/resetAttachments')
      store.dispatch('projectModule/getProjectById', currentProject.id)
    }

    if (data.clientId?.length)
      Utils.setNewViewedClient(parseInt(data.clientId.substring((data.clientId.length - 2), data.clientId.length)))
  })
}
function updateUser() {
  getUserList.value.map(user => {
    if (user.id === userId.value) userIri.value = user['@id']
  })
}

// LIFECYCLE
onMounted(() => {
  const date = new Date()
  const month = date.getMonth() + 1
  const day = date.getDate()

  chosenDate.value = `${date.getFullYear()}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
})

onUpdated(() => {
  if(!userId.value) userId.value = store.getters['userModule/getCurrentUser'].id
})

</script>

<template>
  <div class="add-time">
    <p v-if="getAddTimes.projectName"><strong v-if="getAddTimes.clientName">{{ getAddTimes.clientName }} : </strong>{{ getAddTimes.projectName}}</p>

    <div class="form">
      <div class="form-group">
        <label for="date">Date</label>
        <input type="date" id="date" v-model="chosenDate" required />
      </div>

      <div class="form-group">
        <label>Nombre d'heure</label>
        <input type="number" min="0.5" step="0.5" max="9.5" v-model="time" required />
      </div>

      <div v-if="isAdmin" class="form-group">
        <label for="user">Utilisateur</label>
        <select v-if="getUserList.length" name="user" id="user" v-model="userId" @change="updateUser">
          <option v-for="user in getUserList" :key="user.id" :value="user.id">
            {{user.username}}
          </option>
        </select>
        <p v-else>aucun utilisateur disponible</p>
      </div>

      <div class="buttons-group">
        <button type="button" @click.prevent="addTimes(false)" class="save-button">
          <SvgIconComponent name="save" /> enregistrer
        </button>

        <button type="button" @click.prevent="addTimes(true)" class="save-button">
          <SvgIconComponent name="save" /> enregistrer et fermer
        </button>
      </div>
    </div>
  </div>
</template>