import { createStore, createLogger } from 'vuex'
import securityModule from "./modules/security-module"
import messageModule from "./modules/message-module"
import clientModule from "./modules/client-module"
import userModule from "./modules/user-module"
import noteModule from "./modules/note-module"
import projectModule from "./modules/project-module"
import mediaModule from "./modules/media-module"
import linkModule from "./modules/link-module"
import contactModule from "./modules/contact-module"
import logModule from "./modules/log-module"
import timesModule from "./modules/times-module"
import settingsModule from "./modules/settings-module"
import leaveModule from "./modules/leave-module"
import leaveUpdateModule from "./modules/leave-update-module"
import publicHolidaysModule from "./modules/public-holidays-module"
import topBarModule from "./modules/topbar-module"
import todoModule from "./modules/todo-module"
import commonModule from "./modules/common-module"

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    securityModule,
    messageModule,
    clientModule,
    userModule,
    noteModule,
    projectModule,
    mediaModule,
    linkModule,
    contactModule,
    logModule,
    timesModule,
    settingsModule,
    leaveModule,
    leaveUpdateModule,
    publicHolidaysModule,
    topBarModule,
    todoModule,
    commonModule
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
