<script setup>
import {onMounted, ref, computed, watch} from "vue"
import store from "./../../store"
import BasicInputComponent from "../../components/fields/BasicInputComponent"
import SvgIconComponent from "../../components/items/SvgIconComponent"
import Utils from "../../Utils/Utils"
import MultiColumnTemplate from "../templates/MultiColumnTemplate"

// DATA
const projectCost = ref({
  label: 'Coût par défaut d\'un projet (en €)',
  type: 'text',
  isRequired: false,
  inputValue: '',
  isValid: false,
  activeErrorMessage: false,
  errorMessage: "Veuillez saisir une valeur numérique",
  emptyMessage: "Veuillez saisir un coût de projet",
})
const adminEmail = ref({
  label: 'Email de l\'admin',
  type: "email",
  inputValue: '',
  isValid: false,
  activeErrorMessage: false,
  errorMessage: "Veuillez saisir une adresse email correcte",
  emptyMessage: "Veuillez saisir une adresse email",
  isRequired: true
})
const projectTypesList = ref([])
const hasError = ref(false)

// COMPUTED
const getAdminEmail = computed(() => {
  return store.getters['settingsModule/getAdminEmail'] ?? ''
})
const getProjectCost = computed(() => {
  return store.getters['settingsModule/getProjectCost'] ?? ''
})
const getProjectTypesList = computed(() => {
  return store.getters['settingsModule/getProjectTypesList'] ?? []
})

// METHODS
function update() {
  validateInput()
  if (!hasError.value) {
    const data = []

    if (projectCost.value && projectCost.value !== getProjectCost.value)
      data.push({settingsKey: "projectCost", settingsValue: projectCost.value.inputValue})

    if (adminEmail.value && adminEmail.value !== getAdminEmail.value)
      data.push({settingsKey: "adminEmail", settingsValue: adminEmail.value.inputValue})

    if (projectTypesList.value.length && projectTypesList.value !== getProjectTypesList.value)
      data.push({settingsKey: "projectTypesList", settingsValue: JSON.stringify(projectTypesList.value)})

    if (data.length) {
      data.map(item => store.dispatch('settingsModule/updateSetting', item))
      store.commit('addMessage', {state: 'success', content: 'Les paramètres ont bien été mis à jour'})
    }
  }
}
function addProjectType(ev) {
  const name = ev.target.value
  const slug = Utils.refify(name)
  let exist = false
  projectTypesList.value.map(item => {
    if (item.slug === slug) {
      store.commit('addMessage', {state: 'warning', content: `"${name}" existe déjà dans la liste`})
      exist = true
    }
  })
  if (!exist) projectTypesList.value.push({slug, name, yearly: false})
  ev.target.value = ''
  ev.target.focus()
}
function updateProjectType(ev, slug) {
  projectTypesList.value.map(item => {
    if (item.slug === slug) item.yearly = ev.target.checked
  })
}
function removeProjectType(value) {
  projectTypesList.value = projectTypesList.value.filter(item => item !== value)
}
function validateInput() {
  if (!projectCost.value.isValid) {
    projectCost.value.activeErrorMessage = true
    hasError.value = true
  } else {
    hasError.value = false
  }
  if (!adminEmail.value.isValid) {
    adminEmail.value.activeErrorMessage = true
    hasError.value = true
  } else {
    hasError.value = false
  }
}

// LIFECYCLE
onMounted(() => {
  store.dispatch('settingsModule/getSettings').then(() => {
    adminEmail.value.inputValue = getAdminEmail.value
    projectCost.value.inputValue = getProjectCost.value
    projectTypesList.value = getProjectTypesList.value
  })

  store.commit('topBarModule/setMainTitle', {title: "Paramètres de l'application", icon: "settings"})
})

// WATCHERS
function watchAdminEmail(newValue) {
  adminEmail.value = newValue
}
watch(adminEmail, watchAdminEmail)

function watchProjectCost(newValue) {
  projectCost.value = newValue
}
watch(projectCost, watchProjectCost)

function watchProjectTypesList(newValue) {
  projectTypesList.value = newValue
}
watch(projectTypesList, watchProjectTypesList)

</script>

<template>
  <MultiColumnTemplate>
    <template #main>
      <div class="settings-form">
        <BasicInputComponent :label="adminEmail.label" class="field"
                             :inputType="adminEmail.type" :isRequired="adminEmail.isRequired" v-model:modelValue="adminEmail.inputValue" v-model:isValid="adminEmail.isValid"
                             :errorMessage="adminEmail.inputValue === 0 ? adminEmail.emptyMessage : adminEmail.errorMessage" :activeErrorMessage="adminEmail.activeErrorMessage"
                             :regex="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/" />

        <BasicInputComponent :label="projectCost.label" class="field" @focus="validateInput"
                             :inputType="projectCost.type" :isRequired="projectCost.isRequired"
                             v-model:modelValue="projectCost.inputValue" v-model:isValid="projectCost.isValid"
                             :errorMessage="projectCost.inputValue.length === 0 ? projectCost.emptyMessage : projectCost.errorMessage"
                             :activeErrorMessage="projectCost.activeErrorMessage"
                             :regex="/^[0-9]+$/"/>

        <div class="field">
          <strong>Types d'un projet</strong>
          <ul v-if="projectTypesList.length">
            <li v-for="projectType in projectTypesList" :key="projectType.slug">
              <SvgIconComponent name="delete-forever" :data-value="projectTypesList" @click.prevent="removeProjectType(projectType)"/>
              {{ projectType.name }}
              <input type="checkbox" id="yearly" name="yearly" :checked="projectType.yearly" @change="updateProjectType($event, projectType.slug)">
              <label for="yearly">annualisation ?</label>
            </li>
          </ul>
          <p v-else>Aucun type de projet pour le moment</p>
          <div class="input-button">
            <input type="text" id="projectType" placeholder="ajouter un type" @keyup.enter="addProjectType">
            <button type="button" class="btn btn-primary" @click="addProjectType">&ldca;</button>
          </div>
        </div>

        <hr>

        <button type="button" class="save-button" @click="update">
          <SvgIconComponent name="save"/>
          mettre à jour
        </button>
      </div>
    </template>
  </MultiColumnTemplate>
</template>