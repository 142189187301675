<script setup>
import {ref, onMounted, watch, defineProps, defineEmits} from "vue"
import SvgIconComponent from "./../items/SvgIconComponent"
import SimpleTinyMceComponent from "../fields/SimpleTinyMceComponent"
import GifPlayer from "../items/GifPlayer"
import Utils from "../../Utils/Utils"

const emit = defineEmits(['onUpdate'])
const props = defineProps({
  todo: Array,
  giftMode: {
    type: Boolean,
    default: false
  },
  enableActions: {
    type: Boolean,
    default: true
  }
})

const description = ref('')
const todosState = ref(false)
const items = ref([])
const addTodoInput = ref(null)
const todoComponent = ref(null)

function isFirstItem(uuid) {
  let currentIndex
  items.value.forEach((item, index) => {
    if (item.uuid === uuid) currentIndex = index
  })

  return items.value[currentIndex] === items.value[0] ? 'disabled' : ''
}
function isLastItem(uuid) {
  let currentIndex
  items.value.forEach((item, index) => {
    if (item.uuid === uuid) currentIndex = index
  })

  return items.value[currentIndex] === items.value[items.value.length - 1] ? 'disabled' : ''
}
function addTodo() {
  if (!description.value) return null
  const todo = {
    uuid: Utils.generateUUID(),
    createdAt: new Date(),
    description: description.value ?? '',
    isFinished: false,
  }

  if (!items.value.length) items.value.push(todo)
  else items.value.unshift(todo)

  emit('onUpdate', items.value)
  addTodoInput.value.$refs.editor.$el.focus()
  description.value = ''
}
function updateTodo(ev, todo) {
  const newTodo = {
    uuid: todo.uuid,
    isFinished: todo.isFinished,
    description: todo.description
  }

  if (ev.type === "click" && ev.target.tagName === "INPUT") {
    newTodo.isFinished = ev.target.checked
  }

  items.value.map((el, index) => {
    if (el.uuid === todo.uuid) items.value[index] = newTodo
  })

  let hasCompleted = true
  items.value.forEach(item => {
    if (!item.isFinished) hasCompleted = false
  })

  if (hasCompleted) {
    const elItems = todoComponent.value.querySelectorAll('.todo-item')
    let timeout = 400
    if (elItems) {
      elItems.forEach((item) => {
        setTimeout(() => item.classList.add('jump'), timeout)
        timeout += 120
      })
      setTimeout(() => elItems.forEach(item => item.classList.remove('jump')), timeout)
      if (props.giftMode) {
        setTimeout(() => {
          todosState.value = true
        }, timeout + 300)
      }
    }
  }

  emit('onUpdate', items.value)
}
function moveTodo(uuid, direction) {
  let from, to
  items.value.map((el, index) => {
    if (el.uuid === uuid) from = index
  })

  if (direction === "down") to = from + 1
  if (direction === "up") to = from - 1

  items.value.splice(to, 0, items.value.splice(from, 1)[0])
  emit('onUpdate', items.value)
}
function removeTodo(uuid) {
  items.value = items.value.filter(item => item.uuid !== uuid)
  emit('onUpdate', items.value)
}
function removeAll() {
  items.value = []
  emit('onUpdate', items.value)
}
function toggleAll(action) {
  let timeout = 100
  items.value.forEach(item => {
    setTimeout(() => item.isFinished = action, timeout)
    timeout += 50
  })
  setTimeout(() => emit('onUpdate', items.value), timeout)
}
function onCloseGif() {
  todosState.value = false
}

onMounted(() => {
  if (props.todo) items.value = JSON.parse(JSON.stringify(props.todo))
})

watch(() => props.todo, (newValue) => {
  items.value = JSON.parse(JSON.stringify(newValue))
})
</script>

<template>
  <div class="todo-component" ref="todoComponent">
    <div class="header">
      <SimpleTinyMceComponent name="description"
                     id="addDescription"
                     v-model="description"
                     :inlineOption="true"
                     inputPlaceholder="ajouter une nouvelle tâche"
                     ref="addTodoInput"
      />
      <button type="button" class="btn-primary" @click.prevent="addTodo">
        <SvgIconComponent name="todo-add" />
      </button>
    </div>

    <transition-group v-if="items" name="list-top" class="todo-list" tag="ul">
      <li v-for="todo in items" :key="todo.uuid" class="todo-item" :class="todo.isFinished ? 'is-finished' : ''">
        <div class="custom-checkbox">
          <input type="checkbox" @click="updateTodo($event, todo)" :checked="todo.isFinished">
          <span class="checkmark"></span>
        </div>

        <div class="content">
          <SimpleTinyMceComponent name="todo_description"
                         id="description"
                         v-model="todo.description"
                         :inlineOption="true"
                         @blur="updateTodo($event, todo)"
          />
        </div>
        <div class="actions">
          <SvgIconComponent :class="isFirstItem(todo.uuid)" name="arrow-left" class="up" @click="moveTodo(todo.uuid, 'up')" />
          <SvgIconComponent :class="isLastItem(todo.uuid)" name="arrow-left" class="down" @click="moveTodo(todo.uuid, 'down')" />
          <SvgIconComponent name="delete-forever" @click="removeTodo(todo.uuid)" />
        </div>
        <div class="completed-overlay"></div>
      </li>
    </transition-group>
    <div v-else>
      Aucune tâche n'a été renseigné
    </div>

    <div v-if="items.length" class="footer">
      <button v-if="enableActions" class="btn-secondary rounded" title="tout cocher" @click="toggleAll(true)">
        <SvgIconComponent name="todo-check" />
      </button>

      <button class="btn-secondary rounded" title="tout décocher" @click="toggleAll(false)">
        <SvgIconComponent name="todo-uncheck" />
      </button>

      <button class="btn-secondary rounded" title="tout supprimer" @click="removeAll()">
        <SvgIconComponent name="todo-delete" />
      </button>
    </div>
  </div>

  <teleport v-if="giftMode" to="#gifContainer">
    <GifPlayer :filename="'applause.gif'" :toggle-state="todosState" @closeGif="onCloseGif" :fireworks-mode="true" />
  </teleport>
</template>