<script setup>
import {computed, defineProps} from "vue"
import store from "../../store"
import ClientItemComponent from "../../components/client/ClientItemComponent"
import WindowComponent from "../items/WindowComponent"
import AddTimeComponent from "../../components/times/AddTimeComponent"

defineProps({
  clients: Object,
  emptyPlaceholder: {
    type: String,
    default() {
      return "Aucun client n'a été trouvé"
    }
  }
})

// COMPUTED
const getAddTimes = computed(() => {
  return store.getters['timesModule/getAddTimes']
})

// METHODS
function closePopup() {
  store.commit('timesModule/setAddTimes', {state: false, projectId: '', projectName: ''})
}
</script>

<template>
  <div v-if="clients.length" class="client-list" >
    <ClientItemComponent v-for="client in clients" :key="client.id" :client="client" />
  </div>

  <p v-else>{{ emptyPlaceholder }}</p>

  <teleport to="#endOfBody">
    <WindowComponent :state="getAddTimes.state" windowTitle="Ajouter du temps" windowIcon="add-times" @close="closePopup">
      <AddTimeComponent :projectId=getAddTimes.projectId :projectName="getAddTimes.projectName" @closePopup="closePopup"/>
    </WindowComponent>
  </teleport>
</template>