<script setup>
import {computed, onMounted, onUnmounted} from "vue";
import store from "@/store";
import MultiColumnTemplate from "@/views/templates/MultiColumnTemplate.vue";
import ContactListComponent from "@/components/contacts/ContactListComponent.vue";
import SearchComponent from "@/components/items/SearchComponent.vue";

const searchMainDispatch = {
  action: 'contactModule/searchContact',
  payload: {}
}

const searchResetDispatch = {
  action: 'contactModule/getAllContact',
  payload: {}
}

const getContacts = computed(() => {
  return store.getters['contactModule/getItems']
})

onMounted(() => {
  store.dispatch('contactModule/getAllContact')
  store.commit('topBarModule/setMainTitle', {title: "Contacts", icon: "contacts"})
})

onUnmounted(() => {
  store.commit('contactModule/resetContact')
})
</script>

<template>
  <MultiColumnTemplate>
    <template #main>
      <SearchComponent :main-dispatch="searchMainDispatch" :reset-dispatch="searchResetDispatch" placeholder="rechercher par nom, prénom ou client" />
      <ContactListComponent v-if="getContacts.length" :contacts="getContacts" :showClient="true" />
    </template>
  </MultiColumnTemplate>
</template>