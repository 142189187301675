<template>
  <form @submit.prevent="submit" novalidate >
    <div>
      <p><strong>Adresse email : </strong>{{ user.email }}</p>
      <p><strong>Nom : </strong>{{ user.lastname }}</p>
      <p><strong>Prénom : </strong>{{ user.firstname }}</p>
      <p><strong>Poste occupé : </strong>{{ user.job ? getJobByKey(user.job) : '' }}</p>
    </div>
    <hr>
    <div>
      <CalculateLeaveDaysComponent :start="start.value" :end="end.value" />
    </div>
    <hr>
    <div class="form-group">
      <label for="type">Type de congés</label>
      <select name="type" id="type" ref="leaveType" v-model="type">
        <option v-for="type in leaveType" :value="type" :key="type">{{ type }}</option>
      </select>
    </div>

    <div class="col2">
      <BasicInput :label="start.label" :inputType="start.type" :isRequired="start.isRequired"
                  v-model:modelValue="start.value" v-model:isValid="start.isValid"
                  :errorMessage="start.value.length === 0 ? start.emptyMessage : start.errorMessage" :activeErrorMessage="start.activeErrorMessage"
                  :regex="/^[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/" />

      <BasicInput :label="end.label" :inputType="end.type" :isRequired="end.isRequired"
                  v-model:modelValue="end.value" v-model:isValid="end.isValid"
                  :errorMessage="end.value.length === 0 ? end.emptyMessage : end.errorMessage" :activeErrorMessage="end.activeErrorMessage"
                  :regex="/^[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/" />
    </div>

    <hr>

    <SimpleTinyMce
        class="message"
        inputPlaceholder="Informations complémentaires"
        name="message"
        :inlineOption="true"
        v-model="message"/>

    <button type="submit" class="btn-primary">{{ loading ? 'chargement...' : 'Soumettre'}}</button>
  </form>
</template>

<script lang="js">
import BasicInput from "../fields/BasicInputComponent"
import SimpleTinyMce from "../fields/SimpleTinyMceComponent"
import Config from "../../Utils/Config"
import CustomSelect from "../../assets/js/lib/custom-select"
import DateUtils from "../../Utils/DateUtils"
import CalculateLeaveDaysComponent from "./CalculateLeaveDaysComponent"

export default {
  components: {CalculateLeaveDaysComponent, BasicInput, SimpleTinyMce},
  props: {
    user: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      type: Config.getLeaveTypeLabels()[0],
      start: {
        value: DateUtils.getInputFriendlyDate(new Date()),
        label: "Date de début",
        type: "date",
        isValid: false,
        activeErrorMessage: false,
        errorMessage: "veuillez saisir une date valide (jj/mm/aaaa), exemple 24/05/1985",
        emptyMessage: "Veuillez saisir une date",
        isRequired: true
      },
      end: {
        value: DateUtils.getInputFriendlyDate(new Date()),
        label: "Date de fin",
        type: "date",
        isValid: false,
        activeErrorMessage: false,
        errorMessage: "veuillez saisir une date valide (jj/mm/aaaa), exemple 24/05/1985",
        emptyMessage: "Veuillez saisir une date",
        isRequired: true
      },
      message: '',
      leaveType: Config.getLeaveTypeLabels(),
      hasError: false,
      loading: false
    }
  },
  mounted() {
    new CustomSelect(this.$refs.leaveType)
  },
  methods: {
    getJobByKey(key) {
      return Config.getJobByKey(key)
    },
    submit() {
      this.loading = true
      this.validateInput()

      if (!this.hasError) {
        const data = {
          user: this.user['@id'],
          type: this.type,
          startAt: this.start.value,
          endAt: this.end.value,
          message: this.message ?? ''
        }

        this.$store.dispatch('leaveModule/createLeave', data)
            .then(leave => {
              if (leave.id) {
                this.$store.commit('addMessage', {state: 'success', content: "La demande de congés à bien été effectué"})
                this.start.value = DateUtils.getInputFriendlyDate(new Date())
                this.end.value = DateUtils.getInputFriendlyDate(new Date())
                this.message = ""
                this.loading = false
              }
            })
      }
    },
    validateInput() {
      if (!this.start.isValid) {
        this.start.activeErrorMessage = true
        this.hasError = true
      } else if (!this.end.isValid) {
        this.end.activeErrorMessage = true
        this.hasError = true
      } else this.hasError = false
    },
  }
}
</script>