<script setup>
import { computed, defineProps, defineEmits } from 'vue'
import Editor from '@tinymce/tinymce-vue'

const emit = defineEmits(['update:modelValue', 'blur'])
const props = defineProps({
  modelValue: String,
  name: String,
  inputPlaceholder: String,
  inlineOption: Boolean
})

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
</script>

<template>
  <div class="textarea-bloc">
    <p v-if="!value && inputPlaceholder" class="placeholder">{{ inputPlaceholder }}</p>
    <editor
      tinymce-script-src="/tinymce_7.1.1/tinymce/js/tinymce/tinymce.min.js"
      :name="name"
      v-model="value"
      ref="editor"
      @blur="$emit('blur', $event, value)"
      model-events="change keyup blur focus"
      :inline="inlineOption"
      :init="{
            menubar: false,
            license_key: 'gpl',
            plugins: [
             'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace',
             'visualblocks', 'code', 'fullscreen', 'emoticons', 'insertdatetime', 'media', 'table', 'code',
             'help', 'wordcount'
           ],
           toolbar: `bold italic | alignleft aligncenter | bullist | removeformat | emoticons`
          }"
    />
  </div>
</template>