import {socketInstance} from "../../main"
import noteApi from "../../api/note"
import store from "../index"
import Config from "../../Utils/Config"

const getDescription = (type) => {
    let description = `Nom du client : ${store.getters['clientModule/getCurrent']?.name}.`
    if (type === 'project') description += ` Nom du projet : ${store.getters['projectModule/getCurrent']?.name}`

    return description
}

const state = () => ({
    items: [],
    parent: {
        type: "",
        iri: ""
    },
})

const getters = {
    getList: state => state.items,
    getParent: state => state.parent,
    countCurrent: state => state.items.length
}


const actions = {
    async getListByType({commit}, payload) {
        const notes = await noteApi.getListByType(payload.id, payload.type)
        commit('setList', notes)
        return notes
    },
    async createNote({commit}, data) {
        const note = await noteApi.create(data)
        commit('addNewNote', note)
        if (Config.isProd()) socketInstance.emit('note:create', note)

        await store.dispatch('logModule/createLog', {
            user: store.getters['userModule/getCurrentUser'].username,
            action: "CREATE",
            entity: "note",
            entityId: note.id,
            entityIri: note['@id'],
            description: getDescription(note.client ? 'client' : 'project')
        })

        return note
    },
    async updateNote(context, payload) {
        const note = await noteApi.update(payload.id, payload.data)
        if (Config.isProd()) socketInstance.emit('note:update', note)

        await store.dispatch('logModule/createLog', {
            user: store.getters['userModule/getCurrentUser'].username,
            action: "UPDATE",
            entity: "note",
            entityId: note.id,
            entityIri: note['@id'],
            description: getDescription(note.client ? 'client' : 'project')
        })

        return note
    },
    async removeNote({getters, commit}, payload) {
        let note
        getters['getList'].filter(item => {
            if (item.id === payload.id) note = item
        })

        await noteApi.remove(payload.id)
        commit('removeNote', payload.id)

        if (Config.isProd()) socketInstance.emit('note:delete', note)

        await store.dispatch('logModule/createLog', {
            user: store.getters['userModule/getCurrentUser'].username,
            action: "DELETE",
            entity: "note",
            entityId: payload.id,
            description: getDescription(payload.type)
        })
    },
}

const mutations = {
    addNewNote(state, note) {
        state.items.unshift(note)
    },
    removeNote(state, id) {
        state.items = state.items.filter(item => item.id !== id)
    },
    updateNote(state, note) {
        state.items.map((item, index) => {
            if (item.id === note.id) state.items[index] = note
        })
    },
    setList(state, notes) {
        state.items = notes
    },
    setParent(state, parent) {
        state.parent = parent
    },
    resetNotes(state) {
        state.items = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}