<script setup>
import {ref, watch, onMounted, defineProps, defineEmits} from "vue"
import store from "../../store"
import CustomSelect from "../../assets/js/lib/custom-select"
import IconTitleComponent from "../../components/items/IconTitleComponent"
import SvgIconComponent from "../../components/items/SvgIconComponent"
import BasicInputComponent from "../fields/BasicInputComponent"
import Config from "../../Utils/Config"
import Utils from "../../Utils/Utils"
import {DateTime} from "luxon";

const emit = defineEmits('cancel')
const props = defineProps({
  user: {
    type: Object,
    default() {
      return {}
    }
  }
})

// DATA
const hasError = ref(false)
const roles = ref({
  'ROLE_USER_TODO': 'Utilisateur avec todo',
  'ROLE_USER': 'Utilisateur sans todo',
  'ROLE_ADMIN_MANAGER_TODO': 'Administrateur / todo manager / avec todo',
  'ROLE_ADMIN_MANAGER': 'Administrateur / todo manager / sans todo',
  'ROLE_ADMIN': 'Administrateur sans todo',
  'ROLE_ADMIN_TODO': 'Administrateur avec todo',
  'ROLE_DISABLED' : 'Utilisateur désactivé'
})
const role = ref("ROLE_USER")
const job = ref('direction')
const jobs = ref(Config.getJobs())
const email = ref({
  value: "",
  label: "adresse e-mail",
  type: "email",
  isValid: false,
  activeErrorMessage: false,
  errorMessage: "Veuillez saisir une adresse email correcte",
  emptyMessage: "Veuillez saisir une adresse email",
  isRequired: true
})
const username = ref({
  value: "",
  label: "nom d'utilisateur",
  type: "text",
  isValid: false,
  activeErrorMessage: false,
  errorMessage: "Veuillez saisir un nom d'utilisateur",
  isRequired: true
})
const firstname = ref({
  value: "",
  label: "prénom",
  type: "text",
  isValid: false,
  activeErrorMessage: false,
  emptyMessage: "Veuillez saisir un prénom",
  errorMessage: "Veuillez saisir un prénom",
  isRequired: true
})
const lastname = ref({
  value: "",
  label: "nom",
  type: "text",
  isValid: false,
  activeErrorMessage: false,
  emptyMessage: "Veuillez saisir un nom",
  errorMessage: "Veuillez saisir un nom",
  isRequired: true
})
const password = ref({
  value: "",
  label: "mot de passe",
  type: "password",
  isValid: false,
  activeErrorMessage: false,
  errorMessage: "Veuillez saisir un mot de passe valide (plus de 8 caractères)",
  emptyMessage: "Veuillez saisir un mot de passe",
  isRequired: true
})
const birthday = ref({
  value: "",
  label: "Date de naissance",
  type: "date",
  isValid: false,
  activeErrorMessage: false,
  emptyMessage: "Veuillez saisir une date",
  errorMessage: "Veuillez saisir une date correcte",
  isRequired: false
})
const businessBirthday = ref({
  value: "",
  label: "Anniversaire professionel",
  type: "date",
  isValid: false,
  activeErrorMessage: false,
  emptyMessage: "Veuillez saisir une date",
  errorMessage: "Veuillez saisir une date correcte",
  isRequired: false
})
const title = ref("Nouvel utilisateur")
const titleIcon = ref("person-add")
const saveLabel = ref("enregister")
const editing = ref(false)
const customSelectInput = ref(null)
const customSelectJobsInput = ref(null)

// METHODS
function cancel() {
  role.value = "ROLE_USER_TODO"
  job.value = "-"
  email.value.value = ""
  username.value.value = ""
  firstname.value.value = ""
  lastname.value.value = ""
  password.value.value = ""
  birthday.value.value = ""
  businessBirthday.value.value = ""
  title.value = "Nouvel utilisateur"
  titleIcon.value = "person-add"
  saveLabel.value = "enregister"
  editing.value = false
  hasError.value = false

  customSelectInput.value.parentElement.querySelector('span.selected').innerHTML = roles.value['ROLE_USER']

  emit('cancel', props.user.id)
}
function getRoles(roles) {
  if (roles.includes('ROLE_ADMIN') && roles.includes('TODO_ENABLE') && !roles.includes('ROLE_MANAGER')) return 'ROLE_ADMIN_TODO'
  else if (roles.includes('ROLE_ADMIN') && !roles.includes('TODO_ENABLE') && !roles.includes('ROLE_MANAGER')) return 'ROLE_ADMIN'
  else if (roles.includes('ROLE_ADMIN') && roles.includes('TODO_ENABLE') && roles.includes('ROLE_MANAGER')) return 'ROLE_ADMIN_MANAGER_TODO'
  else if (roles.includes('ROLE_ADMIN') && !roles.includes('TODO_ENABLE') && roles.includes('ROLE_MANAGER')) return 'ROLE_ADMIN_MANAGER'
  else if (roles.includes('ROLE_DISABLED') && roles.includes('ROLE_USER')) return 'ROLE_DISABLED'
  else if (!roles.includes('ROLE_ADMIN') && roles.includes('TODO_ENABLE')) return 'ROLE_USER_TODO'
  else return 'ROLE_USER'
}
function setRoles(role) {
  if (role === "ROLE_ADMIN_TODO") return ['ROLE_ADMIN', 'TODO_ENABLE', 'ROLE_USER']
  else if (role === "ROLE_ADMIN") return ['ROLE_ADMIN', 'ROLE_USER']
  else if (role === "ROLE_ADMIN_MANAGER_TODO") return ['ROLE_ADMIN', 'ROLE_MANAGER', 'TODO_ENABLE', 'ROLE_USER']
  else if (role === "ROLE_ADMIN_MANAGER") return ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_USER']
  else if (role === "ROLE_USER_TODO") return ['TODO_ENABLE', 'ROLE_USER']
  else if (role === "ROLE_DISABLED") return ['ROLE_DISABLED', 'ROLE_USER']
  else return ['ROLE_USER']
}
function validateInput() {
  if (!email.value.isValid) {
    email.value.activeErrorMessage = true
    hasError.value = true
  } else if (!username.value.isValid) {
    username.value.activeErrorMessage = true
    hasError.value = true
  } else if (!firstname.value.isValid) {
    firstname.value.activeErrorMessage = true
    hasError.value = true
  } else if (!lastname.value.isValid) {
    lastname.value.activeErrorMessage = true
    hasError.value = true
  } else if (!password.value.isValid) {
    password.value.activeErrorMessage = true
    hasError.value = true
  } else hasError.value = false
}
function register() {
  validateInput()
  if (!hasError.value) {
    if (editing.value) update()
    else {
      let user = {
        roles: setRoles(role.value),
        email: email.value.value,
        job: job.value,
        username: username.value.value,
        firstname: firstname.value.value,
        lastname: lastname.value.value,
        birthday: birthday.value.value.length ? birthday.value.value : null,
        businessBirthday: businessBirthday.value.value.length ? businessBirthday.value.value : null,
        password: password.value.value
      }

      store.dispatch('userModule/createUser', user)
          .then(() => cancel())
          .catch(e => console.error(e.message))
    }
  }
}
function update() {
  const usrnm = username.value.value
  let data = {
    roles: setRoles(role.value),
    email: email.value.value,
    firstname: firstname.value.value,
    lastname: lastname.value.value,
    birthday: birthday.value.value.length ? birthday.value.value : null,
    businessBirthday: businessBirthday.value.value.length ? businessBirthday.value.value : null,
    job: job.value
  }

  if (password.value.value) data.password = password.value.value
  store.dispatch('userModule/updateUser', {username: usrnm, data})
      .then(() => {
        cancel()
        store.commit('addMessage', {state: 'success', content: `L'utilisateur "${usrnm}" à bien été mis à jour`})
      })
      .catch(e => console.error(e.message))
}

// WATCHERS
function watchEditing(newValue) {
  email.value.isRequired = !newValue
  firstname.value.isRequired = !newValue
  lastname.value.isRequired = !newValue
  password.value.isRequired = !newValue
  birthday.value.isRequired = !newValue
  businessBirthday.value.isRequired = !newValue
}
function watchUser(newValue) {
  if(newValue?.id) {
    role.value = getRoles(newValue.roles)
    job.value = newValue.job
    email.value.value = newValue.email
    username.value.value = newValue.username
    firstname.value.value = newValue.firstname ?? ''
    lastname.value.value = newValue.lastname ?? ''
    if (newValue.birthday) {
      const bDay = DateTime.fromISO(newValue.birthday)
      birthday.value.value = bDay ? bDay.toFormat("yyyy-MM-dd") : ''
    } else birthday.value.value = ""
    if (newValue.businessBirthday) {
      const bbDay = DateTime.fromISO(newValue.businessBirthday)
      businessBirthday.value.value = bbDay ? bbDay.toFormat("yyyy-MM-dd") : ''
    } else businessBirthday.value.value = ""
    password.value.value = ""
    title.value = newValue.username
    titleIcon.value = "account-circle"
    saveLabel.value = "mettre à jour"
    editing.value = true

    customSelectInput.value.parentElement.querySelector('span.selected').innerHTML = roles.value[getRoles(props.user.roles)]
    customSelectJobsInput.value.parentElement.querySelector('span.selected').innerHTML = jobs.value[props.user.job].label
  }
}

watch(editing, watchEditing)
watch(() => props.user, watchUser)

// LIFECYCLES
onMounted(() => {
  new CustomSelect(customSelectInput.value)
  new CustomSelect(customSelectJobsInput.value)
})

</script>

<template>
  <div class="register-user">
    <IconTitleComponent :title="title" :iconName="titleIcon" titleSize="h3" />
    <div v-if="editing" class="cancel-button" title="annuler" @click="cancel">
      <SvgIconComponent name="add" />
    </div>
    <form @submit.prevent="register" class="register-form" novalidate autocomplete="off">
      <div class="form-group">
        <select ref="customSelectInput" v-model="role">
          <option v-for="(r, index) in roles" :key="index" :value="index">{{ r }}</option>
        </select>
      </div>

      <div class="form-group">
        <select v-model="job" ref="customSelectJobsInput">
          <option v-for="(job, slug) in jobs" :key="slug" :value="slug">{{ job.label }}</option>
        </select>
      </div>

      <BasicInputComponent :placeholder="email.label"
                           :inputType="email.type" :isRequired="email.isRequired" v-model:modelValue="email.value" v-model:isValid="email.isValid"
                           :errorMessage="email.value.length === 0 ? email.emptyMessage : email.errorMessage" :activeErrorMessage="email.activeErrorMessage"
                           :regex="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/" />

      <BasicInputComponent :placeholder="username.label"
                           :inputType="username.type" :isRequired="username.isRequired" v-model:modelValue="username.value" v-model:isValid="username.isValid"
                           :errorMessage="username.errorMessage" :activeErrorMessage="username.activeErrorMessage" />

      <BasicInputComponent :placeholder="firstname.label"
                           :inputType="firstname.type" :isRequired="firstname.isRequired" v-model:modelValue="firstname.value" v-model:isValid="firstname.isValid"
                           :errorMessage="firstname.errorMessage" :activeErrorMessage="firstname.activeErrorMessage" />

      <BasicInputComponent :placeholder="lastname.label"
                           :inputType="lastname.type" :isRequired="lastname.isRequired" v-model:modelValue="lastname.value" v-model:isValid="lastname.isValid"
                           :errorMessage="lastname.errorMessage" :activeErrorMessage="lastname.activeErrorMessage" />

      <BasicInputComponent :placeholder="password.label" :inputType="password.type" :isRequired="password.isRequired"
                           v-model:modelValue="password.value" v-model:isValid="password.isValid"
                           :errorMessage="password.value.length === 0 ? password.emptyMessage : password.errorMessage" :activeErrorMessage="password.activeErrorMessage"
                           :regex="/.{8,}$/" />

      <BasicInputComponent :label="birthday.label" :inputType="birthday.type" :isRequired="birthday.isRequired"
                           v-model:modelValue="birthday.value" v-model:isValid="birthday.isValid"
                           :errorMessage="birthday.value.length === 0 ? birthday.emptyMessage : birthday.errorMessage" :activeErrorMessage="birthday.activeErrorMessage" />

      <BasicInputComponent :label="businessBirthday.label" :inputType="businessBirthday.type" :isRequired="businessBirthday.isRequired"
                           v-model:modelValue="businessBirthday.value" v-model:isValid="businessBirthday.isValid"
                           :errorMessage="businessBirthday.value.length === 0 ? businessBirthday.emptyMessage : businessBirthday.errorMessage" :activeErrorMessage="businessBirthday.activeErrorMessage" />

      <button type="submit" class="save-button"><SvgIconComponent name="save" /> {{ saveLabel }}</button>
    </form>
  </div>
</template>