<script setup>
import {ref, computed, defineProps} from "vue"
import {Pie} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale} from 'chart.js'
import Config from "../../Utils/Config"

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

const props = defineProps({
  times: Array
})

const chartOptions = ref({
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'répartition en heure de travail'
    }
  }
})

const getChartData = computed(() => {
  const datasets = {}
  props.times.map(time => {
    if (datasets[time.user.job]?.data) datasets[time.user.job].data += parseFloat(time.times)
    else datasets[time.user.job] = {
      data: parseFloat(time.times),
      label: Config.getJobs()[time.user.job]?.label,
      color: Config.getJobs()[time.user.job]?.color
    }
  })

  const labels = [], backgroundColor = [], data = []
  for (let key in datasets) {
    labels.push(datasets[key].label)
    backgroundColor.push(datasets[key].color)
    data.push(datasets[key].data)
  }

  return {labels, datasets: [{backgroundColor, data}]}
})
</script>

<template>
  <Pie v-if="getChartData" :chartOptions="chartOptions" :chartData="getChartData" class="project-pie-chart" />
</template>