<template>
  <div class="form-group">
    <label v-if="$attrs.label && !$attrs.placeholder">{{ $attrs.label }}</label>
    <input :type="showPassword ? 'text' : inputType" v-bind="$attrs" v-model="value" />

    <button v-if="inputType === 'password'" class="show-password">
      <SvgIconComponent :name="showPassword ? 'eye-open' : 'eye-close'" @click.prevent="showPassword = !showPassword" />
    </button>

    <transition name="fade">
      <div v-if="activeErrorMessage" class="error-message">
        <transition name="fade">
          <p v-if="errorMessage && showErrorMessage" >{{ errorMessage }}</p>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script lang="js">
import SvgIconComponent from "../items/SvgIconComponent"
export default {
  name: "BasicInputComponent",
  components: {SvgIconComponent},
  props: {
    modelValue: {
      type: String
    },
    isValid: {
      type: Boolean,
      default: null
    },
    inputType: {
      type: String,
      default: 'text'
    },
    inputClass: {
      type: String,
      default: ''
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    showIconPassword: {
      type: Boolean,
      default: true
    },
    activeErrorMessage: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    regex: {
      type: [Object, String],
      default: '',
      example: '^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{10,}$'
    }
  },
  data() {
    return {
      showPassword: false,
      showErrorMessage: false,
      isValidate: this.isValid
    }
  },
  mounted() {
    this.update(this.value)
  },
  watch: {
    value() {
      this.update(this.value)
    },
    isRequired() {
      this.update(this.value)
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.update(value)
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    returnValueByLength(value, correct, incorrect, number) {
      return value >= number ? correct : incorrect
    },
    testRegex(value) {
      if (this.regex !== null && this.regex !== '') {
        const regex = new RegExp(this.regex)
        return regex.test(value)
      } else return true
    },
    update(value) {
      if (this.isRequired && this.testRegex(value) && value.length >= 1) {
        this.isValidate = true
      } else if (this.isRequired && (!this.testRegex(value) || value.length < 1)) {
        this.isValidate = false
      } else if ((!this.isRequired && value.length >= 1) && this.regex) {
        this.isValidate = this.testRegex(value)
      } else {
        this.isValidate = true
        this.showErrorMessage = false
      }

      this.$emit('update:isValid', this.isValidate)
      this.showErrorMessage = !this.isValidate
    }
  }
}
</script>