<script setup>
import {onMounted, computed, ref} from "vue"
import store from "../../store"
import {DateTime, Interval} from "luxon"
import Config from "../../Utils/Config"
import GifPlayer from "../items/GifPlayer"
import Utils from "../../Utils/Utils";

const giftState = ref(false)

const getLeaves = computed(() => {
  return store.getters['leaveModule/getItems']
})

const getUser = computed(() => {
  return store.getters['userModule/getCurrentUser']
})

const isVacations = computed(() => (leave) => {
  const today = DateTime.now()
  const startDate = DateTime.fromISO(leave.startAt)
  const endDate = DateTime.fromISO(leave.endAt)
  const interval = Interval.fromDateTimes(startDate, endDate.plus({day: 1}))

  return (leave.type === 'Congé' || leave.type === 'Congé sans solde') && interval.contains(today)
})

const nearlyVacationsState = computed(() => (leave) => {
  const percent = getLeavePercent(leave)
  return (leave.type === 'Congé' || leave.type === 'Congé sans solde') ? (percent > 100 ? 0 : Math.round(percent)) : 0
})

const giveGift = computed(() => leave => {
  return getLeavePercent(leave) > 70
})

function getSmiley(type) {
  return Config.getLeaveSmileyByLabel(type)
}

function getReadableDate(date) {
  return DateTime.fromISO(date).toFormat('dd-MM-yyyy')
}

function getLeavePercent(leave) {
  const today = DateTime.now()
  const startDate = DateTime.fromISO(leave.startAt)
  const interval = Interval.fromDateTimes(today, startDate)

  return 100 - ((interval.count('days') / 14) * 100) ?? 0
}

function openGift() {
  giftState.value = true
}

onMounted(() => {
  const start = DateTime.now()
  const end = start.plus({months: 3})

  store.dispatch('leaveModule/getIncomingLeave', {start: start.toFormat('yyyy-MM-dd'), end: end.toFormat('yyyy-MM-dd')})
})
</script>

<template>
  <div class="leave-resume">
    <div v-if="getLeaves.length">
      <div v-for="leave in getLeaves" :key="leave" class="mini-card" :class="{'is-vacations': isVacations(leave)}">
        <div class="content">
          <span><i v-html="getSmiley(leave.type)"></i> {{leave.user.firstname}} {{leave.user.lastname}}</span>
          <span>{{getReadableDate(leave.startAt)}} - {{getReadableDate(leave.endAt)}}</span>
        </div>
        <div v-if="giveGift(leave) && getUser.username === leave.user.username" class="gift" @click.prevent="openGift()">🎁</div>
        <div v-if="nearlyVacationsState(leave)" :style="{width: `${nearlyVacationsState(leave)}%`}" class="leave-state"></div>
      </div>
    </div>
    <div v-else>
      Il n'y a aucun congés prochainement
    </div>
  </div>
  <teleport to="#gifContainer">
    <GifPlayer :filename="Utils.getRandomGifByFolderName('vacations', 5)" :toggle-state="giftState" @closeGif="giftState = false" />
  </teleport>
</template>