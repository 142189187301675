import Axios from "axios"
import Config from "../Utils/Config"
import store from "../store"

export default {
    getHeaders(contentType = "application/json") {
        return {'Authorization': `Bearer ${store.getters['securityModule/getToken']}`, 'content-type': contentType}
    },
    async get(id) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getMediaUrl()}${id}`, {headers: this.getHeaders()})
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
        })
    },
    async remove(id) {
        return new Promise((resolve, reject) => {
            Axios.delete(`${Config.getMediaUrl()}${id}`, {headers: this.getHeaders()})
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
            })
        })
    },
    async post(data) {
        return new Promise((resolve, reject) => {
            Axios.post(`${Config.getApiUrl()}/media_objects`, data, {headers: this.getHeaders("multipart/form-data")})
                .then(response => {
                    const url = response.data
                    resolve(url)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
                })
        })
    }
}