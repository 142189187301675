<script setup>
import {onMounted, computed, onUnmounted} from "vue"
import store from "./../../store"
import LeaveAskComponent from "../../components/leave/LeaveAskComponent"
import LeaveItemComponent from "../../components/leave/LeaveItemComponent"
import MultiColumnTemplate from "../templates/MultiColumnTemplate"
import IconTitleComponent from "../../components/items/IconTitleComponent"

onMounted(() => {
  if (!store.state.userModule.user) store.dispatch('userModule/getUser', localStorage)
  store.dispatch('leaveModule/getListByUser', localStorage.getItem('user'))
  store.commit('topBarModule/setMainTitle', {title: "Demande de congès", icon: "calendar-range"})
  store.dispatch('publicHolidaysModule/getList')
})

onUnmounted(() => {
  store.commit('leaveModule/resetList')
})

const getUser = computed(() => {
  return store.getters['userModule/getCurrentUser']
})

const getLeaves = computed(() => {
  return store.getters['leaveModule/getItems']
})

</script>

<template>
  <MultiColumnTemplate>
    <template #main>
      <LeaveItemComponent v-for="leave in getLeaves" :leave="leave" :key="leave.id" :lightVersion="true" />
      <p v-if="!getLeaves.length">Aucune demande n'est disponibe</p>
    </template>
    <template #right>
      <IconTitleComponent title="Faire une demande" title-size="h3" icon-name="add" />
        <LeaveAskComponent v-if="getUser" :user="getUser" />
    </template>
  </MultiColumnTemplate>
</template>