<template>
  <div class="textarea-bloc">
    <p v-if="!isFocused && !value && inputPlaceholder" class="placeholder">{{inputPlaceholder}}</p>
    <editor
        tinymce-script-src="/tinymce_7.1.1/tinymce/js/tinymce/tinymce.min.js"
        :name="name"
        :id="nameToId(name)"
        v-model="value"
        ref="editor"
        @focus="isFocused = true"
        @blur="onBlur($event, value)"
        model-events="change keyup blur"
        :inline="inlineOption"
        initial-value="Ajouter un description"
        :init="{
            menubar: false,
            license_key: 'gpl',
            plugins: [
             'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace',
             'visualblocks', 'code', 'fullscreen', 'emoticons', 'insertdatetime', 'media', 'table', 'code',
             'help', 'wordcount'
           ],
           toolbar: `formatselect | bold italic backcolor | alignleft aligncenter alignright | bullist numlist | removeformat | code fullscreen | emoticons`
          }"
    />
  </div>
</template>

<script lang="js">
import Config from "../../Utils/Config"
import Editor from "@tinymce/tinymce-vue"

export default {
  name: "TinyMce",
  components: {Editor},
  data() {
    return {
      tinyMceApiKey: Config.getTinyMceApiKey(),
      isFocused: false
    }
  },
  props: {
    modelValue: String,
    name: String,
    inputPlaceholder: String,
    inlineOption: Boolean
  },
  emits: ['update:modelValue', 'blur'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    nameToId(string) {
      const arrString = string.split('_')
      return arrString[0] + (arrString[1].charAt(0).toUpperCase() + arrString[1].slice(1))
    },
    onBlur(event, value) {
      this.isFocused = false
      this.$emit('blur', event, value)
    }
  }
}
</script>