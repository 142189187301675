import userApi from "../../api/user"
import {StringToImage} from "string-to-image"
import {DateTime} from "luxon";

function stringToColor(string) {
    if (!string) return ''
    let int = 0
    string.split('').map(char => int += char.charCodeAt(0))
    return `hsl(${Math.round(int / 255 * 360 + 120) % 360}, 80%, 70%)`
}

function getAvatar(string) {
    return StringToImage.generateImageUrl(string, {
        width: 9,
        height: 9,
        population: 0.6,
        color: stringToColor(string)
    })
}

const state = {
    user: {},
    items: []
}

const getters = {
    getCurrentUser: state => state.user,
    getItems: state => state.items,
    getUserRole: state => state.user.roles ? state.user.roles[0] : '',
    getUserAvatar: () => username => getAvatar(username),
    isAdmin: state => state.user.roles && state.user.roles.includes("ROLE_ADMIN"),
    isManager: state => state.user.roles && state.user.roles.includes('ROLE_MANAGER'),
    hasTodoEnable: state => state.user.roles && state.user.roles.includes('TODO_ENABLE'),
    getUserByAnniversary: state => state.items.filter(user => {
        const today = new DateTime("now")
        const businessBirthday = user.businessBirthday?.length ? DateTime.fromISO(user.businessBirthday) : ''
        return `${businessBirthday.day}-${businessBirthday.month}` === `${today.day}-${today.month}`
    }),
    getUserByBirthday: state => state.items.filter(user => {
        const today = new DateTime("now")
        const birthday = user.birthday?.length ? DateTime.fromISO(user.birthday) : ''
        return `${birthday.day}-${birthday.month}` === `${today.day}-${today.month}`
    })
}

const actions = {
    async getList({commit}) {
        const users = await userApi.getList()
        commit('setItems', users)
        return users
    },
    async getActiveMemberList({commit}) {
        const users = await userApi.getList()
        const activeUsers = users.filter(user => !user.roles.includes('ROLE_DISABLED'))
        commit('setItems', activeUsers)
        return users
    },
    async createUser({commit, dispatch}, data) {
        const username = await userApi.create(data)
        const user = await dispatch('getUser', username)
        commit('addItems', user)
    },
    async getUser({commit}, username) {
        let user
        if (username) {
            user = await userApi.getUserByUsername(username)
            commit('setCurrentUser', user)
        } else {
            user = await userApi.getCurrentUser()
            commit('setCurrentUser', user)
        }

        localStorage.setItem('user', user.id)
        return user
    },
    async removeUser({commit}, username) {
        await userApi.remove(username)
        commit('removeUser', username)
    },
    async updateUser({commit}, payload) {
        const user = await userApi.update(payload.username, payload.data)
        commit('updateUser', user)
        return user
    }
}

const mutations = {
    setCurrentUser(state, user) {
        state.user = user
    },
    setItems(state, items) {
        state.items = items
    },
    addItems(state, item) {
        state.items.push(item)
    },
    logoutUser(state) {
        state.user = {}
    },
    removeUser(state, username) {
        state.items = state.items.filter(item => item.username !== username)
    },
    updateUser(state, user) {
        state.items.map((item, index) => {
            if (user.username === item.username) state.items[index] = user
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}