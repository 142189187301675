<script setup>
import {ref, onMounted, computed, defineProps} from "vue"
import store from "./../../store"
import Config from "../../Utils/Config"
import SvgIconComponent from "../items/SvgIconComponent"
import ProjectCreateComponent from "../projects/ProjectCreateComponent"
import ProjectListComponent from "../projects/ProjectListComponent"

// DATA
const props = defineProps({
  client: Object
})
const imageLogo = ref("")

// COMPUTED
const isAdmin = computed(() => {
  return store.getters['userModule/isAdmin']
})
const archivedMode = computed(() => {
  return store.getters['clientModule/getArchiveMode']
})

// METHODS
function archiveClient(id, bool) {
  const data = {isArchived: bool, updatedAt: "now"}
  store.dispatch('clientModule/updateClient', {id, data, action: "archive"})
}
function removeClient(name, id) {
  if (confirm(`Voulez vraiment supprimer ${name}`)) {
    store.dispatch('clientModule/removeClient', {id, name})
  }
}
// LIFECYCLE
onMounted(() => {
  if (props.client.logo) {
    store.dispatch('mediaModule/getFile', props.client.logo)
        .then(file => imageLogo.value = `${Config.getMediaUrl()}/${file.data.contentUrl}`)
  }
})
</script>

<template>
  <div class="card">
    <router-link :to="{'name': 'clientSingle', 'params' : {'id': client.id}}" class="card-header"
                 title="Voir le client">
      <div :class="imageLogo ? 'logo' : 'logo empty' ">
        <img v-if="imageLogo" :src="imageLogo" alt="logo du client" loading="lazy">
      </div>
      {{ client.name }}
    </router-link>

    <div class="card-body">
      <ProjectListComponent v-if="client.projects.length" :projects="client.projects" :client="client" template="dashboard"/>
      <ProjectCreateComponent v-else :client="client"/>
    </div>

    <div class="card-footer">
      <i v-if="client.updatedAt" class="date">Mis à jour le :<br>{{ new Date(client.updatedAt).toLocaleString() }}</i>
      <i v-else class="date">Créé le <br> {{ new Date(client.createdAt).toLocaleString() }}</i>

      <div class="operations">
        <div v-if="!archivedMode" class="icon archive" :title="`Archiver ${client.reference}`"
             @click="archiveClient(client.id, true)">
          <SvgIconComponent name="archive"/>
        </div>
        <div v-else class="icon unarchive" :title="`Désarchiver ${client.reference}`"
             @click="archiveClient(client.id, false)">
          <SvgIconComponent name="archive"/>
        </div>
        <div v-if="isAdmin && archivedMode" class="icon delete" :title="`Supprimer ${client.reference}`"
             @click="removeClient(client.name, client.id)">
          <SvgIconComponent name="delete-forever"/>
        </div>
      </div>
    </div>
  </div>
</template>