<script setup>
import {ref, onMounted, computed} from "vue"
import store from "../../store"
import NoteComponent from "./NoteComponent"
import {socketInstance} from "../../main"
import Config from "../../Utils/Config"

const userIsTyping = ref(false)

const notes = computed(() => {
  return store.getters['noteModule/getList']
})
const parent = computed(() => {
  return store.getters['noteModule/getParent']
})

onMounted(() => {
  if (Config.isProd()) {
    socketInstance.on('note:isTyping', payload => {
      if (payload.state) {
        if(!userIsTyping.value.includes(payload) && payload.parent.iri === parent.value.iri) userIsTyping.value.push({id: payload.id, message: payload.message})
      } else {
        userIsTyping.value = userIsTyping.value.filter(item => item.id !== payload.id)
      }
    })
  }
})
</script>

<template>
  <div v-if="notes" class="note-list">
    <transition-group name="list-top" tag="ul">
      <li key="first"><NoteComponent :parent="parent" /></li>
      <li key="second" class="typing-list">
        <ul v-if="userIsTyping.length">
          <li v-for="item in userIsTyping" :key="item.id">
            {{ item.message }}
          </li>
        </ul>
      </li>
      <li v-for="note in notes" :key="note.id">
        <NoteComponent :data="note" :parent="parent" />
      </li>
    </transition-group>
  </div>
</template>