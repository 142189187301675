<script setup>
import {defineProps, computed} from "vue"
import store from "../../store"
import Config from "../../Utils/Config"
import StatsUtils from "../../Utils/StatsUtils"
import {DateTime} from "luxon"
import DateUtils from "../../Utils/DateUtils"

const props = defineProps({
  project: Object,
  times: Array
})

const isAdmin = computed(() => {
  return  store.getters['userModule/isAdmin']

})
const getTotalByUser = computed(() => {
  let total = {}
  props.times.map(time => {
    const key = `${time.user.username} - ${Config.getJobs()[time.user.job].label}`
    if (total[key]) total[key] = parseFloat(total[key]) + parseFloat(time.times)
    else total[key] = parseFloat(time.times)
  })

  return total
})
const getTotal = computed(() => {
  return StatsUtils.getTotalTimes(props.times)

})
const getTotalCost = computed(() => {
  return StatsUtils.getTotalCost(props.project, props.times)
})
const getGain = computed(() => {
  return StatsUtils.getGain(props.project, props.times)
})
const getMargin = computed(() => {
  return StatsUtils.getMargin(props.project, props.times)
})

function getProgress(project) {
  if (project.state === 1 || project.state === 2) return 100 - parseInt(StatsUtils.getMargin(project, props.times))
  else return 0
}
function getProgressWidth(project) {
  const progress = getProgress(project)
  if (progress >= 100) return "width: 100%;"
  else return `width: ${progress}%;`
}
function getProgressState(project) {
  const progress = getProgress(project)
  if (progress > 65 && progress <= 93) return "warning"
  else if (progress > 93) return "danger"
  else return "success"
}
</script>

<template>
  <div class="time-view-resume">
    <p v-if="project.yearly">Depuis le {{DateTime.fromISO(DateUtils.getYearlyDate(project)).toLocaleString()}}</p>
    <div v-if="getTotalByUser" class="total">
      <div v-for="(value, key) in getTotalByUser" :key="key">
        <span>{{key}}</span>
        <span>{{value}}h</span>
      </div>

      <div>
        <span>Total</span>
        <span>{{getTotal}}h</span>
      </div>
    </div>

    <div class="total-cost" v-if="project.dailyCost && project.sellingPrice && isAdmin">
      <div class="mini-card"><strong>Coût du projet :</strong> {{ !isNaN(getTotalCost) ? getTotalCost : 0 }} €</div>
      <div class="mini-card">
        <strong>Marge du projet :</strong> {{ !isNaN(getMargin) ? getMargin : 0 }}%
        <div class="margin-progress" :class="getProgressState(project)" :style="getProgressWidth(project)"></div>
      </div>
      <div class="mini-card"><strong>Marge en € :</strong> {{ !isNaN(getGain) ? getGain : 0 }}€</div>
    </div>
    <div v-else class="messages-container">
      <p v-if="!project.dailyCost" class="message warning">Veuillez renseigner le coût jour</p>
      <p v-if="!project.sellingPrice" class="message warning">Veuillez renseigner le prix de vente</p>
    </div>
  </div>
</template>