<script setup>
import {onMounted, computed} from "vue"
import store from "./../../store"
import TodoComponent from "../../components/todo/TodoComponent"
import MultiColumnTemplate from "../templates/MultiColumnTemplate"
import TodoAgencyComponent from "../../components/todo/TodoAgencyComponent"

const getUser = computed(() => {
  return store.getters['userModule/getCurrentUser']
})

onMounted(() => {
  store.dispatch('settingsModule/getSettings')
  store.commit('topBarModule/setMainTitle', {title: "Ma todo", icon: "todo-list"})
})

function update(todo, user) {
  store.dispatch('userModule/updateUser', {username: user.username, data: {todo}})
}
</script>

<template>
  <MultiColumnTemplate>
    <template #main>
      <div class="todo-list-container">
        <div class="agency-todo-container">
          <h3>Todo de l'agence</h3>
          <TodoAgencyComponent />
        </div>
        <hr>
        <TodoComponent :todo="getUser.todo" @onUpdate="update($event, getUser)" :gift-mode="true" />
      </div>
    </template>
  </MultiColumnTemplate>
</template>