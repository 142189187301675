<script setup>
import {defineProps, defineEmits, ref, watch} from "vue"
import {Fireworks} from "fireworks-js"
import SvgIconComponent from "./SvgIconComponent"

const props = defineProps({
  toggleState: Boolean,
  fireworksMode: {
    type: Boolean,
    default: false
  },
  filename: String
})

const state = ref(false)
const fireworks = ref(null)
const fireworksContainer = ref(null)

const emit = defineEmits(['closeGif'])

function closeGif() {
  state.value = false
  if (fireworks.value) fireworks.value.stop()
  emit('closeGif')
}

watch(() => props.toggleState, (newValue) => {
  state.value = newValue

  if (newValue && props.fireworksMode) {
    setTimeout(() => {
      if (!fireworks.value) fireworks.value = new Fireworks(fireworksContainer.value)
      fireworks.value.start()
    }, 0)
  } else if (fireworks.value) fireworks.value.stop()
})
</script>

<template>
  <div class="gif-content" :class="{'open': state}">
    <SvgIconComponent name="add" @click.prevent="closeGif()"/>
    <picture>
      <img :src="filename" alt="">
    </picture>
    <div v-if="fireworksMode" ref="fireworksContainer" class="fireworks"></div>
    <div v-else class="overlay"></div>
  </div>
</template>