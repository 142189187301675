<script setup>
import {onMounted, ref, computed, onUnmounted} from "vue"
import {useRoute} from "vue-router"
import store from "./../../store"
import PaginationComponent from "../../components/items/PaginationComponent"
import TimesListComponent from "../../components/times/TimesListComponent"
import MultiColumnTemplate from "../templates/MultiColumnTemplate"
import SearchComponent from "../../components/items/SearchComponent"

const userId = ref(localStorage.getItem('user'))
const route = useRoute()

const searchMainDispatch = {
  action: 'timesModule/searchTimes',
  payload: {user: userId.value}
}

const searchResetDispatch = {
  action: 'timesModule/getTimeListByUser',
  payload: {user: userId.value, page: route.query?.page ?? '', pagination: true}
}

// LIFECYCLE
onMounted(() => {
  const page = route.query?.page ?? ''
  store.dispatch('timesModule/getTimeListByUser', {user: userId.value, page, pagination: true})
  store.commit('topBarModule/setMainTitle', {title: 'Gestion du temps', icon: 'times'})
})

onUnmounted(() => {
  store.commit('timesModule/resetTimes')
})

// COMPUTED
const times = computed(() => {
  return store.getters['timesModule/getItems']
})
const getPagination = computed(() => {
  return store.getters['timesModule/getPagination']
})

</script>

<template>
  <MultiColumnTemplate>
    <template #main>
      <div class="time-list">
        <SearchComponent :main-dispatch="searchMainDispatch" :reset-dispatch="searchResetDispatch" />

        <TimesListComponent :times="times" />

        <PaginationComponent v-if="getPagination" :pagination="getPagination" />
      </div>
    </template>
  </MultiColumnTemplate>
</template>