<script setup>
import {computed, defineProps, ref, defineEmits} from "vue"

const emit = defineEmits(['toggle', 'update:modelValue'])
const props = defineProps({
  modelValue: {type: Boolean, default: false},
  disabled: {type: Boolean, default: false},
  name: {type: String, required: true},
  title: {type: String, required: true},
})

const toggleState = ref(props.modelValue)

const id = computed(() => {
  return props.name.replace(/ /g, '').toLowerCase();
})

function toggle() {
  if (props.disabled) return;
  toggleState.value = !toggleState.value;
  emit('toggle', toggleState.value);
  emit('update:modelValue', toggleState.value)
}
</script>

<template>
  <div class="toggle-component">
    <label :id="`${id}Label`" :for="id">{{title}}</label>
    <input type="checkbox" :id="id" v-model="toggleState" :disabled="disabled" :name="name" v-bind="$attrs"/>
    <span
        :aria-checked="toggleState"
        :aria-disabled="disabled"
        :aria-labelledby="`${id}Label`"
        :aria-readonly="disabled"
        class="toggle"
        :class="{'is-toggled': toggleState}"
        role="checkbox"
        @click="toggle"/>
  </div>
</template>