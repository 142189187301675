import {DateTime, Interval} from "luxon"

export default class DateUtils {
    static getInputFriendlyDate(date) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()

        return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    }

    static getYearlyDate(project) {
        let projectDate = project.startingDate
        const now = DateTime.now()
        const startingDate = DateTime.fromISO(project.startingDate)
        const interval = Interval.fromDateTimes(startingDate, now)
        if (interval.length('years') > 1) {
            if (now.month > startingDate.month) projectDate = startingDate.set({year: now.year}).toISODate()
            else projectDate = startingDate.set({year: now.year - 1}).toISODate()
        }
        return projectDate
    }

    static stringToTimestamp(strDate) {
        const date = new Date(strDate)
        return (date.getTime() / 1000).toString()
    }
}