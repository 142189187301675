<template>
  <transition-group name="list-right" v-if="getAttachments.length" tag="ul" class="attachment-list">
    <li v-for="(file, index) in getAttachments" :key="index">
      <div class="file">
        <SvgIcon :name="getFileIcon(file.extension)" />
        <a :href="file.url" target="_blank" @mouseenter="togglePreview(file)" @mouseleave="activePreview = false; previewUrl = ''">
          {{ file.name }} <SvgIcon name="open-new-window" />
        </a>
      </div>
      <div v-if="!isArchived" @click="removeFile(file)" class="delete-button">supprimer</div>
    </li>
  </transition-group>
  <p v-else>Aucun fichier pour le moment</p>

  <teleport to="#endOfBody">
    <ImagePreview :src="previewUrl" :active="activePreview" />
  </teleport>
</template>

<script lang="js">
import SvgIcon from "./SvgIconComponent"
import Utils from "../../Utils/Utils"
import ImagePreview from "./ImagePreviewComponent"

export default {
  name: "AttachmentsListComponent",
  components: {ImagePreview, SvgIcon},
  props: {
    getter: String,
    removeMutation: String
  },
  data() {
    return {
      activePreview: false,
      previewUrl: ""
    }
  },
  methods: {
    getFileIcon(extension) {
      return Utils.getFileType(extension)
    },
    removeFile(file) {
      if (confirm(`Êtes-vous sûr de vouloir supprimer le fichier id : ${file.iri}`)) {
        this.$store.dispatch('mediaModule/removeFile', file.iri)
        this.$store.commit(this.removeMutation, file.iri)
      }
    },
    togglePreview(file) {
      if (Utils.getFileType(file.extension) === "image") {
        this.activePreview = true
        this.previewUrl = file.url
      }
    }
  },
  computed: {
    isArchived() {
      return this.$store.getters['clientModule/getArchiveMode']
    },
    getAttachments() {
      return this.$store.getters[this.getter]
    }
  }
}
</script>