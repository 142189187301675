<script setup>
import {ref, defineProps, computed, onMounted} from "vue"
import store from "../../store"
import DateUtils from "../../Utils/DateUtils"
import Config from "../../Utils/Config"
import ToggleComponent from "../fields/ToggleComponent"
import CustomSelect from "../../assets/js/lib/custom-select"

const props = defineProps({
  project: Object,
  lightView: {
    type: Boolean,
    default: false
  }
})

const sellingPrice = ref(props.project.sellingPrice ?? 0)
const dailyCost = ref(props.project.dailyCost ?? 0)
const purchases = ref(props.project.purchases ?? 0)
const startingDate = ref(DateUtils.getInputFriendlyDate(new Date(props.project.startingDate)) ?? DateUtils.getInputFriendlyDate(new Date()))
const state = ref(props.project.state ?? 0)
const yearly = ref(props.project.yearly ?? false)
const customSelectProjectState = ref(null)

const inputs = {sellingPrice, dailyCost, purchases, state, startingDate, yearly}

const getProjectStates = computed(() => {
  return Config.getProjectStates()
})

function update(ev) {
  const data = {}
  if (parseInt(ev.target.value) !== 0 && !parseInt(ev.target.value)) {
    store.commit('addMessage', {state: "warning", content: "veuillez ne renseigner que des chiffres"})
    inputs[ev.target.name].value = 0
  }
  data[ev.target.name] = parseInt(ev.target.value.replace(' ', ''))
  store.dispatch('projectModule/updateProject', {id: props.project.id, data})
}
function updateYearly(value) {
  yearly.value = value
  store.dispatch('projectModule/updateProject', {id: props.project.id, data: {yearly: value}})
}
function updateStartingDate(ev) {
  store.dispatch('projectModule/updateProject', {id: props.project.id, data: {startingDate: ev.target.value}})
}
function updateState(ev) {
  if (parseInt(ev.target.value) !== parseInt(props.project.state))
    store.dispatch('projectModule/updateProject', {id: props.project.id, data: {state: parseInt(ev.target.value)}})
}

onMounted(() => {
  new CustomSelect(customSelectProjectState.value)
})
</script>

<template>
  <div class="project-settings">
    <div class="form-group">
      <label for="sellingPrice">Prix vendu : </label>
      <input type="text" id="sellingPrice" name="sellingPrice" @blur="update" v-model="sellingPrice" autocomplete="off">
    </div>

    <div class="form-group">
      <label for="dailyCost">Coût jour : </label>
      <input type="text" id="dailyCost" name="dailyCost" @blur="update" v-model="dailyCost" autocomplete="off">
    </div>

    <div class="form-group">
      <label for="purchases">Achats : </label>
      <input type="text" id="purchases" name="purchases" @blur="update" v-model="purchases" autocomplete="off">
    </div>

    <div v-if="!lightView" class="form-group checkbox">
      <ToggleComponent id="yearly" name="yearly" v-model="yearly" title="Annualisé ?" :aria-checked="!!yearly" @toggle="updateYearly" />
    </div>

    <div v-if="!lightView && yearly" class="form-group date">
      <label for="startingDate">Date de démarrage :</label>
      <input type="date" id="startingDate" name="startingDate" @change="updateStartingDate" v-model="startingDate" :checked="!!startingDate">
    </div>

    <div class="form-group select">
      <label for="state">Statut du projet</label>
      <select name="state" id="state" v-model="state" @change="updateState" ref="customSelectProjectState">
        <option v-for="(label, key) in getProjectStates" :value="key" :key="key">{{label}}</option>
      </select>
    </div>
  </div>
</template>