<script setup>
import {onMounted, ref} from "vue"
import StatsUtils from "../../Utils/StatsUtils"
import ApiClient from "../../api/client"

const bestClient = ref([])

onMounted(() => {
  ApiClient.getAll({state: 2})
      .then(clients => {
        const arrMargin = []

        clients.forEach(client => {
          let clientMargin = 0
          let clientGain = 0

          if (client.projects.length) {
            let projectMargin = 0
            let projectGain = 0
            let pCount = 0

            client.projects.forEach(p => {
              if (p.state === 2 && p.sellingPrice && p.times) {
                const margin = StatsUtils.getMargin(p, p.times)
                const gain = StatsUtils.getGain(p, p.times)
                if (!isNaN(margin) && margin !== 0) projectMargin += margin
                if (!isNaN(gain) && gain !== 0) projectGain += gain
                pCount++
              }
            })

            if (!isNaN(projectMargin) && projectMargin > 0) clientMargin += Math.round(projectMargin / pCount)
            if (!isNaN(projectGain) && projectGain > 0) clientGain += Math.round(projectGain / pCount)
          }

          if (clientMargin && clientMargin > 0) arrMargin.push({margin: clientMargin, name: client.name, gain: clientGain})
        })

        bestClient.value = [...arrMargin].sort((a, b) => b.margin - a.margin).slice(0, 10)
      })
})
</script>

<template>
  <div v-for="(data, index) in bestClient" :key="index" class="mini-card">
    <div><strong>{{ data.margin }}% :</strong> {{ data.name }} ({{data.gain}}€)</div>
  </div>
  <p v-if="!bestClient.length"> Aucun projet n'est à féliciter 😭</p>
</template>