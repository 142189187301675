import store from "@/store";

function getYearsInterval(date) {
    const todayYear = new Date().getFullYear()
    const itemYear = date.substring(0, 4)
    return todayYear - parseInt(itemYear)
}

function getYears(date) {
    const yearInterval = getYearsInterval(date)
    const plural = yearInterval > 1 ? 's' : ''
    return `${yearInterval} an${plural}`
}

const getters = {
    getAnniversaries: () => {
        const items = []
        const clients = store.getters['clientModule/getClientByAnniversary']
        const usersBusinessAnniversary = store.getters['userModule/getUserByAnniversary']
        const usersBirthday = store.getters['userModule/getUserByBirthday']

        if(clients.length) clients.forEach(client => {
            const year = getYearsInterval(client.createdAt)
            if (year > 0) {
                items.push({
                    name: client.name,
                    message: `pour ses ${getYears(client.createdAt)} de présence dans l'app client 🍾🍾`
                })
            }
        })

        if (usersBusinessAnniversary.length) usersBusinessAnniversary.forEach(user => {
            items.push({
                name: user.firstname,
                message: `pour ses ${getYears(user.businessBirthday)} à l'agence 💛🖤`
            })
        })

        if (usersBirthday.length) usersBirthday.forEach(user => {
            items.push({
                name: user.firstname,
                message: `, passe une belle journée 🎊💕`
            })
        })

        return items
    },
}

export default {
    namespaced: true,
    getters
}