<script setup>
import {defineProps, onMounted, ref} from "vue";
import {DateTime} from "luxon";
import Utils from "../../Utils/Utils";
import SvgIconComponent from "../items/SvgIconComponent.vue";

const props = defineProps({
  weatherData: Object
})

const image = ref("")
const numDay = ref("")
const hour = ref("")
const shortDay = ref("")
const month = ref("")
const year = ref("")
const temperature = ref("")
const apparent = ref("")
const humidity = ref("")

onMounted(() => {
  props.weatherData?.forEach(item => {
    const date = DateTime.fromFormat(item.time, "yyyy-MM-dd HH:mm:ss")

    hour.value = date.setLocale('fr').toFormat("H")
    numDay.value = date.setLocale('fr').toFormat("dd")
    shortDay.value = date.setLocale('fr').toFormat("ccc")
    month.value = date.setLocale('fr').toFormat("LLL")
    year.value = date.setLocale('fr').toFormat("yyyy")
    temperature.value = Math.round(item.temperature).toString()
    apparent.value = Math.round(item.apparent)
    humidity.value = Math.round(item.humidity)
    image.value = Utils.getGifByWeatherCode(item.weatherCode)
  })
})
</script>

<template>
  <div class="weather-item-component" v-if="temperature">
    <div class="weather-container">
      <div class="weather-item"><SvgIconComponent name="temperature" /> {{temperature}}°C</div>
      <div class="weather-item"><SvgIconComponent name="apparent" /> {{apparent}}°C</div>
      <div class="weather-item"><SvgIconComponent name="humidity" /> {{humidity}}%</div>
    </div>
    <div class="weather-date">
      <p class="short-day">{{shortDay}}</p>
      <p class="num-day">{{numDay}}</p>
      <p class="month">{{month}}</p>
      <p class="year">{{year}}</p>
      <p class="hour">{{hour}}h00</p>
    </div>
    <img v-if="image" :src="image" alt="Weather default" loading="lazy">
  </div>
</template>