<script setup>
import {computed} from "vue"
import store from "../../store"

const getAgencyTodo = computed(() => {
  return store.getters['settingsModule/getAgencyTodo']
})
</script>

<template>
  <ul v-if="getAgencyTodo" class="agency-todo">
    <li v-for="todo in getAgencyTodo" :key="todo.uuid" class="mini-card">
      <div v-html="todo.description"></div>
    </li>
  </ul>
</template>