<script setup>
import {ref, defineProps, computed} from "vue"
import StatsUtils from "../../Utils/StatsUtils"
import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const props = defineProps({
  projects: Array
})

const chartOptions = ref({
  plugins: {
    title: {display: true, text: "Stats global", font: {size: 22}, padding: {bottom: 0}},
  },
  scales: {
    y: {
      ticks: {
        callback: function (value) {
          return `${value}€`
        }
      }
    }
  },
  responsive: true
})

const chartData = computed(() => {
  const chartData = {
    labels: [],
    datasets: []
  }

  if (props.projects) {
    chartData.labels = getProjectLabels.value
    chartData.datasets = [
      {label: '€ prix de vente', data: getSellingPrices.value, backgroundColor: '#ffd100'},
      {label: '€ coût du projet', data: getTotalCost.value, backgroundColor: '#1a1a1a'},
    ]
  }

  return chartData
})
const getProjectLabels = computed(() => {
  const labels = []
  props.projects.forEach(project => {
    labels.push([project.client.name, project.name])
  })

  return labels
})
const getTotalCost = computed(() => {
  const totalCost = []
  props.projects.forEach(project => {
    totalCost.push(StatsUtils.getTotalCost(project))
  })

  return totalCost
})
const getSellingPrices = computed(() => {
  const sellingPrices = []
  props.projects.forEach(project => {
    sellingPrices.push(project.sellingPrice)
  })

  return sellingPrices
})
</script>

<template>
  <Bar :chart-options="chartOptions" :chart-data="chartData"/>
</template>