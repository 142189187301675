<template>
  <div class="input-group">
    <input type="text" name="link" placeholder="Collez/Écrivez votre lien ici &ldca;" v-model="link">
    <input v-if="link" type="text" name="label" placeholder="Nom du lien (facultatif)" v-model="label">

    <div class="button-group">
      <button v-if="link" @click="addLink" class="save-button">{{ saveLabel }}</button>
      <button v-if="link" @click="reset" class="reset-button">
        <SvgIcon name="add" />
      </button>
    </div>
  </div>
  <div ref="trello"></div>
</template>

<script lang="js">
import SvgIcon from "../items/SvgIconComponent"
export default {
  name: "CreateLinkComponent",
  components: {SvgIcon},
  props: {
    parent: Object
  },
  data() {
    return {
      label: "",
      link: "",
      type: "website",
      saveLabel: "Ajouter"
    }
  },
  created() {
    document.addEventListener('paste', this.pastLink)
  },
  unmounted() {
    document.removeEventListener('paste', this.pastLink)
  },
  watch: {
    link(value, oldValue) {
      if (value !== oldValue) {
        if (value.includes('trello.com')) {
          this.saveLabel = "Ajouter un carte trello"
          this.type = "trello"
        } else if (value.includes('linkedin.com')) {
          this.saveLabel = "Ajouter un lien linkedin"
          this.type = "linkedin"
        } else if (value.includes('facebook.com')) {
          this.saveLabel = "Ajouter un lien facebook"
          this.type = "facebook"
        } else if (value.includes('drive.google.com/drive')) {
          this.saveLabel = "Ajouter un Google drive"
          this.type = "gg_drive"
        } else if (value.includes('docs.google.com')) {
          this.saveLabel = "Ajouter un Google doc"
          this.type = "gg_docs"
        } else {
          this.saveLabel = "Ajouter un lien"
          this.type = "website"
        }
      }
    }
  },
  methods: {
    pastLink(ev) {
      if (ev instanceof ClipboardEvent) {
        const regex = new RegExp('^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$')
        const pasteLink = ev.clipboardData.getData('text')

        if (pasteLink && regex.test(pasteLink)) {
          this.label = pasteLink.split('/')[2]
          this.link = pasteLink
        }

        if (this.link.includes('trello.com')) {
          this.saveLabel = "Ajouter un carte trello"
        }
      }

    },
    reset() {
      this.link = ""
      this.label = ""
      this.type = ""
      this.saveLabel = "Ajouter"
    },
    addLink() {
      if (this.link) {
        const regex = new RegExp('^(http:\\/\\/|https:\\/\\/)')
        if (!regex.test(this.link)) this.link = "https://" + this.link
        if (!this.label) this.label = this.link.split('/')[2]

        const data = {
          name: this.label,
          link: this.link,
          type: this.type
        }
        data[this.parent.type] = this.parent.iri

        this.$store.dispatch('linkModule/createLink', data)
        this.link = ""
        this.label = ""
      }
    }
  }
}
</script>