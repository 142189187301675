import leaveApi from "../../api/leave"
import store from "../index"

const state = () => ({
    items: [],
    pagination: {}
})

const getters = {
    getItems: state => state.items,
    getPagination: state => state.pagination
}

const actions = {
    async createLeave({commit}, data) {
        const leave = await leaveApi.create(data)
        commit('addNewLeave', leave)

        const currentUsername = store.getters['userModule/getCurrentUser'].username
        await store.dispatch('logModule/createLog', {
            user: currentUsername,
            action: "CREATE",
            entity: "leave",
            entityId: leave.id,
            entityIri: leave['@id'],
            description: `Demande de congés pour ${leave?.user?.firstname} ${leave?.user?.lastname}`
        })

        return leave
    },
    async getList({commit}, page) {
        const leaves = await leaveApi.getAll(page)
        commit('setList', leaves['hydra:member'])
        commit('setPagination', leaves['hydra:view'])
        return leaves
    },
    async getListByUser({commit}, userId) {
        const leaves = await leaveApi.getByUser(userId)
        commit('setList', leaves['hydra:member'])
        return leaves
    },
    async getListByStateOrType({commit}, payload) {
        const leaves = await leaveApi.getByStateOrType(payload)
        commit('setList', leaves['hydra:member'])
        return leaves
    },
    async getIncomingLeave({commit}, data) {
        const leaves = await leaveApi.getIncoming(data)
        commit('setList', leaves['hydra:member'])
        return leaves
    },
    async update({commit}, payload) {
        const leave = await leaveApi.update(payload.id, payload.data)
        commit('update', leave)
        return leave
    }
}

const mutations = {
    addNewLeave(state, leave) {
        state.items.unshift(leave)
    },
    setList(state, leaves) {
        state.items = leaves
    },
    setPagination(state, pagination) {
        state.pagination = pagination
    },
    resetList(state) {
        state.items = []
    },
    update(state, leave) {
        state.items.map((item, index) => {
            if (item.id === leave.id) state.items[index] = leave
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}