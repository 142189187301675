import { createApp } from 'vue'
import { io } from 'socket.io-client';
import App from './App.vue'
import router from './router'
import store from './store'
import FocusDirective from "./directives/focus"
import Config from "./Utils/Config"

import "./assets/scss/main.scss"
let ioInstance = null
if (Config.isProd()) ioInstance = io(Config.getWebsocketUrl())
export const socketInstance = ioInstance

const clientApp = createApp(App)

if (Config.isProd()) {
    const doNotify = note => note.client === store.getters['noteModule/getParent'].iri
    socketInstance.on('note:created', note => {
        if (doNotify(note)) store.commit('noteModule/addNewNote', note)
    })
    socketInstance.on('note:updated', note => {
        if (doNotify(note)) store.commit('noteModule/updateNote', note)
    })
    socketInstance.on('note:deleted', note => {
        if (doNotify(note)) store.commit('noteModule/removeNote', note.id)
    })
}

clientApp.use(store).use(router)
clientApp.directive('focus', FocusDirective)
clientApp.mount('#app')