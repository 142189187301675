import contactApi from "../../api/contact"
import store from "../index"

const state = () => ({
    items: [],
})

const getters = {
    getItems: state => state.items,
}

const actions = {
    async createContact({commit}, data) {
        const contact = await contactApi.create(data)
        commit('addNewContact', contact)

        await store.dispatch('logModule/createLog', {
            user: store.getters['userModule/getCurrentUser'].username,
            action: "CREATE",
            entity: "contact",
            entityId: contact.id,
            entityIri: contact['@id'],
            description:  `Nom du client : ${store.getters['clientModule/getCurrent']?.name}`
        })

        return contact
    },
    async getAllContact({commit}) {
        const contacts = await contactApi.getAll()
        commit('setContactList', contacts)
    },
    async searchContact({commit}, data) {
        const contacts = await contactApi.search(data)
        commit('setContactList', contacts)
    },
    async getContactList({ commit }, clientId) {
        const contacts = await contactApi.getList(clientId)
        commit('setContactList', contacts)
    },
    async updateContact({commit}, payload) {
        const contact = await contactApi.update(payload.id, payload.data)
        commit('updateItem', contact)

        await store.dispatch('logModule/createLog', {
            user: store.getters['userModule/getCurrentUser'].username,
            action: "UPDATE",
            entity: "contact",
            entityId: contact.id,
            entityIri: contact['@id'],
            description:  `Nom du client : ${store.getters['clientModule/getCurrent']?.name}`
        })

        return contact
    },
    async removeContact({commit}, id) {
        await contactApi.remove(id)
        commit('removeContact', id)

        await store.dispatch('logModule/createLog', {
            user: store.getters['userModule/getCurrentUser'].username,
            action: "DELETE",
            entity: "contact",
            entityId: id,
            description:  `Nom du client : ${store.getters['clientModule/getCurrent']?.name}`
        })
    },
}

const mutations = {
    setContactList(state, contacts) {
        state.items = contacts
    },
    updateItem(state, contact) {
        state.items.map((item, index) => {
            if (item.id === contact.id) state.items[index] = contact
        })
    },
    addNewContact(state, contact) {
        if(contact) state.items.push(contact)
    },
    removeContact(state, id) {
        state.items = state.items.filter(item => item.id !== id)
    },
    resetContact(state) {
        state.items = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}