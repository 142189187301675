import Axios from "axios"
import Config from "../Utils/Config"
import store from "../store"

export default {
    getHeaders(contentType = "application/json") {
        return {'Authorization': `Bearer ${store.getters['securityModule/getToken']}`, 'content-type': contentType}
    },
    async getAll() {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/contacts?pagination=false`, {headers: this.getHeaders()})
                .then(response => {
                    const contacts = response.data['hydra:member']
                    resolve(contacts)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
    async getList(clientId) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/contacts?client=${clientId}`, {headers: this.getHeaders()})
                .then(response => {
                    const contacts = response.data['hydra:member']
                    resolve(contacts)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
                })
        })
    },
    async search(data) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/contacts?search_name=${encodeURI(data.query)}`, {headers: this.getHeaders()})
                .then(response => {
                    const contacts = response.data['hydra:member']
                    resolve(contacts)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
    async update(id, data) {
        return new Promise((resolve, reject) => {
            Axios.patch(`${Config.getApiUrl()}/contacts/${id}`, data, {headers: this.getHeaders('application/merge-patch+json')})
                .then(response => resolve(response.data))
                .catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
                })
        })
    },
    async remove(id) {
        return new Promise((resolve, reject) => {
            Axios.delete(`${Config.getApiUrl()}/contacts/${id}`,{headers: this.getHeaders()})
                .then(response => {
                    store.commit('addMessage', {state: 'warning', content: `Le contact id:${id} à bien été supprimé`})
                    resolve(response)
                }).catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data.message})
                    reject(error)
                })
        })
    },
    async create(data) {
        return new Promise((resolve, reject) => {
            Axios({method: 'post', url: `${Config.getApiUrl()}/contacts`, data, headers: this.getHeaders()})
                .then(response => resolve(response.data))
                .catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.response.data?.message ?? error.response.data['hydra:description']})
                    reject(error)
                })
        })
    }
}