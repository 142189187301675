<script setup>
import {ref, onMounted, computed, watch} from "vue"
import store from "../../store"
import DateUtils from "../../Utils/DateUtils"
import Config from "../../Utils/Config"
import BasicInputComponent from "../../components/fields/BasicInputComponent"
import CustomSelect from "../../assets/js/lib/custom-select"

// DATA
const startDefault = ref('')
const endDefault = ref('')
const projectType = ref('-')
const projectState = ref('-')
const customSelectProjectState = ref(null)
const customSelectProjectType = ref(null)

// COMPUTED
const getProjectTypes = computed(() => {
  return store.getters['settingsModule/getProjectTypesList']
})

// METHODS
function getProjectStates() {
  return Config.getProjectStates()
}
function updateProjectList() {
  const endDate = new Date(endDefault.value)
  endDate.setDate(endDate.getDate() + 1)
  const end = DateUtils.getInputFriendlyDate(endDate)
  const data = {start: startDefault.value, end}

  if (projectState.value && projectState.value !== "-") data.state = projectState.value
  if (projectType.value && projectType.value !== "-") data.type = projectType.value

  store.dispatch('projectModule/getProjectListByDate', data)
}

// LIFECYCLE
onMounted(() => {
  const today = new Date()
  endDefault.value = DateUtils.getInputFriendlyDate(today)
  today.setDate(today.getDate() + 1)
  const end = DateUtils.getInputFriendlyDate(today)
  const lastYear = new Date(today.setFullYear(today.getFullYear() - 1))
  startDefault.value = DateUtils.getInputFriendlyDate(lastYear)

  new CustomSelect(customSelectProjectState.value)
  store.dispatch('projectModule/getProjectListByDate', {start: startDefault, end})

})
watch(customSelectProjectType, (value) => {
  if (value) new CustomSelect(value)
})
</script>

<template>
  <div class="project-search">
    <div class="dates">
      <div class="form-group">
        <label for="start">Date de début</label>
        <BasicInputComponent input-type="date" v-model:modelValue="startDefault" @change="updateProjectList" />
      </div>

      <div class="form-group">
        <label for="end">Date de fin</label>
        <BasicInputComponent input-type="date" v-model:modelValue="endDefault" @change="updateProjectList" />
      </div>
    </div>

    <div class="filters">
      <div class="form-group">
        <label for="state">Statut du projet</label>
        <select name="state" id="state" v-model="projectState" @change="updateProjectList" ref="customSelectProjectState">
          <option selected>-</option>
          <option v-for="(label, key) in getProjectStates()" :value="key" :key="key">{{label}}</option>
        </select>
      </div>

      <div v-if="getProjectTypes.length" class="form-group">
        <label for="type">Type de projet</label>
        <select name="type" id="type" v-model="projectType" @change="updateProjectList" ref="customSelectProjectType">
          <option selected>-</option>
          <option v-for="type in getProjectTypes" :value="type.slug" :key="type.slug">{{type.name}}</option>
        </select>
      </div>
    </div>
  </div>
</template>