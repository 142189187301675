import Axios from "axios"
import Config from "../Utils/Config"
import store from "../store"

export default {
    getHeaders(contentType = "application/json") {
        return {'Authorization': `Bearer ${store.getters['securityModule/getToken']}`, 'content-type': contentType}
    },
    async getByUser(data) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/times?pagination=${data.pagination ? 'true' : 'false'}&user=${data.user}${data.page ? `&page=${data.page}` : ''}`, {headers: this.getHeaders()})
                .then(response => {
                    const times = response.data
                    resolve(times)
                }).catch(error => {
                    reject(error.response.data.message)
                })
        })
    },
    async getByProject(projectId) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/times?project=${projectId}&pagination=false`, {headers: this.getHeaders()})
                .then(response => {
                    const times = response.data
                    resolve(times)
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },
    async getList(page) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/times?${page}`, {headers: this.getHeaders()})
                .then(response => {
                    const times = response?.data
                    resolve(times)
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },
    async get(id) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/times/${id}`, {headers: this.getHeaders()})
                .then(response => {
                    const time = response.data
                    resolve(time)
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },
    async getByProjectAndDate(userId, data) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/times?user=${userId}&project=${data.project}&date=${data.date}`, {headers: this.getHeaders()})
                .then(response => {
                    const time = response.data
                    resolve(time)
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },
    async getByProjectAndStartingDate(projectId, startingDate) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/times?project=${projectId}&date[gte]=${startingDate}&pagination=false`, {headers: this.getHeaders()})
                .then(response => {
                    const time = response.data
                    resolve(time)
                }).catch(error => {
                reject(error.response.data.message)
            })
        })
    },
    async search(data) {
        return new Promise((resolve, reject) => {
            Axios.get(`${Config.getApiUrl()}/times?project.name=${data.query}${data.user ? `&user=${data.user}` : ''}`, {headers: this.getHeaders()})
                .then(response => {
                    const clients = response.data['hydra:member']
                    resolve(clients)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
    async searchByUserAndProject(data) {
        return new Promise((resolve, reject) => {
            let query = `?pagination=${data.pagination}`
            if (data.user) query += `&user=${data.user}`
            if (data.query) query += `&search_name=${data.query}`
            if (data.end) query += `&date[lte]=${data.end}`
            if (data.start) query += `&date[gte]=${data.start}`
            Axios.get(`${Config.getApiUrl()}/times${query}`, {headers: this.getHeaders()})
                .then(response => {
                    const times = response?.data
                    resolve(times)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
    async create(data) {
        return new Promise((resolve, reject) => {
            Axios({method: 'post', url: `${Config.getApiUrl()}/times`, data, headers: this.getHeaders()})
                .then(response => {
                    const time = response.data
                    if (time) {
                        store.commit('addMessage', {
                            state: 'success',
                            content: `Le temps à bien été enregistré`
                        })
                    }
                    resolve(time)
                }).catch(error => {
                store.commit('addMessage', {
                    state: 'error',
                    content: error.response.data?.message ?? error.response.data['hydra:description']
                })
                reject(error)
            })
        })
    },
    async update(id, data) {
        return new Promise((resolve, reject) => {
            Axios.patch(`${Config.getApiUrl()}/times/${id}`, data, {headers: this.getHeaders('application/merge-patch+json')})
                .then(response => {
                    const times = response.data
                    if (times) {
                        store.commit('addMessage', {
                            state: 'success',
                            content: `Le temps à bien été mis à jour`
                        })
                    }
                    resolve(times)
                }).catch(error => {
                store.commit('addMessage', {state: 'error', content: error.response.data.message})
                reject(error)
            })
        })
    },
    async remove(id) {
        return new Promise((resolve, reject) => {
            Axios.delete(`${Config.getApiUrl()}/times/${id}`, {headers: this.getHeaders()})
                .then(response => {
                    store.commit('addMessage', {
                        state: 'success',
                        content: `Le temps à bien été supprimé`
                    })
                    resolve(response)
                }).catch(error => {
                reject(error)
            })
        })
    }
}