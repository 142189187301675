<template>
  <svg class="sprite-icon" :class="`icon-${name}`" aria-hidden="true">
    <use :xlink:href="`${getSvg}#icon-${name}`"></use>
  </svg>
</template>

<script lang="js">
export default {
  props: {
    name: String
  },
  computed: {
    getSvg() {
      return require('../../assets/icons/sprite.svg')
    }
  }
}
</script>