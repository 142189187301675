import Axios from "axios"
import Config from "../Utils/Config"
import Utils from "../Utils/Utils"
import store from "../store"

export default {
    async login(user) {
        return new Promise((resolve, reject) => {
            Axios.post(`${Config.getApiUrl()}/login_check`, user, {headers: {'Content-Type': 'application/json'}})
                .then(response => {
                    const token = response.data.token
                    const payload = Utils.parseJwt(token)
                    if (payload) user.id = payload.id

                    store.commit('addMessage', {state: 'success', content: `Bonjour ${user.username}`})
                    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

                    resolve({username: user.username, token})
                })
                .catch(error => {
                    store.commit('addMessage', {state: 'error', content: error.message})
                    localStorage.removeItem('token')
                    reject(error)
                })
        })
    },
    async logout() {
        return new Promise((resolve) => {
            localStorage.removeItem('token')
            localStorage.removeItem('username')
            localStorage.removeItem('user')
            delete Axios.defaults.headers.common['Authorization']
            resolve()
        })
    }
}