import linkApi from "../../api/link"
import store from "../index"

const getDescription = (type) => {
    let description = `Nom du client : ${store.getters['clientModule/getCurrent']?.name}.`
    if (type === 'project') description += ` Nom du projet : ${store.getters['projectModule/getCurrent']?.name}`

    return description
}

const state = () => ({
    items: [],
})

const getters = {
    getItems: state => state.items,
}

const actions = {
    async getLinkList({commit}, payload) {
        const links = await linkApi.getList(payload)
        commit('setLinkList', links)
        commit('sortItems')
    },
    async createLink({commit}, data) {
        const link = await linkApi.create(data)
        commit('addNewLink', link)
        commit('sortItems')

        await store.dispatch('logModule/createLog', {
            user: store.getters['userModule/getCurrentUser'].username,
            action: "CREATE",
            entity: "link",
            entityId: link.id,
            entityIri: link['@id'],
            description: getDescription(data.client ? 'client' : 'project')
        })

        return link
    },
    async removeLink({commit}, payload) {
        await linkApi.remove(payload.id)
        commit('removeLink', payload.id)
        commit('sortItems')

        await store.dispatch('logModule/createLog', {
            user: store.getters['userModule/getCurrentUser'].username,
            action: "DELETE",
            entity: "link",
            entityId: payload.id,
            description: getDescription(payload.type)
        })
    }
}

const mutations = {
    sortItems(state) {
        const website = state.items.filter(el => el.type !== 'trello')
        const trello = state.items.filter(el => el.type === 'trello')
        state.items = [...website, ...trello]
    },
    setLinkList(state, links) {
        state.items = links
    },
    addNewLink(state, link) {
        if (link) state.items.push(link)
    },
    removeLink(state, id) {
        state.items = state.items.filter(item => item.id !== id)
    },
    resetLinks(state) {
        state.items = []
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}