<script setup>
import {ref} from "vue"
import Motivations from "../../Utils/MotivationsUtils"
import SvgIconComponent from "./SvgIconComponent"

const motivation = ref(Motivations.getRandom())

function getAnother() {
  motivation.value = Motivations.getRandom()
}
</script>

<template>
  <div class="motivations">
    <figure class="blockquote-container">
      <SvgIconComponent name="sync" title="voir une autre phrase" @click="getAnother()" />
      <blockquote>{{ motivation.sentence }}</blockquote>
      <figcaption>— {{ motivation.source }}</figcaption>
    </figure>
  </div>
</template>