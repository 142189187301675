import mediaApi from "../../api/media"

const actions = {
    async sendFile(context, payload) {
        return await mediaApi.post(payload)
    },
    async getFile(context, id) {
        return await mediaApi.get(id)
    },
    async removeFile(context, id) {
        return await mediaApi.remove(id)
    }
}

export default {
    namespaced: true,
    actions
}