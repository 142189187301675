<script setup>
import {ref, onMounted} from "vue"
import ApiProject from "../../api/project"
import StatsUtils from "../../Utils/StatsUtils"

const shameProjects = ref([])

onMounted(() => {
  ApiProject.getAll({state: 2})
      .then(projects => {
        const arrMargin = []
        if (projects.length) {
          projects.forEach(p => {
            const margin = parseInt(StatsUtils.getMargin(p, p.times))
            if (!isNaN(margin) && margin <= 7) arrMargin.push({p, margin})
          })
        }

        shameProjects.value = [...arrMargin].sort((a, b) => a.margin - b.margin).slice(0, 5)
      })
})
</script>

<template>
  <div v-for="item in shameProjects" :key="item.p.id" class="mini-card">
    <strong>{{item.margin}}% :</strong> {{item.p.client.name}} - {{item.p.name}}
  </div>
  <p v-if="!shameProjects.length"> Aucun projet n'est a montrer du doigt 👏</p>
</template>