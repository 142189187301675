<script setup>
import {ref, defineProps, computed} from "vue"
import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, SubTitle, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'
import StatsUtils from "../../Utils/StatsUtils"

ChartJS.register(Title, SubTitle, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const props = defineProps({
  projects: Array
})

const chartOptions = ref({
  plugins: {
    legend: {display: false},
    title: {display: true, text: "Projet le plus rentable", font: {size: 22}, padding: {bottom: 0}},
    subtitle: {display: true, text: "marge en %", padding: {bottom: 20}, font: {size: 14}}
  },
  scales: {
    y: {
      ticks: {
        callback: function (value) {
          return `${value}%`
        }
      }
    }
  },
  responsive: true
})


const chartData = computed(() => {
  const chartData = {
    labels: [],
    datasets: []
  }

  if (props.projects) {
    chartData.labels = getProjectLabels.value
    chartData.datasets = [
      {label: '% de marge', data: getProjectProfits.value, backgroundColor: colorize.value},
    ]
  }

  return chartData
})
const colorize = computed(() => {
  return (ctx) => ctx?.parsed?.y > 0 ? "#FFD100" : "#cb1305"
})
const getProjectLabels = computed(() => {
  const labels = []
  props.projects.forEach(project => {
    labels.push([project.client.name, project.name])
  })

  return labels
})
const getProjectProfits = computed(() => {
  const profits = []
  props.projects.forEach(project => {
    profits.push(StatsUtils.getMargin(project))
  })

  return profits
})
</script>

<template>
  <Bar :chart-options="chartOptions" :chart-data="chartData"/>
</template>