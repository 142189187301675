import leaveUpdateApi from "../../api/leave-update"
import store from "../index"

const state = () => ({
    items: [],
})

const getters = {
    getItems: state => state.items
}

const actions = {
    async createLeaveUpdate({commit}, data) {
        const leaveUpdate = await leaveUpdateApi.create(data)
        commit('addNewLeaveUpdate', leaveUpdate)

        const currentUsername = store.getters['userModule/getCurrentUser'].username
        await store.dispatch('logModule/createLog', {
            user: currentUsername,
            action: "CREATE",
            entity: "leave_update",
            entityId: leaveUpdate.id,
            entityIri: leaveUpdate['@id'],
            description: `Demande de congés pour ${leaveUpdate?.user?.firstname} ${leaveUpdate?.user?.lastname}`
        })

        return leaveUpdate
    },
    async getListByLeave({commit}, leaveId) {
        const leaves = await leaveUpdateApi.getByLeave(leaveId)
        commit('setList', leaves['hydra:member'])
        return leaves
    }
}

const mutations = {
    addNewLeaveUpdate(state, leaveUpdate) {
        state.items.unshift(leaveUpdate)
    },
    setList(state, leaveUpdates) {
        state.items = leaveUpdates
    },
    resetList(state) {
        state.items = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}