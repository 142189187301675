<script setup>
import {onMounted, ref, computed} from "vue"
import store from "./../../store"
import SvgIconComponent from "../../components/items/SvgIconComponent"
import MultiColumnTemplate from "../templates/MultiColumnTemplate"
import PasswordGeneratorComponent from "../../components/items/PasswordGeneratorComponent"
import Config from "../../Utils/Config"
import IconTitleComponent from "../../components/items/IconTitleComponent"

// DATA
const password = ref("")
const confirmPassword = ref("")

// COMPUTED
const activeSaveButton = computed(() => {
  return !password.value || !confirmPassword.value
})
const user = computed(() => {
  return store.getters['userModule/getCurrentUser']
})
const getUserJob = computed(() => {
  return user.value.job ? Config.getJobByKey(user.value.job) : ''
})

const getAvatarSrc = computed(() => {
  return store.getters['userModule/getUserAvatar'](user.value.username)
})

// METHODS
function updateUser() {
  if (password.value) {
    if (password.value === confirmPassword.value) {
      store.dispatch('userModule/updateUser', {username: user.value.username, data: {password: password.value}})
          .then(response => {
            if (response) store.commit('addMessage', {
              state: "success",
              content: "Le mot de passe à bien été mis à jour !"
            })
            password.value = ""
            confirmPassword.value = ""
          })
    } else {
      store.commit('addMessage', {state: "warning", content: "La confirmation de mot de passe n'est pas valide"})
    }
  } else {
    store.commit('addMessage', {state: "warning", content: "Veuillez remplir les champs"})
  }
}

// LIFECYCLE
onMounted(() => {
  if (!store.state.userModule.user) store.dispatch('userModule/getUser')
  store.commit('topBarModule/setMainTitle', {title: "Mon compte", icon: "account-circle"})
})

</script>

<template>
  <MultiColumnTemplate>
    <template #main>
      <div class="user-account">
        <div class="header">
          <div class="user-avatar"><img :src="getAvatarSrc" alt=""></div>
          <div>
            <div class="username">{{ user.username }}</div>
            <div>{{ user.firstname }} {{ user.lastname }}</div>
          </div>
        </div>
        <div class="user-item"><strong>Adresse email :</strong> {{ user.email }}</div>
        <div class="user-item"><strong>Poste :</strong> {{ getUserJob }}</div>
        <div class="user-item"><strong>Roles :</strong> {{ user.roles?.join(', ') }}</div>
        <hr>
        <div class="change-password">
          <IconTitleComponent title="Changer le mot de passe" iconName="password" titleSize="h3"/>
          <div class="input-container text">
            <label for="password">Mot de passe</label>
            <input type="password" id="password" v-model="password">
          </div>
          <div class="input-container text">
            <label for="confirmPassword">Confirmer le mot de passe</label>
            <input type="password" id="confirmPassword" v-model="confirmPassword">
          </div>
        </div>
        <button class="save-button" @click="updateUser" :disabled="activeSaveButton">
          <SvgIconComponent name="save"/>
          mettre à jour
        </button>
      </div>
    </template>

    <template #right>
      <div class="bloc">
        <PasswordGeneratorComponent/>
      </div>
    </template>
  </MultiColumnTemplate>
</template>