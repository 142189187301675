import settingsApi from "../../api/settings"

const state = () => ({
    items: {}
}
)
const getters = {
    getProjectCost: state => state.items.projectCost,
    getAdminEmail: state => state.items.adminEmail,
    getAgencyTodo: state => state.items.agencyTodo ? JSON.parse(state.items.agencyTodo) : [],
    getProjectTypesList: state => state.items.projectTypesList ? JSON.parse(state.items.projectTypesList) : [],
}

const actions = {
    async getSettings({commit}) {
        const settings = await settingsApi.getList()
        commit('setItemsList', settings)

        return settings
    },
    async updateSetting({commit}, payload) {
        const current = await settingsApi.get(payload)

        let setting
        if (current?.id) setting = await settingsApi.update(current.id, payload)
        else setting = await settingsApi.create(payload)
        commit('updateItem', setting)

        return setting
    },
}

const mutations = {
    setItemsList(state, settings) {
        settings.map(item => state.items[item.settingsKey] = item.settingsValue)
    },
    updateItem(state, setting) {
        state.items[setting.settingsKey] = setting.settingsValue
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}