<script setup>
import {ref, onMounted, defineProps} from "vue"
import SvgIconComponent from "../items/SvgIconComponent.vue"
import store from "../../store"

const props = defineProps({
  time: Object,
  readOnly: {
    type: Boolean,
    default: false
  }
})

const newTime = ref('')

function updateTime(e) {
  if (props.time.times !== e.target.value) {
    store.dispatch('timesModule/updateTimes', {id: props.time.id, data: {times: e.target.value, updatedAt: "now"}})
  }
}
function deleteTime(id) {
  if (confirm(`Voulez vraiment supprimer`)) {
    store.dispatch('timesModule/removeTime', id)
  }
}

onMounted(() => {
  newTime.value = props.time.times
})

</script>

<template>
  <td v-if="!readOnly">
    <span class="date-item">
      <strong>{{ new Date(time.createdAt).toLocaleDateString() }}</strong>
        <br>
      <strong>{{ new Date(time.createdAt).toLocaleTimeString() }}</strong>
    </span>
  </td>

  <td v-if="!readOnly">
    <span v-if="time.updatedAt" class="date-item">
        <strong>{{ new Date(time.updatedAt).toLocaleDateString() }}</strong>
          <br>
        <strong>{{ new Date(time.updatedAt).toLocaleTimeString() }}</strong>
      </span>
    <span v-else class="date-item">-</span>
  </td>

  <td v-if="readOnly">{{time.user.username}}</td>

  <td>
    {{ new Date(time.date * 1000).toLocaleDateString() }}
  </td>

  <td>
    <strong>{{ time.project.client.name }}</strong> - {{ time.project.name }}
  </td>

  <td class="time">
    <input v-if="!readOnly" type="number" name="newTime" step="0.5" v-model="newTime" @blur="updateTime">
    <p v-else>{{ newTime }}</p>
  </td>

  <td v-if="!readOnly" class="action-buttons">
    <div class="button-item delete-time" :title="`supprimer ${time.id} ?`" @click.stop="deleteTime(time.id)">
      <SvgIconComponent name="delete-forever" />
    </div>
  </td>
</template>
