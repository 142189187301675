<script setup>
import {computed, defineProps} from "vue"
import store from "../../store"
import Utils from "../../Utils/Utils"
import ProjectListItemComponent from "./ProjectListItemComponent.vue"

const props = defineProps({
  client: Object,
  projects: Array,
  template: {
    type: String,
    default: 'project'
  }
})

// COMPUTED
const getProjects = computed(() => {
  return [...props.projects].reverse()
})

// METHODS
function getProjectClassName(project) {
  let className = Utils.refify(store.getters['projectModule/getState'](project))
  className += ` template-${props.template}`
  return className
}
</script>

<template>
  <div class="project-list">
    <ul>
      <li v-for="project in getProjects" :key="project.id" class="project-list-item"
          :class="getProjectClassName(project)">
        <ProjectListItemComponent :project="project" :template="template" :client="client" />
      </li>
    </ul>
  </div>
</template>