<script setup>
import {ref, defineProps, computed, onMounted, onUnmounted} from "vue"
import store from "../../store"
import Utils from "../../Utils/Utils"
import IconTitleComponent from "../items/IconTitleComponent"
import LinkListComponent from "../links/LinkListComponent"
import DropzoneComponent from "../fields/DropzoneComponent"
import TinyMceComponent from "../fields/TinyMceComponent"
import AttachmentListComponent from "../items/AttachmentListComponent"
import CreateLinkComponent from "../links/createLinkComponent"

const props = defineProps({
  project: Object
})

// DATA
const projectId = ref("")
const editing = ref(false)
const iri = ref("")
const name = ref("")
const reference = ref("")
const description = ref("")
const updatedAt = ref("")
const projectType = ref("")
const isArchived = ref(false)

// COMPUTED
const getProjectTypesList = computed(() => {
  return store.getters['settingsModule/getProjectTypesList']
})

// METHODS

function handleEvent(ev, value) {
  if (ev instanceof FocusEvent) {
    const inputName = ev.target.getAttribute('name')?.split('_')[1]
    const oldValue = store.state.clientModule.current[inputName]

    if (oldValue !== value) {
      const data = {updatedAt: 'now'}
      data[inputName] = value ?? ev.target?.value

      store.dispatch('projectModule/updateProject', {
        id: projectId.value,
        data: data
      }).then(response => {
        if (response) updatedAt.value = response.updatedAt
      })
    }
  }
  editing.value = false
}
function updateReference(ev) {
  const value = Utils.refify(ev.target.value)
  const oldValue = store.state.projectModule.current.reference
  if (oldValue !== value) {
    store.dispatch('projectModule/checkReference', value)
        .then(response => {
          if (response) {
            store.commit('addMessage', {state: 'error', content: `La référence :  "${value}" existe déjà`})
            reference.value = oldValue
          } else {
            store.dispatch('projectModule/updateProject', {
              id: projectId.value, data: {reference: value, updatedAt: 'now'}
            }).then(response => {
              if (response) {
                updatedAt.value = response.updatedAt
                reference.value = value
              }
            })
          }
        })
  }
  editing.value = false
}
function updateProjectType(ev) {
  if (ev.target.value !== props.project.type) {
    const data = {
      type: ev.target.value,
      updatedAt: 'now'
    }
    store.dispatch('projectModule/updateProject', {
      id: projectId.value,
      data: data
    }).then(response => {
      if (response) updatedAt.value = response.updatedAt
    })
  }
}

// LIFECYCLE
onMounted(() => {
  iri.value = props.project['@id']
  name.value = props.project.name
  projectId.value = props.project.id
  reference.value = props.project.reference
  description.value = props.project.description
  updatedAt.value = props.project.updatedAt
  projectType.value = props.project.type

  if (props.project.client) {
    Utils.setNewViewedClient(props.project.client.id)
    isArchived.value = props.project.client.isArchived
    store.commit('clientModule/addCurrent', props.project.client)
  }

  store.dispatch('noteModule/getListByType', {id: projectId.value, type: "project"})
  store.commit('noteModule/setParent', {type: "project", iri: iri.value})
})

onUnmounted(() => {
  store.commit('noteModule/resetNotes')
  store.commit('linkModule/resetLinks')
  store.commit('projectModule/resetAttachments')
})
</script>

<template>
  <div class="single-content">
    <p v-if="updatedAt" class="updated-date">mis à jour : {{ new Date(updatedAt).toLocaleString() }}</p>

    <div class="header">
      <div v-if="!isArchived" class="input-container name">
        <div v-show="editing !== 'name'" @click="editing = 'name'" class="input">{{ name }}</div>
        <input type="text"
               name="project_name"
               v-show="editing === 'name'"
               v-focus="editing"
               @blur="handleEvent"
               @keyup.enter="(ev) => ev.target.blur()"
               v-model="name"/>
      </div>
      <div v-else class="input-container name">
        <div class="input">{{ name }}</div>
      </div>

      <div v-if="!isArchived" class="input-container bloc reference">
        <label>Réf. : </label>
        <div v-show="editing !== 'reference'" @click="editing = 'reference'" class="input" v-html="reference"></div>
        <input type="text"
               name="project_reference"
               v-show="editing === 'reference'"
               v-focus="editing"
               @blur="updateReference"
               @keyup.enter="(ev) => ev.target.blur()"
               v-model="reference"/>
      </div>
      <div v-else class="input-container bloc reference">
        <label>Réf. : </label>
        <div class="input" v-html="reference"></div>
      </div>
    </div>

    <div class="content">
      <div class="main-content">
        <div class="textarea-container bloc">
          <IconTitleComponent title="Description" iconName="wysiwyg" titleSize="h3"/>
          <TinyMceComponent
              v-if="!isArchived"
              id="description"
              name="project_description"
              inputPlaceholder="Écrivez une description"
              :inlineOption="true"
              @blur="handleEvent"
              v-model="description"/>
          <div v-else v-html="description"></div>
        </div>
      </div>

      <div class="side-content">
        <div class="project-type" v-if="getProjectTypesList">
          <IconTitleComponent title="Type de projet" iconName="settings" titleSize="small"/>
          <select name="projectType" id="projectType" @change="updateProjectType" v-model="projectType">
            <option v-for="type in getProjectTypesList" :key="type.slug" :value="type.slug">{{type.name}}</option>
          </select>
        </div>

        <div>
          <IconTitleComponent title="Fichiers" iconName="attachment" titleSize="small"/>
          <DropzoneComponent v-if="!isArchived"/>
          <AttachmentListComponent getter="projectModule/getAttachments"
                                    removeMutation="projectModule/removeAttachments"/>
        </div>
        <hr>
        <div>
          <IconTitleComponent title="Liens" iconName="link" titleSize="small"/>
          <CreateLinkComponent v-if="!isArchived" :parent="{type: 'project', iri}"/>
          <LinkListComponent parentType="project"/>
        </div>
      </div>
    </div>
  </div>
</template>
