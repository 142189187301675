<script setup>
import {ref, defineProps, watch, computed} from "vue"
import store from "../../store"
import CalculateLeaveDaysComponent from "./CalculateLeaveDaysComponent"
import SvgIconComponent from "../items/SvgIconComponent"
import SimpleTinyMceComponent from "../fields/SimpleTinyMceComponent"
import {DateTime, Interval} from "luxon"

const props = defineProps({
  leave: Object,
  lightVersion: {
    type: Boolean,
    default: false
  }
})

// DATA
const note = ref("")
const editing = ref(null)
const explanation = ref("")
const loading = ref(false)

// COMPUTED
const getInterval = computed(() => {
  return Interval.fromDateTimes(DateTime.fromISO(props.leave.startAt, { locale: "fr" }), DateTime.fromISO(props.leave.endAt, { locale: "fr" }))
})

// METHODS
function getLeaveState(state) {
  if (state === 1) return "success"
  else if (state === 2) return "danger"
  else return "warning"
}
function getLeaveStateLabel(state) {
  if (state === 1) return "validé"
  else if (state === 2) return "refusé"
  else return "en attente"
}
function reset() {
  note.value = ''
  editing.value = null
  explanation.value = ''
  loading.value = false
}
function update(leave) {
  const leaveUpdate = {
    user: localStorage.getItem('username'),
    comment: note.value,
    askLeave: leave['@id'],
    state: parseInt(editing.value),
  }

  store.dispatch('leaveUpdateModule/createLeaveUpdate', leaveUpdate)
      .then(leaveUpdate => {
        store.dispatch('leaveModule/update', {id: leave.id, data: {state: leaveUpdate.state}})

        reset()
      })
}

// WATCHERS
function watchEditing(newValue) {
  if (newValue === '1') explanation.value = 'Vous allez accepter cette demande congés, un email sera envoyé au collaborateur.'
  else if (newValue === '0') explanation.value = 'Vous allez mettre en attente cette demande congés, un email sera envoyé au collaborateur.'
  else if (newValue === '2') explanation.value = 'Vous allez refuser cette demande congés, un email sera envoyé au collaborateur.'
  else explanation.value = ''
}
watch(editing, watchEditing)

</script>

<template>
  <div class="leave-card">
    <div class="state" :class="getLeaveState(leave.state)">{{getLeaveStateLabel(leave.state)}}</div>
    <div class="card-header">
      <div>
        <p><strong>{{ leave.user.firstname }} {{ leave.user.lastname }}</strong></p>
        <span>{{ new Date(leave.createdAt).toLocaleDateString() }}</span>
      </div>

      <div>
        <p><strong>Type : </strong>{{ leave.type }}</p>
        <p>{{ getInterval.toFormat('dd LLL yyyy') }}</p>
      </div>

      <CalculateLeaveDaysComponent :start="leave.startAt" :end="leave.endAt" />
    </div>

    <div v-if="leave.message" class="card-body">
      <span class="title">Commentaire : </span>
      <span v-html="leave.message"></span>
    </div>

    <div v-if="!lightVersion" class="card-footer">
      <div v-if="editing" class="note-container">
        <p class="explanation">{{ explanation }}</p>
        <SimpleTinyMceComponent
            class="note"
            inputPlaceholder="Ajouter une note"
            name="note"
            :inlineOption="true"
            v-model="note"/>
        <button class="btn-primary" @click="update(leave)">{{ loading ? 'Chargement' : 'Envoyer' }}</button>
        <button v-if="!loading" class="btn-secondary" @click="reset()">Annuler</button>
      </div>

      <div v-if="!editing" class="action-buttons">
          <span v-if="leave.state !== 1" class="action-button accept" @click="editing = '1'"
                title="Accepter la demande">
          <SvgIconComponent name="done"/>
        </span>
        <span v-if="leave.state !== 0" class="action-button wait" @click="editing = '0'"
              title="Mettre en attente la demande">
            <SvgIconComponent name="hourglass"/>
          </span>
        <span v-if="leave.state !== 2" class="action-button refuse" @click="editing = '2'"
              title="Refuser la demande">
          <SvgIconComponent name="add"/>
        </span>
      </div>
    </div>

    <div v-else class="card-footer">
      <div v-if="leave.leaveUpdates.length">
        <strong class="title">Mises à jour : </strong>
        <div v-for="leaveUpdate in leave.leaveUpdates" :key="leaveUpdate.id" class="leave-update">
          <div><strong>{{ leaveUpdate.user }} - {{ new Date(leaveUpdate.createdAt).toLocaleString() }} : </strong></div>
          <div class="state" :class="getLeaveState(leaveUpdate.state)"></div>
          <div v-html="leaveUpdate.comment"></div>
        </div>
      </div>
    </div>
  </div>
</template>