<script setup>
import {computed, onMounted} from "vue"
import store from "../../store"
import MostProfitableProjectChartComponent from "../../components/charts/MostProfitableProjectChartComponent"
import ProjectGlobalStatsChartComponent from "../../components/charts/ProjectGlobalStatsChartComponent"
import LongestProjectChartComponent from "../../components/charts/LongestProjectChartComponent"
import MultiColumnTemplate from "../templates/MultiColumnTemplate"
import ProjectSearchComponent from "../../components/projects/ProjectSearchComponent"

// COMPUTED
const getProjectList = computed(() => {
  return store.getters['projectModule/getItems']
})

// LIFECYCLE
onMounted(() => {
  store.commit('topBarModule/setMainTitle', {title: "Statistiques", icon: "stats"})
})

</script>

<template>
  <MultiColumnTemplate>
    <template #main>
      <ProjectSearchComponent />


      <div class="project-stats">
        <p>{{ getProjectList.length }} projet{{ getProjectList.length > 1 ? 's' : '' }}</p>
        <MostProfitableProjectChartComponent :projects="getProjectList" class="stats-item" />
        <ProjectGlobalStatsChartComponent :projects="getProjectList" class="stats-item" />
        <LongestProjectChartComponent :projects="getProjectList" class="stats-item" />
      </div>
    </template>
  </MultiColumnTemplate>
</template>